import { Link } from "react-router-dom";
import Guard from "../guards/Guard";

const SidebarProjects = (): JSX.Element => {
  return (
    <>
      <li className="nav-item">
        <Guard entity="PROJECTS" level="VIEW">
          <Link to={'/projects'} className="nav-link ps-1">
            <i className="fas fa-book p-2"></i>
            Projects
          </Link>
        </Guard>

        <Guard entity="GENRES" level="EDIT">
          <Link to={'/genres'} className="nav-link ps-1">
            <i className="fas fa-magic p-2"></i>
            Genres
          </Link>
        </Guard>
      </li>
    </>
  )
}

export default SidebarProjects;