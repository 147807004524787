import { API } from "../../api/api";
import { useContext } from "react";
import { useEffect, useState } from "react"
import CompanyCreate from "./components/CompanyCreate";
import CompanyUpdate from "./components/CompanyUpdate";
import CompanyContext from "./context/CompanyContext";
import CompanyProvider from "./context/CompanyProvider";
import SimpleCard from "../../components/card/SimpleCard";
import { Button, Modal } from "react-bootstrap";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import { CompaniesStatistics } from "../../api/models/Statistics.model";
import DeleteButton from "../../components/buttons/DeleteButton";
import TableFiltering from "../../components/table/TableFiltering";
import Paginator from "../../components/Paginator";

const imageIconStyle = {
  width: 42,
  height: 42,
}

const CompanyList = (): JSX.Element => {
  return (
    <CompanyProvider>
      <CompanyTable/>
    </CompanyProvider>
  )
}

const CompanyTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [statistics, setStatistics] = useState<CompaniesStatistics>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const {state: {companies, lastPage}, getCompanies} = useContext(CompanyContext);
  const [show, setShow] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(()=>{
    (async () => {
      const statistics = await API.getCompaniesStatistics();
      setStatistics(statistics);
    })();
  }, [])

  useEffect(() => {
    getCompanies(page).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const del = (id :number) => {
    setShowConfirmDelete(false);
    API.deleteCompany(id)
    .then(() => {
      getCompanies(1);
    })
    .catch(() => setShow(true));
  }

  const delConfirm = (id: number) => {
    setCompanyToDelete(id);
    setShowConfirmDelete(true);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    { 
      Header: 'Logo',
      Footer: 'logo',
      accessor: 'logo',
      maxWidth: 70,
      minWidth: 70,
      Cell: (props : { value: string } ) => (
        <img
          src={props.value}
          className='cell-image'
          style= {imageIconStyle}
          alt='thumbnail'
        />
      ),
      canFilter: false,
      // Filter: ColumnFilter(title, setTitle),
    },
    { 
      Header: 'Name',
      Footer: 'name',
      accessor: 'name',
      canFilter: false,
      // Filter: ColumnFilter(name, setName),
    },
    { 
      Header: 'Actions',
      Footer: 'actions',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = companies.map(company => ({
    id: company.id,
    logo: company.logo,
    name: company.name,
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <CompanyUpdate id={company.id} />
        <DeleteButton onClick={() => delConfirm(company.id)} placement="top" hover="Delete company" />
      </div>
    )
  }));

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{"Not this time"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{"It seems that the company is assigned to a user, we cannot delete it."}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card shadow-card mb-2">
        <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
          <SimpleCard value={statistics?.companiesCount || 0} placeholder="COMPANIES"/>
          <SimpleCard value={statistics?.employeeCount || 0} placeholder="EMPLOYEES"/>
          <SimpleCard value={statistics?.assignedCount || 0} placeholder="ASSIGNED"/>
        </div>
      </div>

      <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(companyToDelete)} onCancel={() => setShowConfirmDelete(false)} />
      <div className="card shadow-card mb-2">
        <div className="card-body">
          <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
            <div className="h5">Companies</div>
            <CompanyCreate/>
          </div>
          <div className="">
            <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
          </div>
        </div>
        <div className="card-footer">
          <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
        </div>
      </div>
    </>
  )
}

export default CompanyList;