import { Contact } from "../../../api/models/Contact.model";
import { Profile } from "../../../api/models/Profile.model";
import { Task } from "../../../api/models/Task.model";
import { User } from "../../../api/models/User.model";
import BaseState from "../../../context/base/BaseState";

class UserState extends BaseState {
  constructor(
    public user: User = {} as User,
    public profile: Profile = {} as Profile,
    public contacts: Contact[] = [],
    public skills: string[] = [],
    public users: User[] = [],
    public tasks: Task[] = [],
    public page: number = 1,
    public lastPage: number = 1,
  ) {
    super();
  }
}

export default UserState;