import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type PrevButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  iconName?: IconName;
  disabled?: boolean;
}

const PrevButton = ({ iconName="arrow-left", ...props }: PrevButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-secondary"
    iconName={iconName}
  />
);

export default PrevButton;