import axios from "axios";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Genre } from "../../../api/models/Genre.model";
import ProjectContext from "../context/ProjectContext";
import { API } from "../../../api/api";
import EditButton from "../../../components/buttons/EditButton";
import Context from "../../../context/Context";
import Search from "../../../components/select/Search";
import { CompanyIconOption, UserIconOption } from "../../../components/select/IconOption";
import { User } from "../../../api/models/User.model";
import { Company } from "../../../api/models/Company.model";
import { ProjectState } from "../../../api/models/ProjectState.model";
import { ProjectDocument } from "../../../api/models/ProjectDocument.model";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import SimplePill from "../../../components/pills/SimplePill";
import { ApiException } from "../../../api/errors/ApiException";

type InitialState = {
  title: string,
  description: string,
  ipHolder: string,
  image: string,
  genreId: number,
  stateId: number,
  responsibleId: number,
  responsibleEmail: string,
  companyId: number,
  companyName: string,
}

const ProjectUpdate = (props: { id: number }): JSX.Element => {
  const [genres, setGenres] = useState<Genre[]>([]);
  const [states, setStates] = useState<ProjectState[]>([]);
  const [projectDocuments, setProjectDocuments] = useState<ProjectDocument[]>([]);

  const [initialState, setInitialState] = useState({} as InitialState);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ipHolder, setIpHolder] = useState('');
  const [image, setImage] = useState('');
  const [genreId, setGenreId] = useState(0);
  const [stateId, setStateId] = useState(0);

  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  
  const [responsibleId, setResponsibleId] = useState(0);
  const [responsibleEmail, setResponsibleEmail] = useState('');

  const { loader } = useContext(Context);
  const { getProjects } = useContext(ProjectContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [genres, states, project] = await Promise.all([
          API.getGenres(),
          API.getProjectStates(),
          API.getProject(props.id),
        ]);
        setGenres(genres.data);
        setStates(states);

        setTitle(project.title);
        setDescription(project.description);
        setIpHolder(project.ip_holder);
        setImage(project.image);
        setGenreId(project.genre?.id);
        setStateId(project.state?.id);
        setCompanyId(project.company?.id);
        setCompanyName(project.company?.name);
        setResponsibleId(project.responsible?.id);
        setResponsibleEmail(project.responsible?.email);
        setInitialState({
          title: project.title,
          description: project.description,
          ipHolder: project.ip_holder,
          image: project.image,
          genreId: project.genre?.id,
          stateId: project.state?.id,
          responsibleId: project.responsible?.id,
          responsibleEmail: project.responsible?.email,
          companyId: project.company?.id,
          companyName: project.company?.name,
        });
      } catch (err) {
        console.log('Oops, something went wrong ', err);
      }
    })()
  }, [props.id]);

  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setImage(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong uploading Image ', err);
      alert('Oops, something went wrong');
    }
  }

  const documentUpload = async (files: FileList | null) => {
    if (files === null) return;
    try {
      const formData = new FormData();
      formData.append("document", files[0]);
      const path = `${process.env.REACT_APP_BUILD_URL_BASE}/api/documents`;
      const { data: asset } = await axios.post(path, formData);
      setProjectDocuments([...projectDocuments, {
        url: `${path}/download/${asset.filename}`,
        original_name: files[0].name,
      }]);
    } catch (err) {
      console.log('Oops, something went wrong uploading Document ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    // TODO: Agregar loader.
    setValidated(true);

    if (form.checkValidity() === true && companyId > 0 && responsibleId > 0) {
      loader.show();
      API.updateProject(props.id,{
        title,
        description,
        ip_holder: ipHolder,
        image,
        genre_id: genreId,
        state_id: stateId,
        responsible_id: responsibleId,
        company_id: companyId,
        project_documents: projectDocuments,
      })
      .then(()=> {
        getProjects();
        setModalShow(false);
        setValidated(false);
        setShow(false);
        setInitialState({
          title,
          description,
          ipHolder,
          image,
          genreId,
          stateId,
          responsibleId,
          responsibleEmail,
          companyId,
          companyName,
        })
        loader.hide();
      }).catch((err: ApiException) => {
        loader.hide();
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    setModalShow(false);
    setValidated(false);
    setShow(false);

    setTitle(initialState.title);
    setDescription(initialState.description);
    setImage(initialState.image);
    setGenreId(initialState.genreId);
    setStateId(initialState.stateId);
    setCompanyId(initialState.companyId);
    setCompanyName(initialState.companyName);
    setResponsibleId(initialState.responsibleId);
    setResponsibleEmail(initialState.responsibleEmail);
  }

  const selectCompany = (data: Company) => {
    setCompanyId(Number(data.id));
    setCompanyName(data.name);
  }

  const selectUser = (data: User) => {
    setResponsibleId(Number(data.id));
    setResponsibleEmail(data.email);
  }

  const removeCompany = () => {
    setCompanyId(0);
    setCompanyName('');
  }

  const removeUser = () => {
    setResponsibleId(0);
    setResponsibleEmail('');
  }
  const getIconDocument = (origina_name: string): IconName => {
    if (origina_name.match(/\.pdf/) != null) {
      return "file-pdf";
    }

    if (origina_name.match(/\.(doc|docx|odt)^/) != null) {
      return "file-word";
    }
    return "file";
  }

  const unselectDocument = (projectDocument: ProjectDocument) => {
    setProjectDocuments(projectDocuments.filter((it) => it.url != projectDocument.url));
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit project"} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update Project</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-hard-validation">
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Project name" onChange={e => setTitle(e.target.value)} value={title} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid title name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" type="text" size="sm" placeholder="Project name" onChange={e => setDescription(e.target.value)} value={description} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid description.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom04" className="mb-3">
                <Form.Label>IP Holder</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Intellectual property holder" onChange={e => setIpHolder(e.target.value)} value={ipHolder} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid ip holder.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid image.</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Row>
              <Form.Group as={Col} md="6" controlId="validationCustom04" className="mb-3">
                <Form.Label>Genre</Form.Label>
                  <select className="form-control form-select-sm" onChange={e => setGenreId(Number(e.target.value))} value={genreId} required>
                    <option key={0} className="d-none"></option>
                      {genres.map((genre: Genre) => (
                        <option key={genre.id} value={genre.id}>{genre.name}</option>
                      ))}
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid description.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom04" className="mb-3 ">
                <Form.Label>Stage</Form.Label>
                  <select className="form-control form-select-sm" onChange={e => setStateId(Number(e.target.value))} value={stateId} required>
                    <option key={0} className="d-none"></option>
                      {states.map((state: ProjectState) => (
                        <option key={state.id} value={state.id}>{state.name}</option>
                      ))}
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid Stage.</Form.Control.Feedback>
              </Form.Group>
            </Row>


            <Row>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom05" className="mb-3">
                <Form.Label>Company</Form.Label>
                <Search 
                    className={["form-control", afterValidationClass(companyId > 0)].join(' ')}
                    search={API.searchCompanies}
                    item={CompanyIconOption}
                    onItemClick={selectCompany}
                    onRemoveContent={removeCompany}
                    value={companyName}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom06" className="mb-3">
                <Form.Label>Responsible</Form.Label>
                <Search 
                    className={["form-control", afterValidationClass(responsibleId > 0)].join(' ')}
                    search={(email) => API.searchProjectTeamByEmail(props.id, email)}
                    item={UserIconOption}
                    onItemClick={selectUser}
                    onRemoveContent={removeUser}
                    value={responsibleEmail}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid responsible.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Documents</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => documentUpload( (e.target as HTMLInputElement).files )}/>
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex flex-wrap gap-2 mt-3">
                {projectDocuments.map((projectDocument:ProjectDocument) => 
                  <SimplePill
                    key={projectDocument.original_name}
                    iconName={getIconDocument(projectDocument.original_name)}
                    label={projectDocument.original_name}
                    checkable={true}
                    onCheck={() => unselectDocument(projectDocument)}
                  />
                )}
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
        </Modal>
    </>
  )
}

export default ProjectUpdate;