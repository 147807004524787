import { useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import { Company } from '../../../api/models/Company.model';
import CompanyContext from './CompanyContext';
import CompanyReducer from './CompanyReducer';
import CompanyState from './CompanyState';

const CompanyProvider = (props: {children: any}): JSX.Element => {

  const [state, dispatch] = useReducer(CompanyReducer, {} as CompanyState, (_) => new CompanyState());

  const getCompany = (id: number): Promise<void> => {
    return API.getCompany(id)
      .then((company: Company) => {
        dispatch({
          type: "GET_COMPANY",
          payload: {
            company: company
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
    });
  }

  const getCompanies = (page: number): Promise<void> => {
    return API.getCompanies(page)
      .then((companiesResponse) => {
        dispatch({ 
          type: "GET_COMPANIES",
          payload: {
            companies: companiesResponse.data,
            lastPage: companiesResponse.meta.last_page,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  return (
    <CompanyContext.Provider
      value = {{
        state,
        getCompany,
        getCompanies,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  )
}

export default CompanyProvider;