import axios, { AxiosError, AxiosResponse } from "axios";
import { Feedback, FeedbackPagingFilter, FeedbackPageable } from "../models/Feedback.model";
import { TaskPagingFilter } from "../models/Task.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FeedbackAPI {

  /**
   * 
   * @param id feedback ID
   * @returns 
   */
  export const getFeedback =  async (id: number): Promise<Feedback> => {
    const uri = `feedbacks/${id}`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  export const createFeedback = async (body: any, releaseId: number): Promise<any> => {
    const uri = `feedbacks`;

    return await axios.post(uri, {
      body,
      release_id: releaseId,
    })
    .then((res: AxiosResponse) => {
			return res.data;
		})
		.catch((err: AxiosError) => {
      errorHandler(err);
		});
  }

  /**
   * 
   * @param id 
   * @param params 
   * @returns 
   */
  export const updateFeedback = async (id: number, body: any): Promise<any> => {
    const uri = `feedbacks/${id}`;

    return await axios.put(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
    }

  /**
   * 
   * @param id 
   * @returns 
   */
  export const deleteFeedback = async (id: number): Promise<any> => {
    const uri =  `feedbacks/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  /**
   * @returns an object that contains array with feedbacks and a number with total pages
   */
  export const getFeedbacks =  async (filters: FeedbackPagingFilter): Promise<FeedbackPageable> => {
    const uri = `feedbacks`;
    return await axios.get(uri, { params: filters })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getFeedbackTasks = async (feedbackId: number, filters?: TaskPagingFilter): Promise<any> => {
    const uri = `feedbacks/${feedbackId}/tasks`;
    return await axios.get(uri, { params: filters })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const closeFeedback= async (feedbackId: number): Promise<any> => {
    const uri = `feedbacks/close-feedback/${feedbackId}`;
    return await axios.put(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const openFeedback = async (feedbackId: number): Promise<any> => {
    const uri = `feedbacks/open-feedback/${feedbackId}`;

    return await axios.put(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }
}
