import { SyntheticEvent, useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";
import { ProjectDocument } from "../../../api/models/ProjectDocument.model";
import ViewButton from "../../../components/buttons/ViewButton";
import "../Project.css";
import { ApiException } from "../../../api/errors/ApiException";
import axios from "axios";

const ProjectDetails = (props: { id: number }): JSX.Element => {
  const [projectDocuments, setProjectDocuments] = useState<ProjectDocument[]>([]);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ipHolder, setIpHolder] = useState('');
  const [image, setImage] = useState('');
  const [genre, setGenre] = useState('');
  const [state, setState] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [responsibleEmail, setResponsibleEmail] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [project] = await Promise.all([
          API.getProject(props.id),
        ]);

        setTitle(project.title);
        setDescription(project.description);
        setIpHolder(project.ip_holder);
        setImage(project.image);
        setGenre(project.genre?.name);
        setState(project.state?.name);
        setCompanyName(project.company?.name);
        setResponsibleEmail(project.responsible?.email);
        setProjectDocuments(project.project_documents);
      } catch (err) {
        setError("Oops! Connection error");
        setShow(true);
      }
    })()
  }, [props.id]);

  const handleClose = () => {
    setModalShow(false);
    setShow(false);
  }

  const downloadDocument = async (e: SyntheticEvent, projectDocument: ProjectDocument) => {
    e.preventDefault();
    const params = { original: projectDocument.original_name }
    axios.get(projectDocument.url, { params })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', projectDocument.original_name);
        document.body.appendChild(link);
        link.click();
      });
  }

  return (
    <>
      <ViewButton onClick={() => setModalShow(true)} placement={"top"} hover="View more details" />
      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate>
          <Modal.Body className="p-0">
            <Form.Group className="mb-3 d-flex flex-column">
              <img src={image} className="detail-image"/>
            </Form.Group>

            <Col className="m-4">
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <p className="text-muted">{description}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>IP Holder</Form.Label>
                <p className="text-muted">{ipHolder}</p>
              </Form.Group>
              <hr />
              <Row>
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Genre</Form.Label>
                  <p className="text-muted">{genre}</p>
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-3 ">
                  <Form.Label>Stage</Form.Label>
                  <p className="text-muted">{state}</p>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <p className="text-muted">{companyName}</p>
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Responsible</Form.Label>
                  <p className="text-muted">{responsibleEmail}</p>
                </Form.Group>
              </Row>
              <hr />
              <Form.Group className="mb-3">
                <Form.Label>Documents</Form.Label>
                <div className="d-flex flex-wrap gap-2">
                  {projectDocuments?.map((projectDocument:ProjectDocument, it) => 
                    <a key={it} onClick={(e) => downloadDocument(e, projectDocument)} href={projectDocument.url}>{projectDocument.original_name}</a>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Modal.Body>
        </Form>
        </Modal>
    </>
  )
}

export default ProjectDetails;