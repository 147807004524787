import { useEffect, useState } from "react";
import { Pageable } from "../../api/models/Pageable";
import { MiniLoader } from "../Loader";

interface ItemProps<T> {
  data: T;
}

interface InfinityScrollProps<T> {
  fetcher: (page: number) => Promise<Pageable<T>>;
  component: (props: ItemProps<T>) => JSX.Element;
}

const InfinityScroll = <T extends any>(props: InfinityScrollProps<T>): JSX.Element => {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [data, setData] = useState<T[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [lastPage, fetching]);

  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        const { data: newData, meta } = await props.fetcher(page);
        setData([...data, ...newData]);
        setLastPage(meta.last_page);
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight * 0.9 && page < meta.last_page) {
          setPage(page + 1);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [page]);

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight * 0.9 && !fetching && page < lastPage) {
      setPage(page + 1);
    }
  };

  return <>
    {data.map((item: T, index: number) => <props.component data={item} key={index} />)}
    {fetching && <MiniLoader />}
  </>;
};

export default InfinityScroll;