import { User } from "../api/models/User.model";
import BaseState from "./base/BaseState";

class State extends BaseState {
  constructor(
    public user: User = {} as User,
    public showLoader = false,
  ) {
    super();
  }
}

export default State;
