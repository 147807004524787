export class Profile {
  constructor(
    public id: number = 0,
    public first_name: string = '',
    public last_name: string = '',
    public name: string = '',
    public avatar: string = '',
    public about: string = '',
    public occupation: string = '',
    public skills: string = '',
    public github: string= '',
    public twitter: string= '',
    public linkedin: string= '',
    public instagram: string= '',
  ) { }
}

export type ProfileCreateDto = {
  first_name: string;
  last_name: string;
  avatar: string;
  about: string;
  occupation: string;
  skills: string;
  github: string|null;
  twitter: string|null;
  linkedin: string|null;
  instagram: string|null;
}
