import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type DeleteButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const DeleteButton = (props: DeleteButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-danger"
    iconName="trash"
  />
);

export default DeleteButton;
