import { WithId } from "../../types/Generics";

export class Company implements WithId {
  constructor(
    public id: number = 0,
    public name: string = '',
    public description: string = '',
    public logo: string = '',
    public created_at: string = '',
  ) {}
}