/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TaskPagingFilter } from '../../../api/models/Task.model';
import TaskState from './TaskState';

class ContextType {
  constructor(
    public state: TaskState = {} as TaskState,
    public getTasks: (filters?: TaskPagingFilter) => Promise<void> = async (_) => {},
  ){}
} 

const TaskContext = createContext<ContextType>({} as ContextType);

export default TaskContext;