import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../themes/Colors';

const Container = styled.div`
  background-color: ${colors.dark};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFoundScreen = () => {

  return (
    <Container>
      <div>
        <h1 style={{ marginBottom: 20 }}>
          404 Not found
        </h1>
        <Link to="/" style={{ marginTop: 10 }}>
          <Button className='btn-primary'>{'Back to home'}</Button>
        </Link>
      </div>
    </Container>
  );
}

export default NotFoundScreen;