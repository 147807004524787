import { useHistory } from "react-router-dom";
import { Project, ProjectPageable } from "../../api/models/Project.model";
import { API } from "../../api/api";
import './Dashboard.css';
import InfinityScroll from "../../components/lazy/InfinityScroll";
import { Badge } from "react-bootstrap";
import GoButton from "../../components/buttons/GoButton";

const Dashboard = (): JSX.Element => {

  const fetchProjects = (page: number): Promise<ProjectPageable> => 
    API.getProjects({
      page,
      created_at_sort: 'DESC',
      sort_criteria: ['created_at'],
    });

  return (
    <>
      <div className="card shadow-card mb-2">
        <div className="card-body row">
          <InfinityScroll fetcher={fetchProjects} component={CardProject} />
        </div>
      </div>
    </>
  )
}

const CardProject = ({ data: project }: { data: Project }) => {
  const history = useHistory();
  const view = (id: number) => {
    history.push(`/projects/${id}`);
  }
  return (
    <div className="col-md-3 mb-4">
      <div  className="card shadow-card h-100" style={{ maxWidth: '22rem' }}>
        <div className="card-block h-100 d-flex flex-column">
          <img className="card-img-top card-game-img" src={Project.getImage(project)} alt={project.title} draggable="false" onDragStart={(e) => {e.preventDefault();}}/>
          <div className="card-body">{project.title}</div>
          <div className="card-footer bg-white d-flex justify-content-between">
            <Badge bg='success' style={{margin: 'auto 0'}}>{`IP: ${project.ip_holder}`}</Badge >
            <GoButton onClick={() => view(project.id)} placement="top" hover="Go to project"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;