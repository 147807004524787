import { API } from "../../../api/api";
import { SyntheticEvent, useState, useContext } from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import CompanyContext from "../context/CompanyContext";
import AddButton from "../../../components/buttons/AddButton";
import axios from "axios";

const CompanyCreate = (): JSX.Element => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');
  
  const { getCompanies } = useContext(CompanyContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(true);

  const imageUpload = async (files: FileList | null) => {
    if (files === null || files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setLogo(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Add loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      API.createCompany({
        name,
        description,
        logo,
      })
      .then(() => {
        getCompanies(1);
        setModalShow(false);
        setValidated(false);
      })
      .catch((err) => {
        setError(err.message);
        setShow(true);
      })
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getCompanies(1);
    setModalShow(false);
    setValidated(false);
  }

  return (
    <>
      <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add Company" />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create company</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            {error && show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Company name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Company name" onChange={e => setName(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Company logo</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )}/>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company logo.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"  size="sm" placeholder="Description"  onChange={ e => setDescription(e.target.value) } />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company description.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default CompanyCreate;