import { Company } from "../../../api/models/Company.model";
import BaseState from "../../../context/base/BaseState";

class CompanyState extends BaseState {
  constructor(
    public company: Company = {} as Company,
    public companies: Company[] = [],
    public page: number = 1,
    public lastPage: number = 1,
  ){
    super();
  }
}

export default CompanyState;