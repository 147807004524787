/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';
import { FeedbackPagingFilter } from '../../../api/models/Feedback.model';
import { TaskPagingFilter } from '../../../api/models/Task.model';
import FeedbackState from './FeedbackState';

class ContextType {
  constructor(
    public state: FeedbackState = {} as FeedbackState,
    public getFeedbackTasks: (filters?: TaskPagingFilter) => Promise<void> = async (_) => {},
    public getFeedbacks: (filters?: FeedbackPagingFilter) => Promise<void> = async (_) => {},
    public getFeedback: () => Promise<void> = async () => {},
    public startEdit: () => void = () => {},
    public closeEdit: () => void = () => {},
  ){}
} 

const FeedbackContext = createContext<ContextType>({} as ContextType);

export default FeedbackContext;