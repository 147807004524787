import { useEffect } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import './Table.css';

type InputDispatcherProps = {
  refValue: string|null;
  dispatcher: Dispatch<SetStateAction<string|null>>;
  className?: string;
  style?: any;
}

const InputDispatcher = ({
  refValue,
  dispatcher,
  className,
  style,
}: InputDispatcherProps): JSX.Element => {
  const [value, setValue] = useState(refValue);
  const [timerId, setTimerId] = useState<NodeJS.Timeout|undefined>();

  useEffect(() => {
    setValue(refValue);
  }, [refValue])

  const onChange = (newValue:string) => {
    setValue(newValue);

    if(timerId !== undefined){
      clearTimeout(timerId);
    }

    setTimerId(setTimeout(() => {
      dispatcher(newValue);
      setTimerId(undefined);
    }, 300));
  }

  return (
    <InputGroup size="sm" className="mb-0">
      <FormControl size='sm' autoComplete="off"
        className={className}
        value={value || ''}
        placeholder='search...'
        onChange={e => onChange(e.target.value)}
        style={style} required
      />
    </InputGroup>
  )
}

export default InputDispatcher;