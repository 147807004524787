import axios, { AxiosError, AxiosResponse } from "axios";
import { Task, TaskPagingFilter } from "../models/Task.model";
import { TaskCategory } from "../models/TaskCategory.model";
import { TaskPriority } from "../models/TaskPriority.model";
import { TaskState } from "../models/TaskState.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TaskAPI {

  export const getTask =  async (id: number): Promise<Task> => {
    const uri = `tasks/${id}`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  export const getTaskStates = async (): Promise<TaskState[]> => {
    const uri = `task-states`;
    return await axios.get(uri)
    .then((res: AxiosResponse) => {
      return res.data
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const getTaskPriorities = async (): Promise<TaskPriority[]> => {
    const uri = `task-priorities`;
    return await axios.get(uri)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const getTaskCategories = async (): Promise<TaskCategory[]> => {
    const uri = `categories`;
    return await axios.get(uri)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const createTask = async (body: any): Promise<Task | any > => {
    const uri = `tasks`;
    return await axios.post(uri, body)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const updateTask = async ( id: number, body: any): Promise<Task | any> => {
    const uri = `tasks/${id}`;
    return await axios.put(uri, body)
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const createAssignation = async (body: any): Promise<any> => {
    const uri = `assignations`;
    return await axios.post(uri, body);
  }

  export const getTasks =  async (filters?: TaskPagingFilter): Promise<Task[] | any> => {

    return await axios.get("tasks", { params: filters })
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }
}
