/* eslint-disable no-case-declarations */
import { Project } from "../../../api/models/Project.model";
import { Release } from "../../../api/models/Release.model";
import ReleaseState from "./ReleaseState";

const ReleaseReducer = (state: ReleaseState, action: {payload: any, type: string}): ReleaseState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_RELEASES":
      return {
        ...state,
        releases: payload.releases as Release[],
        lastPage: payload.lastPage
      }

    case "GET_PROJECT":
      const project = payload as Project;
      const currentRelease = project.releases.length > 0 && !project.releases[project.releases.length - 1].is_dummy ? project.releases[project.releases.length - 1] : undefined;
      const releaseForClients = project.clientRelease;
      const releaseForDevs = project.devRelease;
      return {
        ...state,
        project: project,
        currentRelease: currentRelease,
        releaseForClients: releaseForClients,
        releaseForDevs: releaseForDevs,
      }

    case "RELEASE_TO_TRY":
      return {
        ...state,
        releaseToTry: payload,
      }

    default:
      return state;
  }
}

export default ReleaseReducer;
