/* eslint-disable @typescript-eslint/no-empty-function */
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { Placement } from "react-bootstrap/esm/types";
import { isEmpty } from "../../utils/Objects";
import TooltipHelp from "../tooltips/TooltipHelp";

type SideLabel = "left" | "right";

type BaseButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  iconName?: IconName;
  label?: string|ReactNode,
  sideLabel?: SideLabel,
  customIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
}

const BaseButton = ({
  onClick = () => {},
  placement = "auto" as Placement,
  hover = "",
  iconName = "icons",
  className = "",
  disabled = false,
  customIcon,
  label,
  sideLabel,
}: BaseButtonProps): JSX.Element => (
  <TooltipHelp placement={placement} hover={hover}>
    <button className={["btn", className, (disabled ? "disabled" : "")].join(' ')} onClick={onClick}>
      {
        isEmpty(customIcon)
        ? getLabel(iconName, label, sideLabel)
        : customIcon
      }
    </button>
  </TooltipHelp>
);

const getLabel = (iconName: IconName, label?: string|ReactNode, sideLabel?: SideLabel): JSX.Element => {
  if (label !== undefined && label !== null) {
    switch (sideLabel) {
      case "left": return <>{label} <FontAwesomeIcon icon={["fas", iconName]} /></>
      case "right": return <><FontAwesomeIcon icon={["fas", iconName]} /> {label}</>
    }
  }

  return <FontAwesomeIcon icon={["fas", iconName]} />
}

export default BaseButton;
