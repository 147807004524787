import moment from "moment";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FeedbackSortCriteriaValues } from "../../../api/models/Feedback.model";
import ViewButton from "../../../components/buttons/ViewButton";
import Paginator from "../../../components/Paginator";
import ColumnFilter from "../../../components/table/ColumnFilter";
import ColumnSorter from "../../../components/table/ColumnSorter";
import OptionFilter from "../../../components/table/OptionFilter";
import TableFiltering from "../../../components/table/TableFiltering";
import { SortCriteriaOptions } from "../../../types/Generics";
import ProjectContext from "../context/ProjectContext";

const ProjectFeedbacks = (props: { projectId: number | undefined}): JSX.Element => {
  const [page, setPage] = useState(1);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const {state: {feedbacks, lastPage}, getProjectFeedbacks} = useContext(ProjectContext);
  const history = useHistory();

  const [version, setVersion] = useState<string|null>(null);
  const [createdBy, setCreatedBy] = useState<string|null>(null);
  const [closed, setClosed] = useState<boolean|null>(null);

  const [createdAtSorter, setCreatedAtSorter] = useState<SortCriteriaOptions>('DESC');
  const [sortersOrder, setSortersOrder] = useState<FeedbackSortCriteriaValues[]>(['created_at']);

  useEffect(() => {
    getProjectFeedbacks({
      page,
      version,
      created_by: createdBy,
      closed,
      created_at_sort: createdAtSorter,
      sort_criteria: sortersOrder,
    }).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId, page, version, createdBy, closed, createdAtSorter, sortersOrder]);

  const switchCreatedAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'created_at'));
    } else if (sortersOrder.indexOf('created_at') < 0) {
      sortersOrder.push('created_at');
      setSortersOrder(sortersOrder);
    }
    setCreatedAtSorter(sortCriteria);
  }

  const view = (releaseId: number, feedbackId: number) => {
    history.push(`/releases/${releaseId}/feedbacks/${feedbackId}`);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Version',
      Footer: 'version',
      accessor: 'version',
      canFilter: true,
      Filter: ColumnFilter(version, setVersion),
    },
    {
      Header: 'Created by',
      Footer: 'created_by',
      accessor: 'created_by',
      canFilter: true,
      Filter: ColumnFilter(createdBy, setCreatedBy),
    },
    {
      Header: 'Status',
      Footer: 'status',
      accessor: 'closed',
      Cell: (props : { value: string } ) => (
        <Badge bg={props.value !== "open" ? `dark` : `success`}>{props.value}</Badge>
      ),
      canFilter: true,
      Filter: OptionFilter({
        options: [{id: 1, value: "OPEN", closed: false }, {id: 2, value: "CLOSED", closed: true }],
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setClosed(option.closed),
      }),
    },
    {
      Header: 'Created at',
      Footer: 'created at',
      accessor: 'created_at',
      canFilter: false,
      canSort: true,
      Sorter: ColumnSorter({ defaultSortCriteria: createdAtSorter, setSortCriteria: switchCreatedAtSort })
    },
    { 
      Header: 'Action',
      Footer: '',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = feedbacks.map(feedback => ({
    id: feedback.id,
    project: feedback.release.project.title,
    version: feedback.release.version,
    created_by: feedback.created_by.profile === null ? feedback.created_by.email : feedback.created_by.profile.first_name + " " + feedback.created_by.profile.last_name,
    closed: feedback.closed ? 'closed' : 'open',
    created_at: moment(feedback.created_at).format('MM/DD/YYYY'),
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <ViewButton onClick={() => view(feedback.release.id, feedback.id)} placement="top" hover="View feedback" />
      </div>
    )
  }))

  return (
    <div className="pt-0 pb-0 mb-0 border-bottom w-100">
      <div className="">
        <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
      </div>
      <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
    </div>
  );
}

export default ProjectFeedbacks;