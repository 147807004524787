import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiException } from "../errors/ApiException";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GenreAPI {

  export const getGenre =  async (genreId: number): Promise<any> => {
    const uri = `genres/${genreId}`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  export const createGenre = async (body: any): Promise<any> => {
    const uri = `genres`;

    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }

  export const updateGenre = async (id: number, body: any): Promise<any> => {
  const uri = `genres/${id}`;

  return await axios.put(uri, body)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const deleteGenre = async (id: number): Promise<any> => {
    const uri =  `genres/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getGenres =  async (page=1, name?: string): Promise<any> => {

    const uri = `genres`;
    const params = {
      page,
      name
    }

    return await axios.get(uri, { params })
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }
}
