import { Link } from "react-router-dom";
import Guard from "../guards/Guard";

const SidebarUsers = (): JSX.Element => {
  return (
    <>
      <Guard entity="USERS" level="FULL">
        <li className="nav-item">
          <Link to={'/users'} className="nav-link ps-1">
            <i className="fas fa-users p-2"></i>
            Users
          </Link>
        </li>
      </Guard>
      <Guard entity="ROLES" level="FULL">
        <li className="nav-item">
          <Link to={'/roles'} className="nav-link ps-1">
            <i className="fas fa-user-tag p-2"></i>
            Roles
          </Link>
        </li>
      </Guard>
      <Guard entity="COMPANIES" level="FULL">
        <li className="nav-item">
          <Link to={'/companies'} className="nav-link ps-1">
            <i className="fas fa-building p-2"></i>
            Companies
          </Link>
        </li>
      </Guard>
      <li className="nav-item">
        <Link to={'/teams'} className="nav-link ps-1">
          <i className="fas fa-users p-2"></i>
          Teams
        </Link>
      </li>
    </>
  )
}

export default SidebarUsers;