import { SyntheticEvent } from "react";
import { CloseButton } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types"
import { doNothing } from "../../utils/Functions";
import Avatar from "../svg/Avatar"
import TooltipHelp from "../tooltips/TooltipHelp"

type PillIconProps = {
  icon: string;
  label?: string;
  placement?: Placement;
  hover?: string;
  className?: string;
  checkable?: boolean;
  onCheck?: (e: SyntheticEvent) => void;
}

const closeButtonStyles = {
  width: "8px",
  height: "8px",
  padding: "2px",
  backgroundSize: "6px",
};

const PillIcon = ({
  icon,
  label = "",
  placement = "auto" as Placement,
  hover = "",
  className = "",
  checkable = false,
  onCheck = doNothing,
}: PillIconProps):JSX.Element => (
  <TooltipHelp placement={placement} hover={hover}>
    <div className={["d-flex gap-1 align-items-center pill rounded-pill pe-1 bg-danger text-white", className].join(" ")}>
      <Avatar src={icon} alt="" />
      <div>{label}</div>
      { checkable &&
        <CloseButton className="rounded-circle border-dark border border-2" variant="white" style={closeButtonStyles} onClick={(e) => onCheck(e)}/>
      }
    </div>
  </TooltipHelp>
);

export default PillIcon;
