import { Editor } from '@toast-ui/react-editor';
import axios from "axios";
import { useEffect } from "react";
import { SyntheticEvent, useContext, useRef, useState } from "react";
import { Alert } from 'react-bootstrap';
import { ApiException } from '../../../api/errors/ApiException';
import { Feedback } from "../../../api/models/Feedback.model";
import SaveButton from "../../../components/buttons/SaveButton";
import FeedbackContext from "../context/FeedbackContext";

const FeedbackUpdate = (props: { feedback: Feedback }): JSX.Element => {
  const [body, setBody] = useState('');
  const editorRef = useRef(null) as any;
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const { getFeedback, closeEdit } = useContext(FeedbackContext);

  useEffect(() => {
    (async () => {
      setBody(props.feedback.body);
    })()
  }, [props.feedback])

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      if (body.length === 0) {
        setError("Feedback shouldn't be empty");
        setShow(true);
        return;
      }
      await axios.put(`feedbacks/${props.feedback.id}`, {
        body,
      });
      setBody('');
      editorRef.current.getInstance().setMarkdown('');
      getFeedback();
      closeEdit();
    } catch (err) {
      if (err instanceof ApiException) {
        setError(err.message);
      } else {
        setError("Oops, something went wrong");
      }
      setShow(true);
    }
  };

  return (
    <form onSubmit={submit}>
      {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
      <div className="mb-3">
        <Editor
          previewStyle="vertical"
          minHeight= "400px"
          height="auto"
          initialEditType="wysiwyg"
          initialValue={props.feedback.body}
          ref={ editorRef }
          hideModeSwitch={true}
        />
      </div>
      <SaveButton onClick={() => setBody(editorRef.current.getInstance().getMarkdown())} placement={"auto"} hover={"Save feedback"} />
    </form>
  )
}

export default FeedbackUpdate;
