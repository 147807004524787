import { Role } from "../../../api/models/Role.model";

class RoleState {
  constructor(
    public roles: Role[] = [],
    public page: number = 1,
    public lastPage: number = 1,
    public state = {hasError: false},
  ){}
}

export default RoleState;