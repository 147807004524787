import { useContext } from 'react';
import { SyntheticEvent, useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap';
import { API } from '../../../api/api';
import { Company } from '../../../api/models/Company.model';
import { Role } from "../../../api/models/Role.model";
import AddButton from '../../../components/buttons/AddButton';
import { CompanyIconOption } from '../../../components/select/IconOption';
import Search from '../../../components/select/Search';
import UserContext from '../context/UserContext';

const UserCreate = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roleId, setRoleId] = useState('');
  const [companyId, setCompanyId] = useState(0);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const { getUsers } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false); 

  const [error, setError] = useState('');
  const [show, setShow] = useState(true);

  useEffect( ()=> {
    (async () => {
      try {
        const companies = await API.getCompanies();
        const roles = await API.getRoles(1);

        setRoles(roles.data);
        setCompanies(companies.data);
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, [])
  
  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true && companyId > 0) {
      API.register({
        email: email,
        password: password,
        role_id: roleId,
        company_id: companyId,
      })
      .then(() => {
        getUsers(1, null);
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch((err) => {
        setError(err.message);
        setShow(true);
      })
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getUsers(1, null);
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  const selectCompany = (data: Company) => {
    setCompanyId(Number(data.id));
    setCompanyName(data.name);
  }

  const removeCompany = () => {
    setCompanyId(0);
    setCompanyName('');
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
    {/* <button className="btn btn-sm btn-success" onClick={() => setModalShow(true)}>Add</button> */}
    <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add User" />

    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Create user</Modal.Title>
      </Modal.Header>
        {error && show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="text" size="sm" placeholder="E-mail" onChange={ e => setEmail(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a emal.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" size="sm" placeholder="Password"  onChange={ e => setPassword(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Role</Form.Label>
                  <select className="form-control form-control-sm" onChange={ e => setRoleId(e.target.value) } required>
                    <option key={0} className="d-none"></option>
                      {roles.map((role:Role) => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                      ))}
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a role.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Company</Form.Label>
                <Search 
                  className={["form-control", afterValidationClass(companyId > 0)].join(' ')}
                  search={API.searchCompanies}
                  item={CompanyIconOption}
                  onItemClick={selectCompany}
                  onRemoveContent={removeCompany}
                  value={companyName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a password confirmation.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UserCreate