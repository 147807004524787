import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type DownloadButtonProps = {
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
  path: string;
}

const DownloadButton = ({ path, disabled, ...props }: DownloadButtonProps): JSX.Element => {
  const [showLoading, setShowLoading] = useState(false);

  const downloadAction = () => {
    setShowLoading(true);
    axios.get(path, { responseType: 'blob' })
      .then((res: AxiosResponse) => {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(res.headers['content-disposition']);
        const filename = matches !== null && matches[1].replace(/['"]/g, '');

        if (!filename) {
          throw new Error('Invalid headers')
        }

        const blob = new Blob([res.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        setShowLoading(false);
      });
  }

  return (
    <BaseButton
      {...props}
      onClick={downloadAction}
      className="btn-sm btn-warning text-white"
      disabled={disabled && !showLoading}
      iconName="download"
      customIcon={showLoading ? <Spinner animation="border" role="status" style={{height:14, width:14}}/> : undefined}
    />
  );
}
export default DownloadButton;
