/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Feedback, FeedbackPagingFilter } from "../../../api/models/Feedback.model";
import { Task, TaskPagingFilter } from "../../../api/models/Task.model";
import FeedbackState from "./FeedbackState";

type GetTasksTypePayload = {
  tasks: Task[];
  lastPage: number;
  filters: TaskPagingFilter;
};

type GetFeedbacksPayload = {
  feedbacks: Feedback[];
  lastPage: number;
  filters: FeedbackPagingFilter;
};

const FeedbackReducer = (state: FeedbackState, action: {payload: any, type: string}): FeedbackState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_TASKS":
      // eslint-disable-next-line no-case-declarations
      const { tasks, lastPage, filters: defaultTaskFilters }: GetTasksTypePayload = payload;
      return {
        ...state,
        tasks,
        lastPage,
        defaultTaskFilters,
      };

    case "GET_FEEDBACK":
      return {
        ...state,
        feedback: payload as Feedback,
      }

    case "GET_FEEDBACKS":
      // eslint-disable-next-line no-case-declarations
      const { feedbacks, lastPage: feedbackLastPage, filters }: GetFeedbacksPayload = payload;
      return {
        ...state,
        feedbacks,
        lastPage: feedbackLastPage,
        defaultFilters: filters,
      }

    case "EDIT_FEEDBACK":
      return {
        ...state,
        editFeedback: payload,
      }

    default:
      return state;
  }
}

export default FeedbackReducer;
