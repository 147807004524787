import { isEmpty } from "../../utils/Objects";
import { Company } from "./Company.model";
import { Genre } from "./Genre.model";
import { Pageable, Paging } from "./Pageable";
import { Release } from "./Release.model";
import { User } from "./User.model";
import gameCardDefault from "../../assets/game-card-default.jpg";
import { ProjectState } from "./ProjectState.model";
import { SortCriteriaOptions } from "../../types/Generics";
import { ProjectDocument } from "./ProjectDocument.model";

export class Project {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public ip_holder: string,
    public image: string,
    public closed: boolean,
    public views: number,
    public created_at: string,
    public updated_at: string,
    public created_by: User,
    public genre: Genre,
    public releases: Release[],
    public users: User[],
    public responsible: User,
    public company: Company,
    public devRelease: Release,
    public clientRelease: Release,
    public state: ProjectState,
    public project_documents: ProjectDocument[],
  ) {}

  static getImage = (project: Project): string => {
    return !isEmpty(project.image)
      ? project.image
      : gameCardDefault;
  }
}


export type ProjectPageable = Pageable<Project>;

export type ProjectCreateDto = {
  title: string|null;
  description?: string|null;
  ip_holder?: string|null;
  image?: string|null;
  closed?: boolean;
  views?: number;
  genre_id?: number;
  responsible_id?: number;
  company_id?: number;
  state_id?:number;
  project_documents?: ProjectDocument[];
}

export type ProjectUpdateDto = {
  title?: string;
  description?: string;
  ip_holder?: string;
  image?: string;
  closed?: boolean;
  views?: number;
  genre_id?: number;
  responsible_id?: number;
  company_id?: number;
  state_id?: number;
  dev_release_id?: number;
  client_release_id?: number;
  project_documents?: ProjectDocument[];
}

export interface ProjectFilter {
  title?: string|null;
  // title_sort?: SortCriteriaOptions

  genre?: string|null;
  // genre_sort?: SortCriteriaOptions

  state?: number|null;
  // state_sort?: SortCriteriaOptions

  company?: string|null;
  // company_sort?: SortCriteriaOptions

  responsible?: string|null;
  // responsible_sort?: SortCriteriaOptions

  created_at_sort?: SortCriteriaOptions

  sort_criteria?: ProjectSortCriteriaValues[]
}

export type ProjectPagingFilter = ProjectFilter & Paging;

export type ProjectSortCriteriaValues = 'title' | 'genre' | 'state' | 'company' | 'responsible' | 'created_at'
