import { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import { TaskCategory } from "../../../api/models/TaskCategory.model";
import { TaskPriority } from "../../../api/models/TaskPriority.model";
import { TaskState } from "../../../api/models/TaskState.model";
import { User } from "../../../api/models/User.model";
import AddButton from "../../../components/buttons/AddButton";
import { UserIconOption } from "../../../components/select/IconOption";
import Search from "../../../components/select/Search";
import FeedbackContext from "../../Feedback/context/FeedbackContext";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { isEmpty } from "../../../utils/Objects";

const TaskCreate = (props: { feedbackId: number }): JSX.Element => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  const [priorityId, setPriorityId] = useState(0);
  const [stateId, setStateId] = useState(0);

  const [assignedTo, setAssignedTo] = useState<number>(0);
  const [assignedToEmail, setAssignedToEmail] = useState<string>('');

  const [states, setStates] = useState<TaskState[]>();
  const [priorities, setPriorities] = useState<TaskPriority[]>();
  const [categories, setCategories] = useState<TaskCategory[]>();

  const { state: { feedback }, getFeedbackTasks: getTasks, getFeedback } = useContext(FeedbackContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const editorRef = useRef(null) as any;

  useEffect(() => {
    (async () => {
      try {
        const states = await API.getTaskStates();
        const priorities = await API.getTaskPriorities();
        const categories = await API.getTaskCategories();

        if(isEmpty(feedback)) {
          await getFeedback();
        }
  
        setStates(states);
        setStateId(states[0].id);
        setPriorities(priorities);
        setCategories(categories);
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, []);

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    // TODO: Agregar loader.
    setValidated(true);

    if (form.checkValidity() === true && assignedTo > 0) {
      API.createTask({
        title,
        description,
        due_at: dueDate,
        category_id: categoryId,
        priority_id: priorityId,
        state: stateId,
        feedback_id: props.feedbackId,
        assigned_to: assignedTo,
      })
      .then(() => {
        getTasks();
        setModalShow(false);
        setShow(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getTasks();
    setModalShow(false);
    setValidated(false);
    setShow(false);
    setAssignedTo(0);
    setAssignedToEmail('');
  }

  const selectUser = (data: User) => {
    setAssignedTo(Number(data.id));
    setAssignedToEmail(data.email);
  }

  const removeUser = () => {
    setAssignedTo(0);
    setAssignedToEmail('');
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
      <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add task" disabled={feedback.closed} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <Modal.Title>Create task</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Task title" onChange={e => setTitle(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task title.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Description</Form.Label>
                  <Editor
                    previewStyle="vertical"
                    initialEditType="wysiwyg"
                    initialValue=""
                    hideModeSwitch={true}
                    ref={ editorRef }
                  />
                {/* <Form.Control as="textarea" type="text" placeholder="Task description" onChange={e => setDescription(e.target.value)} required /> */}
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task description.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Row>
              <Form.Group as={Col} controlId="validationCustom03" className="mb-3">
                <Form.Label>Due at</Form.Label>
                <Form.Control type="date"  size="sm" placeholder="Task due at" onChange={e => setDueDate(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>State</Form.Label>
                <select className="form-control form-control-sm " onChange={e => setStateId(Number(e.target.value))} required>
                  {states?.map((state: TaskState) => (
                    <option key={state.id} value={state.id}>{state.state}</option>
                  ))}
                </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>Priority</Form.Label>
                <select className="form-control form-control-sm" onChange={e => setPriorityId(Number(e.target.value))} required>
                  <option key={0} className="d-none"></option>
                    {priorities?.map((priority: TaskPriority) => (
                      <option key={priority.id} value={priority.id}>{priority.name}</option>
                    ))}
                </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>Category</Form.Label>
                <select className="form-control form-control-sm" onChange={e => setCategoryId(Number(e.target.value))} required>
                  <option key={0} className="d-none"></option>
                  {categories?.map((category: TaskCategory) => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </Form.Group>
            </Row>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Assign to</Form.Label>
                  <Search 
                    className={["form-control", afterValidationClass(assignedTo > 0)].join(' ')}
                    search={(email) => API.searchProjectTeamByEmail(feedback?.release?.project?.id, email)}
                    item={UserIconOption}
                    onItemClick={selectUser}
                    onRemoveContent={removeUser}
                    value={assignedToEmail}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task title.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary" onClick={() => setDescription(editorRef.current.getInstance().getMarkdown())}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default TaskCreate;