import { useEffect } from "react";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Task } from "../../../api/models/Task.model";
import ViewButton from "../../../components/buttons/ViewButton";
import ReactMarkdown from "react-markdown";

const TaskView = (props: { taskId: number }): JSX.Element => {
  const [task, setTask] = useState<Task>({} as Task)
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const task = await API.getTask(props.taskId);
        setTask(task);
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, [props.taskId]);

  const handleCancel = () => {
    setModalShow(false);
    setShow(false);
  }

  return (
    <>
      <ViewButton onClick={() => setModalShow(true)} placement={"auto"} hover={"View task"} />
      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <Modal.Title>View task</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task title" value={task.title} disabled />
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Description</Form.Label>
                <ReactMarkdown>{task.description}</ReactMarkdown>
              </Form.Group>
            </Col>
            <Row>
              <Form.Group as={Col} controlId="validationCustom03" className="mb-3">
                <Form.Label>Due at</Form.Label>
                <Form.Control type="date" size="sm" placeholder="Task due at" value={task.due_at} disabled />
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task state" value={task.state?.state} disabled />
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>Priority</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task priority" value={task.priority?.name} disabled />
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom05" className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task category" value={task.category?.name} disabled />
              </Form.Group>
            </Row>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Assign to</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task category" value={task.assignation?.assigned_to.email} disabled />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Close</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default TaskView;