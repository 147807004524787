/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import CompanyState from './CompanyState';

class ContextType {
  constructor(
    public state: CompanyState = {} as CompanyState,
    public getCompanies: (page: number) => Promise<void> = async (_) => {},
    public getCompany: (id: number) => Promise<void> = async  () => {},
  ){}
}

const CompanyContext = createContext<ContextType>({} as ContextType);

export default CompanyContext;