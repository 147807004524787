import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type NewTabButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const NewTabButton = (props: NewTabButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className='btn-sm btn-primary'
    iconName='arrows-alt'
  />
);

export default NewTabButton;