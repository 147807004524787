import { useContext } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "./context/UserContext";
import UserCreate from "./components/UserCreate";
import UserUpdate from "./components/UserUpdate";
import { API } from "../../api/api";
import UserProvider from "./context/UserProvider";
import SimpleCard from "../../components/card/SimpleCard";
import { Alert, Button, Modal } from "react-bootstrap";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import ColumnFilter from "../../components/table/ColumnFilter";
import ViewButton from "../../components/buttons/ViewButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import TableFiltering from "../../components/table/TableFiltering";
import { UsersStatistics } from "../../api/models/Statistics.model";
import { User } from "../../api/models/User.model";
import { isEmpty } from "../../utils/Objects";
import moment from "moment";
import UserProfileCreate from "./components/UserProfileCreate";
import UserProfileUpdate from "./components/UserProfileUpdate";
import Paginator from "../../components/Paginator";

const UserList = (): JSX.Element => {
  return (
    <UserProvider  userId={undefined}>
      <UserTable/>
    </UserProvider>
  )
}

const imageIconStyle = {
  width: 42,
  height: 42,
}

const UserTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<UsersStatistics>();
  const { state: {users, lastPage}, getUsers } = useContext(UserContext);
  const [showException, setShowException] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [email, setEmail] = useState<string|null>(null);
  const history = useHistory();

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    getUsers(page, email).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, email])

  useEffect(() => {
    (async () => {
      try {
        const statistics = await API.getUsersStatistics();
        setStatistics(statistics);
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, [])

  const del = async (id: number) => {
    setShowConfirmDelete(false);
    API.deleteUser(id)
    .then(() => {
      getUsers(1, null);
    })
    .catch(() => setShowException(true));
  }

  const delConfirm = (id: number) => {
    setUserToDelete(id);
    setShowConfirmDelete(true);
  }

  const view = (id: number) => {
    history.push(`/users/${id}/profile`);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Avatar',
      Footer: 'avatar',
      accessor: 'avatar',
      maxWidth: 70,
      minWidth: 70,
      Cell: (props : { value: string } ) => (
        <img
          src={props.value}
          className='cell-image'
          style= {imageIconStyle}
          alt='thumbnail'
        />
      ),
      canFilter: false,
    },
    { 
      Header: 'Email',
      Footer: 'email',
      accessor: 'email',
      canFilter: true,
      Filter: ColumnFilter(email, setEmail),
    },
    { 
      Header: 'Role',
      Footer: 'role',
      accessor: 'role',
      canFilter: false,
    },
    { 
      Header: 'Name',
      Footer: 'name',
      accessor: 'name',
      canFilter: false,
    },
    { 
      Header: 'Company',
      Footer: 'company',
      accessor: 'company',
      canFilter: false,
    },
    { 
      Header: 'Created at',
      Footer: 'created_at',
      accessor: 'created_at',
      canFilter: false,
    },
    { 
      Header: 'Actions',
      Footer: '',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = users.map((user: User) => ({
    id: user.id,
    avatar: User.getImage(user),
    email: user.email,
    role: user.role?.name,
    name: user.profile?.name,
    company: user.company?.name,
    created_at: moment(user.created_at).format('MM/DD/YYYY'),
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <ViewButton onClick={() => view(user.id)} placement="top" hover="View profile" disabled={isEmpty(user.profile)}/>
        <UserUpdate id={user.id}/>
        {!user.profile
          ? <UserProfileCreate userId={user.id} />
          : <UserProfileUpdate profile={user.profile} contact={user.contacts && user.contacts[0]} userId={user.id} />
        }
        <DeleteButton onClick={() => delConfirm(user.id)} placement="top" hover="Delete user" />
      </div>
    )
  }));

  return (
    <>
      <Modal show={showException} onHide={() => setShowException(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Not this time</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`It seems that user ${userToDelete} is working on a project, we can not delete it.`}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowException(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(userToDelete)} onCancel={() => setShowConfirmDelete(false)} />

      {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}

      <div className="card shadow-card mb-2">
        <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
          <SimpleCard value={statistics?.usersCount || 0} placeholder="TOTAL"/>
          <SimpleCard value={statistics?.adminCount || 0} placeholder="ADMIN"/>
          <SimpleCard value={statistics?.employeeCount || 0} placeholder="EMPLOYEE"/>
          <SimpleCard value={statistics?.clientCount || 0} placeholder="CLIENT"/>
        </div>
      </div>

      <div className="card shadow-card mb-2">
        <div className="card-body">
          <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
            <div className="h5">Users</div>
              <UserCreate/>
          </div>
          <div className="">
            <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
          </div>
        </div>
        <div className="card-footer">
          <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
        </div>
      </div>
    </>
  )
}

export default UserList;