import CompanyState from "./CompanyState";

const CompanyReducer = (state: CompanyState, action: {payload: any, type: string}): CompanyState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_COMPANY":
      // eslint-disable-next-line no-case-declarations
      const { company } = payload;
      return {
        ...state,
        company
      };
    case "GET_COMPANIES":
      // eslint-disable-next-line no-case-declarations
      const { companies, lastPage } = payload;
      return {
        ...state,
        companies,
        lastPage
      };
    case "ERROR":
      // eslint-disable-next-line no-case-declarations
      const { code } = payload;
      return {
        ...state,
        errorCode: code,
      };
    default:
      return state;
  }
}

export default CompanyReducer;
