import axios from "axios";
import { useEffect, useState } from 'react'
import { Alert, Modal } from "react-bootstrap";
import { Permission } from "../../../api/models/Permission.model";
import { Table } from "../../../api/models/Table.model";
import ViewButton from "../../../components/buttons/ViewButton";

type TableLevel = {[key: string]: string};

const RoleView = (props: {id: number}): JSX.Element => {
  const [name, setName] = useState('');
  const [tables, setTables] = useState<Table[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [tableLevel, setTableLevel] = useState({} as TableLevel);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [{ data: role }, { data: tables}] = await Promise.all([
          axios.get(`roles/${props.id}`),
          axios.get("tables"),
        ]);
        setName(role.name);
        setTables(tables);
        const tableLevel = {} as TableLevel;
        role.permissions.forEach((permission: Permission) => {
          const reg = /_(VIEW|CREATE|EDIT|FULL)/.exec(permission.name);
          if (reg !== null) {
            const level = reg[1];
            const table = permission.name.slice(0, reg.index);
            tableLevel[table] = level;
          }
        });
        setTableLevel(tableLevel);
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })();
  }, [props.id]);

  const handleClose = () => {
    setModalShow(false);
    setShow(false);
  } 

  return (
    <>
      <ViewButton onClick={() => setModalShow(true)} placement="top" hover="View role" />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          Role
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Modal.Body>
          <div className="mb-3">
            <h6>Name</h6>
            <p>{name}</p>
          </div>

          <div className="">
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Entity</th>
                  <th scope="col">Level</th>
                </tr>
              </thead>
              <tbody>
                {tables.map((table:Table) => (
                  <tr key={table.id}>
                    <td>{table.name}</td>
                    <td>{tableLevel[table.name] === undefined ? '-' : tableLevel[table.name]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RoleView;