import { CSSProperties } from "react";

interface GameProps {
  url: string;
  title: string;
  style?: CSSProperties;
  resolution: {
    height: number;
    width: number;
  }
}

const Game = ({ url, title, style, resolution }: GameProps): JSX.Element => {

  return (
    <iframe
      src={url}
      id="game"
      frameBorder={10}
      marginWidth={10}
      title={title}
      height={resolution.height}
      width={resolution.width}
      style={{
        resize: 'both',
        ...style
      }}
      allowFullScreen={true}
      loading="lazy"
      className="embed-responsive-item"
      allow="display-capture"
    ></iframe>
  )
}

export default Game;