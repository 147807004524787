import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiException } from "../errors/ApiException";
import { CompaniesStatistics, FeedbackStatistics, GenreStatistics, HomeStatistics, ProjectsStatistics, RolesStatistics, TasksStatistics, UsersStatistics } from "../models/Statistics.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StatisticAPI {

  export const getHomeStatistics = async (): Promise<HomeStatistics> => {
    return await axios.get('statistics/home')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUsersStatistics = async (): Promise<UsersStatistics> => {
    return await axios.get('statistics/users')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getTasksStatistics = async (): Promise<TasksStatistics> => {
    return await axios.get('statistics/tasks')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getRolesStatistics = async (): Promise<RolesStatistics> => {
    return await axios.get('statistics/roles')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getProjectsStatistics = async (): Promise<ProjectsStatistics> => {
    return await axios.get('statistics/projects')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getGenreStatistics = async (): Promise<GenreStatistics> => {
    return await axios.get('statistics/genres')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getFeedbackStatistics = async (): Promise<FeedbackStatistics> => {
    return await axios.get('statistics/feedbacks')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getCompaniesStatistics = async (): Promise<CompaniesStatistics> => {
    return await axios.get('statistics/companies')
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }
}
