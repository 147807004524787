/* eslint-disable react/jsx-key */
import { Table } from 'react-bootstrap';
import { useMemo } from 'react'
import { Column, useTable } from 'react-table';
import Loader, { MiniLoader } from '../Loader';
import NoResults from '../select/NoResults';

type TableFilteringProps = {
  columns: Column[];
  data: any;
  isWaitingData?: boolean;
};

const TableFiltering = (props: TableFilteringProps): JSX.Element => {

  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  )

  return (
    <Table striped bordered hover size="sm" {...getTableProps()} responsive="md">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th {...column.getHeaderProps()} className="align-top text-center">
                <div className='d-flex justify-content-center gap-2'>
                  {column.render('Header')}
                  {column.canSort && column.render('Sorter')}
                </div>
                <div>{column.canFilter && column.render('Filter')}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} >
              {row.cells.map(cell => (
                <td className="align-middle text-center" {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
      { data.length == 0 &&
        <tfoot>
          <tr>
            <td colSpan={props.columns.length}>
              { props.isWaitingData ? <MiniLoader /> : <NoResults /> }
            </td>
          </tr>
        </tfoot>
      }
    </Table>
  )
}

export default TableFiltering;