import { Company } from "../../api/models/Company.model";
import { Project } from "../../api/models/Project.model";
import { User } from "../../api/models/User.model";

type IconOptionProps = {
  icon: string;
  title: string;
  description?: string;
}

const style = {
  width: "35px",
  height: "35px",
  borderRadius: "50%"
}

const IconOption = ({ icon, title, description }: IconOptionProps): JSX.Element => {
  return <div className="d-flex align-items-center gap-2">
    <img src={icon} className="" alt="img" style={style} />
    <div>
      <div style={{fontSize: ".95rem"}}>{title}</div>
      <div className="text-muted" style={{fontSize: ".8rem"}}>{description}</div>
    </div>
  </div>
}

type UserIconOptionProps = {
  data: User;
};

export const UserIconOption = ({ data: user }: UserIconOptionProps): JSX.Element => (
  <IconOption 
    icon={User.getImage(user)}
    title={user.email}
    description={User.getUsername(user)}
  />
);

type CompanyIconOptionProps = {
  data: Company;
};

export const CompanyIconOption = ({ data: Company }: CompanyIconOptionProps): JSX.Element => (
  <IconOption 
    icon={Company.logo}
    title={Company.name}
  />
);

type ProjectIconOptionProps = {
  data: Project;
};

export const ProjectIconOption = ({ data: project }: ProjectIconOptionProps): JSX.Element => (
  <IconOption 
    icon={Project.getImage(project)}
    title={project.title}
  />
);

export default IconOption;
