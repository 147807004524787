import { ReactNode, useContext } from "react";
import { Permission } from "../../api/models/Permission.model";
import Context from "../../context/Context";

const levels: {[key: string]: number} = {
  "VIEW": 1,
  "CREATE": 2,
  "EDIT": 3,
  "FULL": 4
}

type GuardProps = {
  entity: string;
  level: string;
  children: ReactNode;
  exactly?: boolean;
}

const Guard = ({
  entity,
  level,
  children,
  exactly = false,
}: GuardProps): JSX.Element => {
  const { state: { user } } = useContext(Context);
  if (user.role == undefined) {
    return <></>
  }

  const flagGuard = user.role.permissions.some((permission: Permission) => {
    const strArr: string[] = permission.name.split('_');
    const userLevel: string = strArr.pop() || '';
    const userEntity: string = strArr.join('_');

    const levelEval = exactly ? levels[userLevel] == levels[level] : levels[userLevel] >= levels[level];
    return entity === userEntity && levelEval
  });

  return (
    <>
      { flagGuard && children }
    </>
  )
}

export default Guard;