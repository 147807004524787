import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { SortCriteriaOptions } from "../../types/Generics";

type ColumnSorterProps = {
  defaultSortCriteria?: SortCriteriaOptions
  setSortCriteria: (sortCriteria: SortCriteriaOptions) => void
}

const ColumnSorter = (props: ColumnSorterProps): JSX.Element[] => {
  const [sortCriteria, setSortCriteria] = useState<SortCriteriaOptions>(props.defaultSortCriteria ?? null)

  const switchAscSort = () => {
    const newSortCriteria = sortCriteria == 'ASC' ? null : 'ASC';
    setSortCriteria(newSortCriteria)
    props.setSortCriteria(newSortCriteria)
  }

  const switchDescSort = () => {
    const newSortCriteria = sortCriteria == 'DESC' ? null : 'DESC';
    setSortCriteria(newSortCriteria)
    props.setSortCriteria(newSortCriteria)
  }

  const switchSort = () => {
    let newSortCriteria: SortCriteriaOptions;

    switch (sortCriteria) {
      case 'DESC':
        newSortCriteria = null;
        break;
      case 'ASC':
        newSortCriteria = 'DESC';
        break;
      default:
        newSortCriteria = 'ASC';
        break;
    }
    setSortCriteria(newSortCriteria)
    props.setSortCriteria(newSortCriteria)
  }

  const getIconName = (sortCriteria: SortCriteriaOptions): IconName => {
    switch (sortCriteria) {
      case 'DESC':
        return 'sort-down';
      case 'ASC':
        return 'sort-up';
    }
    return 'sort';
  }

  return [
    <div onClick={switchSort} key="up" style={{cursor: 'pointer'}}>
      <FontAwesomeIcon icon={["fas", getIconName(sortCriteria)]} />
    </div>,
  ]
}

export default ColumnSorter;
