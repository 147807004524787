import { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import { TaskCategory } from "../../../api/models/TaskCategory.model";
import { TaskPriority } from "../../../api/models/TaskPriority.model";
import { TaskState } from "../../../api/models/TaskState.model";
import { User } from "../../../api/models/User.model";
import EditButton from "../../../components/buttons/EditButton";
import { UserIconOption } from "../../../components/select/IconOption";
import Search from "../../../components/select/Search";
import FeedbackContext from "../../Feedback/context/FeedbackContext";
import TaskContext from "../context/TaskContext";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { Link } from "react-router-dom";

type InitialState = {
  title: string,
  description: string,
  dueDate: string,
  categoryId: number,
  priorityId: number,
  stateId: number,
  assignedTo: number,
  assignedToEmail: string,
}

const TaskUpdate = (props: { taskId: number, feedbackId?: number, releaseId?: number, projectId?: number }): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  const [priorityId, setPriorityId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [assignedTo, setAssignedTo] = useState<number>(0);
  const [assignedToEmail, setAssignedToEmail] = useState<string>('');

  const [states, setStates] = useState<TaskState[]>();
  const [priorities, setPriorities] = useState<TaskPriority[]>();
  const [categories, setCategories] = useState<TaskCategory[]>();

  const { getTasks } = useContext(TaskContext);
  const { getFeedbackTasks } = useContext(FeedbackContext);

  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const editorRef = useRef(null) as any;

  useEffect(() => {
    (async () => {
      try {
        const states = await API.getTaskStates();
        const priorities = await API.getTaskPriorities();
        const categories = await API.getTaskCategories();
        const task = await API.getTask(props.taskId);

        setStates(states);
        setStateId(states[0].id);
        setPriorities(priorities);
        setCategories(categories);

        setTitle(task.title);
        setDescription(task.description);
        setDueDate(task.due_at);
        setCategoryId(task.category.id);
        setPriorityId(task.priority.id);
        setStateId(task.state.id);
        setAssignedTo(task.assignation?.assigned_to.id);
        setAssignedToEmail(task.assignation?.assigned_to.email);
        

        setInitialState({
          title: task.title,
          description: task.description,
          dueDate: task.due_at,
          categoryId: task.category.id,
          priorityId: task.priority.id,
          stateId: task.state.id,
          assignedTo: task.assignation?.assigned_to.id,
          assignedToEmail: task.assignation?.assigned_to.email,
        });
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, [props.taskId]);


  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    setDescription(editorRef.current.getInstance().getMarkdown());

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    // TODO: Agregar loader.
    setValidated(true);

    if (form.checkValidity() === true && assignedTo > 0) {
      API.updateTask(props.taskId, {
        title,
        description,
        due_at: dueDate,
        category_id: categoryId,
        priority_id: priorityId,
        state: stateId,
        assigned_to: assignedTo,
      })
      .then(() => props.projectId !== undefined ? getTasks() : getFeedbackTasks())
      .then(()=> {
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getTasks();
    setModalShow(false);
    setValidated(false);
    setShow(false);

    setTitle(initialState.title);
    setDescription(initialState.description);
    setDueDate(initialState.dueDate);
    setCategoryId(initialState.categoryId);
    setPriorityId(initialState.priorityId);
    setStateId(initialState.stateId);
    setAssignedTo(initialState.assignedTo);
    setAssignedToEmail(initialState.assignedToEmail);
  }

  const selectUser = (data: User) => {
    setAssignedTo(Number(data.id));
    setAssignedToEmail(data.email);
  }

  const removeUser = () => {
    setAssignedTo(0);
    setAssignedToEmail('');
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"auto"} hover={"Edit task"} />
      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <Modal.Title>Update task</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                { props.projectId && (
                  <Form.Label>
                      <>
                        <Link target='_blank' rel='noopener noreferrer' to={`/projects/${props.projectId}`}>Project</Link>/
                        <Link target='_blank' rel='noopener noreferrer' to={`/releases/${props.releaseId}/feedbacks/${props.feedbackId}`}>Feedback</Link>
                      </>
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Task title" value={title} onChange={e => setTitle(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task title.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Description</Form.Label>
                
                <Editor
                  previewStyle="vertical"
                  initialEditType="wysiwyg"
                  initialValue={ description }
                  hideModeSwitch={ true }
                  ref={ editorRef }
                />

                {/* <Form.Control as="textarea" type="text" placeholder="Task description" value={description} onChange={e => setDescription(e.target.value)} required /> */}
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task description.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Row>
              <Form.Group as={Col} controlId="validationCustom03" className="mb-3">
                <Form.Label>Due at</Form.Label>
                <Form.Control type="date" size="sm" placeholder="Task due at" value={dueDate} onChange={e => setDueDate(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>State</Form.Label>
                <select className="form-control form-control-sm" value={stateId} onChange={e => setStateId(Number(e.target.value))} required>
                  {states?.map((state: TaskState) => (
                    <option key={state.id} value={state.id}>{state.state}</option>
                  ))}
                </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom04" className="mb-3">
                <Form.Label>Priority</Form.Label>
                <select className="form-control form-control-sm" value={priorityId} onChange={e => setPriorityId(Number(e.target.value))} required>
                  <option key={0} className="d-none"></option>
                    {priorities?.map((priority: TaskPriority) => (
                      <option key={priority.id} value={priority.id}>{priority.name}</option>
                    ))}
                </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task due date.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="validationCustom05" className="mb-3">
                <Form.Label>Category</Form.Label>
                <select className="form-control form-control-sm" value={categoryId} onChange={e => setCategoryId(Number(e.target.value))} required>
                  <option key={0} className="d-none"></option>
                  {categories?.map((category: TaskCategory) => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </Form.Group>
            </Row>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Assign to</Form.Label>
                  <Search 
                    className={["form-control", afterValidationClass(assignedTo > 0)].join(' ')}
                    search={API.searchUserByEmail}
                    item={UserIconOption}
                    onItemClick={selectUser}
                    onRemoveContent={removeUser}
                    value={assignedToEmail}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid task title.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default TaskUpdate;