import { useEffect, useState, useContext } from "react";
import SimpleCard from "../../components/card/SimpleCard";
import RoleContext from "./context/RoleContext";
import RoleProvider from "./context/RoleProvider";
import RoleCreate from "./components/RoleCreate";
import RoleUpdate from "./components/RoleUpdate";
import RoleView from "./components/RoleView";
import { API } from "../../api/api";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import { Button, Modal } from "react-bootstrap";
import DeleteButton from "../../components/buttons/DeleteButton";
import TableFiltering from "../../components/table/TableFiltering";
import { RolesStatistics } from "../../api/models/Statistics.model";
import Guard from "../../components/guards/Guard";
import Paginator from "../../components/Paginator";

const RoleList = (): JSX.Element => {
  return (
    <RoleProvider>
      <RoleTable/>
    </RoleProvider>
  )
}

const RoleTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [statistics, setStatistics] = useState<RolesStatistics>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const {state: {roles, lastPage}, getRoles} = useContext(RoleContext);
  const [show, setShow] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    getRoles(page).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    (async () => {
      const statistics = await API.getRolesStatistics();
      setStatistics(statistics);
    })()
  }, [])

  const del = async (id: number) => {
    setShowConfirmDelete(false);
    API.deleteRole(id)
    .then(() => {
      getRoles(1);
    })
    .catch(() => setShow(true));
  }

  const delConfirm = (id: number) => {
    setRoleToDelete(id);
    setShowConfirmDelete(true);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    { 
      Header: 'Name',
      Footer: 'name',
      accessor: 'name',
      canFilter: false,
      // Filter: ColumnFilter(title, setTitle),
    },
    { 
      Header: 'Actions',
      Footer: 'actions',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = roles.map(role => ({
    ...role,
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <RoleView id={role.id} />
        <Guard entity="ROL" level="FULL">
          <RoleUpdate id={role.id} />
          <DeleteButton onClick={() => delConfirm(role.id)} placement="top" hover="Delete role" />
        </Guard>
      </div>
    )
  }));

  return (
    <>

    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Not this time</Modal.Title>
      </Modal.Header>
      <Modal.Body>It seems that the role is assigned to a user, we cannot delete it.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>

    <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(roleToDelete)} onCancel={() => setShowConfirmDelete(false)} />

    <div className="card shadow-card mb-2">
      <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
        <SimpleCard value={statistics?.adminCount || 0} placeholder="ADMIN"/>
        <SimpleCard value={statistics?.employeeCount || 0} placeholder="EMPLOYEE"/>
        <SimpleCard value={statistics?.clientCount || 0} placeholder="CLIENT"/>
      </div>
    </div>

    <div className="card shadow-card mb-2">
    <div className="card-body">
      <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
        <div className="h5">Roles</div>
        <Guard entity="ROL" level="FULL">
          <RoleCreate/>
        </Guard>
      </div>
      <div className="">
        <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
      </div>
    </div>
      <div className="card-footer">
        <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
      </div>
    </div>
    </>
  )
}

export default RoleList;