import { useEffect } from "react";
import TaskCreate from "../Task/components/TaskCreate";
import FeedbackTasks from "./components/FeedbackTasks";
import FeedbackProvider from "./context/FeedbackProvider";
import { useContext } from "react";
import FeedbackContext from "./context/FeedbackContext";
import FeedbackUpdate from "./components/FeedbackUpdate";
import TaskProvider from "../Task/context/TaskProvider";
import Guard from "../../components/guards/Guard";
import EditButton from "../../components/buttons/EditButton";
import CancelButton from "../../components/buttons/CancelButton";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import moment from "moment";

const FeedbackView = ({ match: { params: { feedbackId } } }: any): JSX.Element => {
  return (
    <>
      <FeedbackProvider feedbackId={feedbackId}>
        <div className="card shadow-card mb-2">
          <div className="card-header d-flex justify-content-between align-items-center">
            <div className="pt-3 pb-2 d-flex justify-content-between">
              <div className="h5">Feedback</div>
            </div>
            <Guard entity="FEEDBACKS" level="EDIT">
              <FeedbackButton />
            </Guard>
          </div>

          <div className="card-body">
            <FeedbackBody />
          </div>
        </div>

        <Guard entity="PROJECT_TASKS" level="VIEW">
          <TaskProvider>
            <div className="card shadow-card mb-2">
              {/* <div className="card-header"></div> */}
              <div className="card-body">
                <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
                  <div className="h5">Tasks</div>
                  <TaskCreate feedbackId={feedbackId} />
                </div>
                <FeedbackTasks feedbackId={feedbackId} />
              </div>
            </div>
          </TaskProvider>
        </Guard>
      </FeedbackProvider>
    </>
  )
};

const FeedbackBody = () => {
  const { state: { feedback, editFeedback }, getFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    (async () => {
      getFeedback()
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(feedback?.release?.project.id);

  return editFeedback ?
    <>
      <FeedbackUpdate feedback={feedback} />
    </>
    :
    <>
      <div className="text-muted mb-2" style={{ fontSize: '0.85rem' }}>
        {`Project: `}
        <Link target='_blank' rel='noopener noreferrer' to={`/projects/${feedback?.release?.project.id}`}>{feedback?.release?.project.title}</Link>
      </div>
      {feedback?.release?.deployed_by && (
        <div className="text-muted m-3" style={{ fontSize: '0.8rem' }}>
          <div className="text-muted">
            <div>
              {`Version: ${feedback?.release?.version}`}
            </div>
            <div>
              {`Created at: ${moment(feedback?.release?.created_at).format('MM/DD/YYYY')}`}
            </div>
            <div>
              {`Deployed by: ${feedback?.release?.deployed_by}`}
            </div>
          </div>
        </div>
      )}
      <hr />
      <ReactMarkdown>{feedback.body}</ReactMarkdown>
    </>
};

const FeedbackButton = () => {
  const { state: { feedback, editFeedback }, startEdit, closeEdit } = useContext(FeedbackContext);

  return editFeedback
    ? <CancelButton onClick={() => closeEdit()} placement={"auto"} hover={"Cancel edit feedback"} />
    : <EditButton onClick={() => startEdit()} placement={"auto"} hover={"Edit feedback"} disabled={feedback.closed} />

};

export default FeedbackView;
