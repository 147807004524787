import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type ReopenButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const ReopenButton = (props: ReopenButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-info"
    iconName="folder-open"
  />
);

export default ReopenButton;