import './styles/App.css';
import { useContext, useEffect } from 'react';
import Context from './context/Context';
import PagesNavigation from './pages/pages.navigation';
import Loader from './components/Loader';

const App = (): JSX.Element => {
  const { loader, getUser }  = useContext(Context);

  useEffect(() => {
    loader.show();
    getUser().finally(() => loader.hide());
  }, []);

  return (
    <div className="App">
      <PagesNavigation />
      <Loader />
    </div>
  );
}

export default App;
