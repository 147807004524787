import { SyntheticEvent, useContext, useState } from "react";
import logo from '../../assets/avatar.svg';
import './Login.css';
import { Redirect } from "react-router";
import { Alert } from "react-bootstrap";
import Context from "../../context/Context";
import { API } from "../../api/api";

const Login = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const [show, setShow] = useState(true);

  const { loader, getUser } = useContext(Context);

  const submit = (e: SyntheticEvent) => {
    e.preventDefault();
    loader.show();
    API.login({ email, password })
      .then(() => getUser())
      .then(() => {
        setRedirect(true);
        loader.hide();
      })
      .catch((err) => {
        loader.hide();
        setError(err.message);
        setShow(true);
      });
  }

  if (redirect) return <Redirect to={'/'}/>

  return (
    <main className="form-signin card shadow-card">
      <div className="d-flex justify-content-center mb-4">
        <img src={logo} width="100" height="100" className="rounded-circle" alt="killabunnies" />
      </div>
      {error && show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
      <form onSubmit = { submit } >
        <div className="form-floating">
          <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" required onChange = { e => setEmail(e.target.value) } />
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <div className="form-floating">
          <input type="password" className="form-control" id="floatingPassword" placeholder="Password" required onChange = { e => setPassword(e.target.value) } />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <button className="w-100 btn btn-lg btn-dark" type="submit">Sign in</button>
      </form>
    </main>
  )
}

export default Login;