import axios, { AxiosError, AxiosResponse } from "axios";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeviceAPI {

  export const getDevice =  async (deviceId: number): Promise<any> => {
    const uri = `devices/${deviceId}`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  export const createDevice = async (body: any): Promise<any> => {
    const uri = `devices`;

    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }

  export const updateDevice = async (id: number, body: any): Promise<any> => {
  const uri = `devices/${id}`;

  return await axios.put(uri, body)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const deleteDevice = async (id: number): Promise<any> => {
    const uri =  `devices/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getDevices =  async (page=1): Promise<any> => {

    const uri = `devices`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }
}
