import axios from "axios";
import { useContext } from "react";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import BaseButton from "../../../components/buttons/BaseButton";
import UserContext from "../context/UserContext";

const UserProfileCreate = ({ userId }: { userId: number }): JSX.Element => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [about, setAbout] = useState('');
  const [occupation, setOccupation] = useState('');
  const [avatar, setAvatar ] = useState('');
  const [skills, setSkills ] = useState('');
  const [github, setGithub] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');

  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');

  const { getUsers } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setAvatar(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      Promise.all([
        API.createUserProfile({
          first_name: firstName,
          last_name: lastName,
          avatar,
          about,
          occupation,
          skills,
          github,
          twitter,
          linkedin,
          instagram,
        }, userId),
        API.createContact({
          phone,
          street,
          number,
          email,
        }, userId),
      ])
      .then(async () => {
        await getUsers(1, null);
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  return (
    <>
      <BaseButton 
        onClick={() => setModalShow(true)}
        className="btn-sm btn-success"
        iconName="id-card"
        placement={"top"}
        hover={"Create Profile"}
      />

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Profile</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text"  size="sm" placeholder="First Name" onChange={e => setFirstName(e.target.value)} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text"  size="sm" placeholder="Last Name" onChange={e => setLastName(e.target.value)} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid last name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-3">
              <Form.Label>Avatar</Form.Label>
              <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )} required/>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid image.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              <Form.Label>About me</Form.Label>
              <Form.Control as="textarea"  size="sm" placeholder="Tell me a fun fact about yourself..."  onChange={e => setAbout(e.target.value)} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid company description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05" className="mb-3">
              <Form.Label>Occupation</Form.Label>
              <Form.Control type="text" size="sm" placeholder="Occupation" onChange={e => setOccupation(e.target.value)} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid occupation.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom06" className="mb-3">
              <Form.Label>Skills</Form.Label>
              <Form.Control as="textarea"  size="sm" placeholder="C++, Unreal, PixiJS"  onChange={e => setSkills(e.target.value)} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid skill list.</Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>GitHub</Form.Label>
                <Form.Control type="link" size="sm" placeholder="https://github.com/" onChange={e => setGithub(e.target.value)} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid github link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Twitter</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://twitter.com/" onChange={e => setTwitter(e.target.value)} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid twitter link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Linkedin</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://www.linkedin.com/" onChange={e => setLinkedin(e.target.value)} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid linkedin link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Instagram</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://www.instagram.com/" onChange={e => setInstagram(e.target.value)} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid instagram link.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <hr />
            <div className="h5 mb-3">Contact info</div>
            <Row>
              <Form.Group as={Col} md="3">
                <Form.Label>Phone number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Phone number" onChange={ e => setPhone(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>Street name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street name" onChange={ e => setStreet(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Street number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street number"  onChange={ e => setNumber(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email"  size="sm" placeholder="Email" onChange={ e => setEmail(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UserProfileCreate