import { Link } from "react-router-dom";
import Guard from "../guards/Guard";

const SidebarFeedbacks = (): JSX.Element => {
  return (
    <>
      <li>
      <Guard entity="FEEDBACKS" level="VIEW">
        <Link to={'/feedbacks'} className="nav-link ps-1">
          <i className="fas fa-thumbtack p-2"></i>
          Feedbacks
        </Link>
        </Guard>
        <Guard entity="TASKS" level="VIEW">
          <Link to={'/tasks'} className="nav-link ps-1">
            <i className="fas fa-tools p-2"></i>
            Task
          </Link>
        </Guard>
      </li>
    </>
  )
}

export default SidebarFeedbacks;