import { useEffect, useMemo, useState } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import { ApiException } from "../../api/errors/ApiException";
import { WithId } from "../../types/Generics";
import { usePrevious } from "../../utils/Functions";
import { isEmpty } from "../../utils/Objects";
import InputDispatcher from "../table/InputDispatcher";
import NoResults from "./NoResults";

interface ItemProps<T> {
  data: T;
}

interface SearchProps<T extends WithId> {
  className?: string;
  search: (str: string) => Promise<T[]>;
  item: (props: ItemProps<T>) => JSX.Element;
  onItemClick?: (data: T) => void;
  onRemoveContent?: () => void;
  value?: string;
  href?: (data: T) => string;
}

const Search = <T extends WithId>(props: SearchProps<T>): JSX.Element => {
  const [results, setResults] = useState<T[]>([]);
  const [criteria, setCriteria] = useState<string|null>(null);
  const prevCriteria = usePrevious(criteria);

  const value = useMemo(() => props.value, [props.value]);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      if (criteria !== null) { 
        props.search(criteria)
        .then((results) => {
          setResults(results);
        })
        .catch( (err: ApiException) => {
          setError(err.message);
          setShow(true);
        });
      }

      if(!isEmpty(prevCriteria) && isEmpty(criteria)) {
        props.onRemoveContent && props.onRemoveContent();
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, prevCriteria])

  useEffect(() => {
    if (value !== undefined) {
      setCriteria(value);
    }
  }, [value]);

  const bodyMenu = (() => {
    if (isEmpty(results)) {
      return <NoResults />
    }

    return results.map((result: T) => {
      const itemProps: any = {};
      if(props.onItemClick !== undefined) {
        itemProps['onClick'] = () => props.onItemClick !== undefined && props.onItemClick(result);
      }

      if(props.href !== undefined) {
        itemProps['href'] = props.href(result);
      }
      
      return (
        <Dropdown.Item key={result.id} {...itemProps}>
          <props.item data={result} />
        </Dropdown.Item>
      )
    })
  })()

  return (
    <Dropdown className="dropdown-button-drop-hidden">
      <Dropdown.Toggle variant="" size="sm" bsPrefix=" " className="d-block w-100 p-0">
        <InputDispatcher refValue={criteria} dispatcher={setCriteria} className={props.className}/>
      </Dropdown.Toggle>
      
      { criteria !== null &&
        <Dropdown.Menu>
          {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
          {bodyMenu}
        </Dropdown.Menu>
      }
    </Dropdown>
  )
}

export default Search;
