import { Route, Redirect } from "react-router-dom";
import Wrapper from "./Wrapper";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const hasToken = localStorage.getItem("token");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasToken === null) return <Redirect to={'/login'} />;
        return (
          <Wrapper>
            <Component {...props} />
          </Wrapper>
        )
      }}
    />
  );
};

export default ProtectedRoute;
