import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { API } from "../../api/api";
import { FeedbackSortCriteriaValues } from "../../api/models/Feedback.model";
import { FeedbackStatistics } from "../../api/models/Statistics.model";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import ConcludeButton from "../../components/buttons/ConcludeButton";
import ReopenButton from "../../components/buttons/ReopenButton";
import ViewButton from "../../components/buttons/ViewButton";
import SimpleCard from "../../components/card/SimpleCard";
import Paginator from "../../components/Paginator";
import ColumnFilter from "../../components/table/ColumnFilter";
import ColumnSorter from "../../components/table/ColumnSorter";
import OptionFilter from "../../components/table/OptionFilter";
import TableFiltering from "../../components/table/TableFiltering";
import { SortCriteriaOptions } from "../../types/Generics";
import FeedbackContext from "./context/FeedbackContext";
import FeedbackProvider from "./context/FeedbackProvider";

const FeedbackList = (): JSX.Element => {
  return (
    <FeedbackProvider  TaskId={undefined}>
      <FeedbackTable/>
    </FeedbackProvider>
  )
}

const FeedbackTable = (): JSX.Element => {
  const history = useHistory();

  const [statistics, setStatistics] = useState<FeedbackStatistics>();
  const [page, setPage] = useState(1);

  const [show, setShow] = useState(false);
  const [feedbackToClose, setFeedbackToClose] = useState<number>(0);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);

  const [feedbackToReopen, setFeedbackToReopen] = useState<number>(0);
  const [showConfirmReopen, setShowConfirmReopen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {state: {feedbacks, lastPage}, getFeedbacks} = useContext(FeedbackContext);

  const [project, setProject] = useState<string|null>(null);
  const [version, setVersion] = useState<string|null>(null);
  const [createdBy, setCreatedBy] = useState<string|null>(null);
  const [closed, setClosed] = useState<boolean|null>(null);

  const [createdAtSorter, setCreatedAtSorter] = useState<SortCriteriaOptions>('DESC');
  const [sortersOrder, setSortersOrder] = useState<FeedbackSortCriteriaValues[]>(['created_at']);


  useEffect(() => {
    getFeedbacks({
      page,
      project,
      version,
      created_by: createdBy,
      closed,
      created_at_sort: createdAtSorter,
      sort_criteria: sortersOrder,
    }).finally(() => setShowLoading(false))
  }, [page, project, version, createdBy, closed, createdAtSorter, sortersOrder]);

  useEffect(() => {
    (async () => {
      const statistics = await API.getFeedbackStatistics();
      setStatistics(statistics);
    })()
  }, []);

  const switchCreatedAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'created_at'));
    } else if (sortersOrder.indexOf('created_at') < 0) {
      sortersOrder.push('created_at');
      setSortersOrder(sortersOrder);
    }
    setCreatedAtSorter(sortCriteria);
  }

  const view = (releaseId: number, feedbackId: number) => {
    history.push(`/releases/${releaseId}/feedbacks/${feedbackId}`);
  }

  const close = (id: number) => {
    setShowConfirmClose(false);
    API.closeFeedback(id)
    .then(() => {
      getFeedbacks();
    })
    .catch((e) => {
      setErrorMessage(e.message);
      setShow(true);
    });
  };

  const reopen = (id: number) => {
    setShowConfirmReopen(false);
    API.openFeedback(id)
    .then(() => {
      getFeedbacks();
    })
    .catch((e) => {
      setErrorMessage(e.message);
      setShow(true)
    });
  }

  const closeConfirm = (id: number) => {
    setFeedbackToClose(id);
    setShowConfirmClose(true);
  }

  const reopenConfirm = (id: number) => {
    setFeedbackToReopen(id);
    setShowConfirmReopen(true);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    { 
      Header: 'Project',
      Footer: 'project',
      accessor: 'project',
      canFilter: true,
      Filter: ColumnFilter(project, setProject),
    },
    {
      Header: 'Version',
      Footer: 'version',
      accessor: 'version',
      canFilter: true,
      Filter: ColumnFilter(version, setVersion),
    },
    {
      Header: 'Created by',
      Footer: 'created_by',
      accessor: 'created_by',
      canFilter: true,
      Filter: ColumnFilter(createdBy, setCreatedBy),
    },
    {
      Header: 'Status',
      Footer: 'status',
      accessor: 'closed',
      Cell: (props : { value: string } ) => (
        <Badge bg={props.value !== "open" ? `dark` : `success`}>{props.value}</Badge>
      ),
      canFilter: true,
      Filter: OptionFilter({
        options: [{id: 1, value: "OPEN", closed: false }, {id: 2, value: "CLOSED", closed: true }],
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setClosed(option.closed),
      }),
    },
    {
      Header: 'Created at',
      Footer: 'created at',
      accessor: 'created_at',
      canFilter: false,
      canSort: true,
      Sorter: ColumnSorter({ defaultSortCriteria: createdAtSorter, setSortCriteria: switchCreatedAtSort })
    },
    { 
      Header: 'Action',
      Footer: '',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = feedbacks.map(feedback => ({
    id: feedback.id,
    project: feedback.release.project.title,
    version: feedback.release.version,
    created_by: feedback.created_by.profile === null ? feedback.created_by.email : feedback.created_by.profile.first_name + " " + feedback.created_by.profile.last_name,
    closed: feedback.closed ? 'closed' : 'open',
    created_at: moment(feedback.created_at).format('MM/DD/YYYY'),
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <ViewButton onClick={() => view(feedback.release.id, feedback.id)} placement="top" hover="View feedback" />
        {feedback.closed
          ? <ConcludeButton onClick={() => reopenConfirm(feedback.id)} placement="top" hover="Reopen feedback" />
          : <ReopenButton onClick={() => closeConfirm(feedback.id)} placement="top" hover="Close feedback" />
        }
      </div>
    )
  }))

  return (
    <>
      <ErrorAlert show={show}  title={"Not this time"} message={errorMessage} onCancel={() => setShow(false)} />
      <ConfirmAlert show={showConfirmClose} title={"Are you sure?"} message={"You will mark as done this feedback"} onConfirm={() => close(feedbackToClose)} onCancel={() => setShowConfirmClose(false)} />
      <ConfirmAlert show={showConfirmReopen} title={"Are you sure?"} message={"You will reopen this feedback"} onConfirm={() => reopen(feedbackToReopen)} onCancel={() => setShowConfirmReopen(false)} />

      <div className="card shadow-card mb-2">
        <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
          <SimpleCard value={statistics?.feedbackCount || 0} placeholder="FEEDBACKS"/>
          <SimpleCard value={statistics?.openFeedbackCount || 0} placeholder="OPEN"/>
          <SimpleCard value={statistics?.closedFeedbackCount || 0} placeholder="CLOSED"/>
          <SimpleCard value={statistics?.tasksCount || 0} placeholder="TASKS"/>
        </div>
      </div>

      <div className="card shadow-card mb-2">
        <div className="card-body">
          <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
            <div className="h5">Feedbacks</div>
          </div>
          <div className="">
            <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
          </div>
        </div>
        <div className="card-footer">
          <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
        </div>
      </div>
    </>
  );
}

export default FeedbackList;