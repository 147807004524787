import { CSSProperties, useContext } from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";
import Context from "../context/Context";


type LoaderProps = {
  animation?: SpinnerProps["animation"];
};

type MiniLoaderProps = {
  show?: boolean;
  animation?: SpinnerProps["animation"];
};

const loaderStyles: CSSProperties = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'RGBA(0, 0, 0, 0.5)',
  zIndex: 2045,
}

const Loader = ({ animation = "border" }: LoaderProps): JSX.Element => {
  const { state: { showLoader: show } } = useContext(Context);

  return (
    <div className={show ? "d-flex justify-content-center align-items-center" : "d-none"} style={loaderStyles}>
      <Spinner className="text-light" animation={animation} />
    </div>
  )
}

export const MiniLoader = ({ show = true, animation = "border" }: MiniLoaderProps): JSX.Element => {
  return (
    <div className={show ? "d-flex justify-content-center align-items-center m-2" : "d-none"}>
      <Spinner animation={animation} style={{height:14, width:14}} />
    </div>
  )
}

export default Loader;
