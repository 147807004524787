import { SyntheticEvent, useRef, useState, useContext } from "react";
import ProjectContext from "../../Project/context/ProjectContext";
import { Alert } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import './Editor.css';


type FeedbackCreateProps = {
  releaseId: number;
  afterSubmit?: () => void;
};

const FeedbackCreate = (props : FeedbackCreateProps): JSX.Element => {
  const [body, setBody] = useState<string>('');
  const editorRef = useRef(null) as any;
  const { getProjectFeedbacks } = useContext(ProjectContext);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);


  const submit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (body.length === 0) {
      setError("Feedback shouldn't be empty");
      setShow(true);
      return;
    }

    API.createFeedback(body, props.releaseId)
    .then(() => {
      setBody('');
      editorRef.current.getInstance().setMarkdown('');
      getProjectFeedbacks();
      props.afterSubmit && props.afterSubmit();
    })
    .catch((err: ApiException) => {
      setError(err.message);
      setShow(true);
    })
  };

  return (
    <form onSubmit={submit}>
      {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
      <div className="mb-3">
        <Editor
          previewStyle="vertical"
          initialEditType="wysiwyg"
          initialValue=""
          hideModeSwitch={true}
          ref={ editorRef }
        />
      </div>
      <button className="btn btn-outline-secondary btn-sm" onClick={() => setBody(editorRef.current.getInstance().getMarkdown())}>Send Feedback</button>
    </form>
  );
}

export default FeedbackCreate;