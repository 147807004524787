import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";

const PasswordUpdate = (): JSX.Element => {
  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] =useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const handleSubmit = async (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      API.updatePassword({
        password,
        password_confirm,
      })
      .then(() => {
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch( (err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = async (e: SyntheticEvent) => {
    e.preventDefault();
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  return (
      <>
      <button className="dropdown-item" onClick={() => setModalShow(true)}>Update Password</button>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" size="sm" placeholder="New password" onChange={ e => setPassword(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control type="password" size="sm" placeholder="Confirm password" onChange={e => setPasswordConfirm(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default PasswordUpdate;