import axios, { AxiosError, AxiosResponse } from "axios";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ContactAPI {
  /**
   * 
   * @param constactId 
   * @returns 
   */
  export const getContact =  async (constactId: number): Promise<any> => {
    const uri = `contacts/${constactId}`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  export const createContact = async (body: any, userId: number|null = null): Promise<any> => {
    const uri = !userId ? `contacts` : `contacts/${userId}`;

    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  /**
   * 
   * @param id 
   * @param body 
   * @returns 
   */
  export const updateContact = async (id: number, body: any): Promise<any> => {
  const uri = `contacts/${id}`;

  return await axios.put(uri, body)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  export const deleteContact = async (id: number): Promise<any> => {
    const uri =  `contacts/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  /**
   * 
   * @returns 
   */
  export const getContacts =  async (): Promise<any> => {
    const uri = `contacts`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }
}
