import { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/types";

type TooltipHelpProps = {
  placement: Placement;
  children: ReactElement<any, string | JSXElementConstructor<any>> | ((props: OverlayTriggerRenderProps) => ReactNode);
  hover: string;
}

const TooltipHelp = (props: TooltipHelpProps): JSX.Element => {
  return (
    <OverlayTrigger
      placement={props.placement}
      delay={{ show: 100, hide: 100 }}
      overlay={(<Tooltip>{props.hover}</Tooltip>)}
    >
      {props.children}
    </OverlayTrigger>
  )
}

export default TooltipHelp;
