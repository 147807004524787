import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type EditButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const EditButton = (props: EditButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-primary"
    iconName="edit"
  />
);

export default EditButton;
