/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { Release } from '../../../api/models/Release.model';
import ReleaseState from './ReleaseState';

class ContextType {
  constructor(
    public state: ReleaseState = {} as ReleaseState,
    public getReleases: (page: number) => Promise<void> = async (_) => {},
    public getProject: () => Promise<void> = async () => {},
    public setReleaseToTry: (release: Release) => void = () => {},
    public hasDummyRelease: () => boolean = () => false,
  ){}
} 

const ReleaseContext = createContext<ContextType>({} as ContextType);

export default ReleaseContext;