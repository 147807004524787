
const avatarStyle = {
  borderRadius: '50%',
}

const Avatar = ({src, alt}: { src: string, alt: string }): JSX.Element => (
  <img src={src} alt={alt} width="32" height="32" className="" style={avatarStyle} />
)

export default Avatar;
