import axios, { AxiosError, AxiosResponse } from "axios";
import { Project, ProjectCreateDto, ProjectPageable, ProjectPagingFilter, ProjectUpdateDto } from "../models/Project.model";
import { Parameters } from "../../types/Generics";
import { User, UserPageable } from "../models/User.model";
import { errorHandler } from "../utils/ErrorHandler";
import { FeedbackPagingFilter, FeedbackPageable } from "../models/Feedback.model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ProjectAPI {

  export const getProject = (projectId: number | undefined): Promise<Project> => {
    const uri = `projects/${projectId}`;

    return axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const createProject = (body: ProjectCreateDto): Promise<Project> => {
    const uri = `projects`;

    return axios.post(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const updateProject = (projectId: number, body: ProjectUpdateDto): Promise<any> => {
    const uri = `projects/${projectId}`;

    return axios.put(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const deleteProject = (projectId: number): Promise<any> => {
    const uri = `projects/${projectId}`;
    return axios.delete(uri)
      .then(() => {
        return "OK"
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getProjects = (filters?: ProjectPagingFilter): Promise<ProjectPageable> => {
    const uri = `projects`;
    return axios.get(uri, { params: filters })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getSharedProjects = (page: number): Promise<ProjectPageable> => {
    const uri = `projects/shared`;
    const params: Parameters = {
      page,
    };

    return axios.get(uri, { params })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getProjectFeedbacks = (projectId?: number, filters?: FeedbackPagingFilter): Promise<FeedbackPageable> => {
    return axios.get(`projects/${projectId}/feedbacks`, { params: filters })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getProjectReleases = (page: number, projectId: number | undefined): Promise<any> => {
    const params = {
      page,
    }
    return axios.get(`projects/${projectId}/releases`, { params })
      .then((res: AxiosResponse) => {
        return res.data
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUsersAssignedToProject = (page: number,  projectId: number): Promise<UserPageable> => {
    const params = {
      page,
    }
    return axios.get(`projects/${projectId}/users`, { params })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const updateUsersAssignedToProject = (projectId: number, userIds: number[]): Promise<Project> => {
    return axios.put(`projects/${projectId}/users`, { users: userIds })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUsersSharedToProject = (page: number,  projectId: number): Promise<UserPageable> => {
    const params = {
      page,
    }
    return axios.get(`projects/${projectId}/guests`, { params })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const updateUsersSharedToProject = (projectId: number, userIds: number[]): Promise<Project> => {
    return axios.put(`projects/${projectId}/guests`, { users: userIds })
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const createProjectRelease = (body: any): Promise<any> => {
    const uri = 'releases';

    return axios.post(uri, body)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
  }

  export const searchProjects = async (title: string): Promise<Project[]> => {
    const uri = `projects/as?title=${title}`;

    return await axios.get(uri)
      .then((res: AxiosResponse)=> {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const searchProjectTeamByEmail = async (projectId: number, email: string): Promise<User[]> => {
    const params = { email }
    return await axios.get(`projects/${projectId}/users`, { params })
      .then((res: AxiosResponse)=> {
        return res.data.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getProjectStates = async (): Promise<any> => {
    const uri = `project-state`;

    return await axios.get(uri)
      .then((res: AxiosResponse)=> {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }
}
