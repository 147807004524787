import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type EmailButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const EmailButton = (props: EmailButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-warning"
    iconName="mail-bulk"
  />
);

export default EmailButton;