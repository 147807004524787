import { useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import RoleContext from './RoleContext';
import RoleReducer from './RoleReducer';
import RoleState from './RoleState';

const RoleProvider = (props: {children: any}): JSX.Element => {

  const [state, dispatch] = useReducer(RoleReducer, {} as RoleState, (_) => new RoleState());

  const getRoles = (page: number): Promise<void> => {
    return API.getRoles(page)
      .then((rolesResponse) => {
        dispatch({ 
          type: "GET_ROLES",
          payload: {
            roles: rolesResponse.data,
            lastPage: rolesResponse.meta.last_page,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  return (
    <RoleContext.Provider
      value = {{
        state,
        getRoles,
      }}
    >
      {props.children}
    </RoleContext.Provider>
  )
}

export default RoleProvider;