import { ReactNode, useContext } from "react";
import Context from "../../context/Context";

type GuardProps = {
  children: ReactNode;
  projectId?: number;
}

const SharedGuard = ({
  children,
  projectId,
}: GuardProps): JSX.Element => {
  const { state: { user }, isGuest } = useContext(Context);
  const sharedProhjectIds = user?.shared_projects?.map(project => project.id);
  let isShared = false;

  if (projectId !== undefined && sharedProhjectIds !== undefined) {
    isShared = sharedProhjectIds.indexOf(projectId) !== -1 ? true : false;
  }

  const flagGuard = !isGuest() && !isShared ;

  return (
    <>
      { flagGuard && children }
    </>
  )
}

export default SharedGuard;
