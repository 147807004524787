import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type CancelButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const CancelButton = (props: CancelButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-danger"
    iconName="window-close"
  />
);

export default CancelButton;