/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { FeedbackPagingFilter } from '../../../api/models/Feedback.model';
import { ProjectPagingFilter } from '../../../api/models/Project.model';
import ProjectState from './ProjectState';

class ContextType {
  constructor(
    public state: ProjectState = {} as ProjectState,
    public getProjectFeedbacks: (filters?: FeedbackPagingFilter) => Promise<void> = async (_) => {},
    public getProjects: (filters?: ProjectPagingFilter) => Promise<void> = async (_) => {},
    public getProject: () => Promise<void> = async () => {}
  ){}
} 

const ProjectContext = createContext<ContextType>({} as ContextType);

export default ProjectContext;