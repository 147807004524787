import axios from 'axios';
import { useContext } from 'react';
import { SyntheticEvent, useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import { Company } from '../../../api/models/Company.model';
import { Role } from "../../../api/models/Role.model";
import EditButton from '../../../components/buttons/EditButton';
import { CompanyIconOption } from '../../../components/select/IconOption';
import Search from '../../../components/select/Search';
import Context from '../../../context/Context';
import UserContext from '../context/UserContext';

type InitialState = {
  email: string,
  roleId: number,
  companyId: number,
  companyName: string,
}

const UserUpdate = (props: { id: number }): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string|null>(null);
  const [roleId, setRoleId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [roles, setRoles] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const { loader } = useContext(Context);
  const { getUsers } = useContext(UserContext);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect( () => {
    (async () => {
      try {
        const [{data: roles}, {data: user}] = await Promise.all([
          axios.get('roles'),
          axios.get(`users/${props.id}`),
        ]);

        setRoles(roles.data);
        setEmail(user.email);
        setRoleId(user.role?.id);
        setCompanyId(user.company?.id);
        setCompanyName(user.company?.name);
        setInitialState({
          email: user.email,
          roleId: user.role?.id,
          companyId: user.company?.id,
          companyName: user.company?.name,
        })
      } catch (err) {
        setShow(true);
        setError('Oops, something went wrong ' + JSON.stringify(err));
      }
    })()
  }, [props.id]);

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true && companyId > 0) {
      loader.show();
      API.updateUser(Number(props.id), {
        email: email,
        password,
        role_id: roleId,
        company_id: companyId,
      })
      .then(() => {
        getUsers(1, null);
        setModalShow(false);
        setValidated(false);
        setShow(false);
        setInitialState({
          email,
          roleId,
          companyId,
          companyName,
        })
        loader.hide();
      })
      .catch((err: ApiException) => {
        loader.hide();
        setError(err.message);
        setShow(true);
      })
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getUsers(1, null);
    setModalShow(false);
    setValidated(false);
    setShow(false);

    setEmail(initialState.email);
    setRoleId(initialState.roleId);
    setCompanyId(initialState.companyId);
    setCompanyName(initialState.companyName);
  }

  const selectCompany = (data: Company) => {
    setCompanyId(Number(data.id));
    setCompanyName(data.name);
  }

  const removeCompany = () => {
    setCompanyId(0);
    setCompanyName('');
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
    <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit user"} />
    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Update user</Modal.Title>
      </Modal.Header>
      {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="text" size="sm" placeholder="E-mail" onChange={ e => setEmail(e.target.value) } value={ email } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a email.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Reset password</Form.Label>
                <Form.Control type="password" size="sm" placeholder="Password" onChange={ e => setPassword(e.target.value) } />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Role</Form.Label>
                  <select className="form-control form-control-sm" onChange={ e => setRoleId(Number(e.target.value)) } value={ roleId } required>
                    <option key={0} className="d-none"></option>
                    {roles.map((role:Role) => {
                      return (
                        <option key={role.id} value={role.id}> {role.name} </option>
                      )
                    })}
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a role.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom04" className="mb-3">
                <Form.Label>Company</Form.Label>
                <Search 
                  className={["form-control form-control-sm", afterValidationClass(companyId > 0)].join(' ')}
                  search={API.searchCompanies}
                  item={CompanyIconOption}
                  onItemClick={selectCompany}
                  onRemoveContent={removeCompany}
                  value={companyName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a password confirmation.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UserUpdate