import { ReactNode, useContext, useEffect } from "react";
import Context from "../context/Context";
import UserProvider from "../pages/User/context/UserProvider";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";

const Wrapper = (props: { children: ReactNode }): JSX.Element => {
  const { state: { user }, isGuest } = useContext(Context);

  if (!user || JSON.stringify(user) === '{}') {
    return <></>
  }

  return (
    <UserProvider userId={user.id}>
      <div className="container-fluid">
        <div className="row">
          { !isGuest() &&
            <Sidebar permissions={user.role?.permissions} user={user}/>
          }
          <Header user={user}/>
        </div>
        <main className={isGuest() ? "container" : "col-md-9 ms-sm-auto col-lg-10 px-md-4"}>
          {props.children}
        </main>
      </div>
    </UserProvider>
  );
}

export default Wrapper