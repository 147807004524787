import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiException } from "../errors/ApiException";
import { Role } from "../models/Role.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RoleAPI {
  /**
   * 
   * @param id company ID
   * @returns 
   */
  export const getRole =  async (id: number): Promise<Role> => {
    const uri = `roles/${id}`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  export const createRole = async (body: any): Promise<any> => {
    const uri = `roles`;

    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
				errorHandler(err);
			});
  }

  /**
   * 
   * @param id 
   * @param params 
   * @returns 
   */
  export const updateRole = async (id: number, body: any): Promise<any> => {
    const uri = `roles/${id}`;

    return await axios.put(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
    }

  /**
   * 
   * @param id 
   * @returns 
   */
  export const deleteRole = async (id: number): Promise<any> => {
    const uri =  `roles/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  /**
   * @returns an object that contains array with roles and a number with total pages
   */
  export const getRoles =  async (page = 1): Promise<any> => {
    const uri = `roles?${page}`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }
}
