import axios from "axios";
import { useContext, useEffect } from "react";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Profile } from "../../../api/models/Profile.model";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import UserContext from "../context/UserContext";
import BaseButton from "../../../components/buttons/BaseButton";
import { Contact } from "../../../api/models/Contact.model";

type InitialState = {
  firstName : string,
  lastName : string,
  avatar : string,
  about : string,
  occupation : string,
  skills: string,
  github: string,
  twitter: string,
  linkedin: string,
  instagram: string,
  phone: string,
  street: string,
  number: string,
  email: string,
}

type UserProfileUpdateProps = {
  profile: Profile;
  contact?: Contact;
  userId: number;
};

const UserProfileUpdate = (props: UserProfileUpdateProps): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [about, setAbout] = useState('');
  const [occupation, setOccupation] = useState('');
  const [avatar, setAvatar ] = useState('');
  const [skills, setSkills ] = useState('');
  const [github, setGithub] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');

  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');

  const { getUsers } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setFirstName(props.profile.first_name);
    setLastName(props.profile.last_name);
    setAbout(props.profile.about);
    setOccupation(props.profile.occupation);
    setAvatar(props.profile.avatar);
    setSkills(props.profile.skills);
    setGithub(props.profile.github);
    setTwitter(props.profile.twitter);
    setLinkedin(props.profile.linkedin);
    setInstagram(props.profile.instagram);
    setPhone(props.contact?.phone || '');
    setStreet(props.contact?.street || '');
    setNumber(props.contact?.number || '');
    setEmail(props.contact?.email || '');
    setInitialState({
      firstName: props.profile.first_name,
      lastName: props.profile.last_name,
      avatar: props.profile.about,
      about: props.profile.occupation,
      occupation: props.profile.avatar,
      skills: props.profile.skills,
      github: props.profile.github,
      twitter: props.profile.twitter,
      linkedin: props.profile.linkedin,
      instagram: props.profile.instagram,
      phone: props.contact?.phone || '',
      street: props.contact?.street || '',
      number: props.contact?.number || '',
      email: props.contact?.email || '',
    });
  }, [props.profile, props.contact]);


  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setAvatar(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      Promise.all([
        API.updateUserProfile(props.profile.id, {
          first_name: firstName,
          last_name: lastName,
          avatar,
          about,
          occupation,
          skills,
          github,
          twitter,
          linkedin,
          instagram,
        }),
        !props.contact
          ? API.createContact({
            phone,
            street,
            number,
            email,
          }, props.userId)
          : API.updateContact(props.contact.id, {
            phone,
            street,
            number,
            email,
          })
      ])
      .then(() => {
        getUsers(1, null);
        setModalShow(false);
        setValidated(false);
        setShow(false);
        setInitialState({
          firstName,
          lastName,
          avatar,
          about,
          occupation,
          skills,
          github,
          twitter,
          linkedin,
          instagram,
          phone,
          email,
          street,
          number,
        });
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    setModalShow(false);
    setValidated(false);
    setShow(false);
    setFirstName(initialState.firstName);
    setLastName(initialState.lastName);
    setAbout(initialState.avatar);
    setOccupation(initialState.about);
    setAvatar(initialState.occupation);
    setAvatar(initialState.skills);
    setGithub(initialState.github);
    setTwitter(initialState.twitter);
    setLinkedin(initialState.linkedin);
    setInstagram(initialState.instagram);
    setPhone(initialState.phone);
    setStreet(initialState.street);
    setNumber(initialState.number);
    setEmail(initialState.email);
  }

  return (
    <>
      <BaseButton 
        onClick={() => setModalShow(true)}
        className="btn-sm btn-primary"
        iconName="id-card"
        placement={"top"}
        hover={"Edit profile"}
      />

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update profile</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text"  size="sm" placeholder="First Name" onChange={e => setFirstName(e.target.value)} value={firstName} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text"  size="sm" placeholder="Last Name" onChange={e => setLastName(e.target.value)} value={lastName} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid last name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-3">
              <Form.Label>Avatar</Form.Label>
              <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )}/>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid image.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              <Form.Label>About</Form.Label>
              <Form.Control as="textarea"  size="sm" placeholder="Description"  onChange={e => setAbout(e.target.value)} value={about} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid company description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05" className="mb-3">
              <Form.Label>Occupation</Form.Label>
              <Form.Control type="text"  size="sm" placeholder="Occupation" onChange={e => setOccupation(e.target.value)} value={occupation} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid occupation.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom06" className="mb-3">
              <Form.Label>Skills</Form.Label>
              <Form.Control as="textarea"  size="sm" placeholder="C++, Unreal, PixiJS"  onChange={e => setSkills(e.target.value)} value={skills} required />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid skill list.</Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>GitHub</Form.Label>
                <Form.Control type="link" size="sm" placeholder="https://github.com/" onChange={e => setGithub(e.target.value)} value={github} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid github link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Twitter</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://twitter.com/" onChange={e => setTwitter(e.target.value)} value={twitter} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid twitter link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Linkedin</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://www.linkedin.com/" onChange={e => setLinkedin(e.target.value)} value={linkedin} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid linkedin link.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05" as={Col} md="3">
                <Form.Label>Instagram</Form.Label>
                <Form.Control type="text" size="sm" placeholder="https://www.instagram.com/" onChange={e => setInstagram(e.target.value)} value={instagram} />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid instagram link.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <hr />
            <div className="h5 mb-3">Contact info</div>
            <Row>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Phone number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Phone number"  value={phone} onChange={ e => setPhone(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Street name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street name" value={street} onChange={ e => setStreet(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationCustom03">
                <Form.Label>Street number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street number" value={number} onChange={ e => setNumber(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
              <Form.Label>Email</Form.Label>
                <Form.Control type="email"  size="sm" placeholder="Email" value={email} onChange={ e => setEmail(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UserProfileUpdate