/* eslint-disable no-case-declarations */
import RoleState from "./RoleState";

const RoleReducer = (state: RoleState, action: {payload: any, type: string}): RoleState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_ROLES":
      const { roles, lastPage } = payload;
      return {
        ...state,
        roles,
        lastPage
      };

    default:
      return state;
  }
}

export default RoleReducer;
