import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Genre } from "../../../api/models/Genre.model";
import ProjectContext from "../context/ProjectContext";
import { API } from "../../../api/api";
import AddButton from "../../../components/buttons/AddButton";
import { User } from "../../../api/models/User.model";
import axios from "axios";
import Search from "../../../components/select/Search";
import { CompanyIconOption, UserIconOption } from "../../../components/select/IconOption";
import { Company } from "../../../api/models/Company.model";
import { ProjectState } from "../../../api/models/ProjectState.model";
import { ProjectDocument } from "../../../api/models/ProjectDocument.model";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import SimplePill from "../../../components/pills/SimplePill";
import { ApiException } from "../../../api/errors/ApiException";
import Context from "../../../context/Context";

const ProjectCreate = (): JSX.Element => {
  const [genres, setGenres] = useState<Genre[]>([]);
  const [states, setStates] = useState<ProjectState[]>([]);

  const [title, setTitle] = useState<string|null>(null);
  const [description, setDescription] = useState<string|null>(null);
  const [ipHolder, setIpHolder] = useState<string|null>(null);
  const [image, setImage] = useState<string|null>(null);
  const [projectDocuments, setProjectDocuments] = useState<ProjectDocument[]>([]);
  const [genre, setGenre] = useState<number>();
  const [state, setState] = useState<number>();

  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  
  const [responsibleId, setResponsibleId] = useState(0);
  const [responsibleEmail, setResponsibleEmail] = useState('');

  const { getProjects } = useContext(ProjectContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { loader } = useContext(Context);

  useEffect(() => {
    (async () => {
      const [genres, states] = await Promise.all([
        API.getGenres(),
        API.getProjectStates(),
      ]);
      setGenres(genres.data);
      setStates(states);
    })();
  }, []);

  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setImage(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const documentUpload = async (files: FileList | null) => {
    if (files === null) return;
    try {
      const formData = new FormData();
      formData.append("document", files[0]);
      const path = `${process.env.REACT_APP_BUILD_URL_BASE}/api/documents`;
      const { data: asset } = await axios.post(path, formData);
      setProjectDocuments([...projectDocuments, {
        url: `${path}/download/${asset.filename}`,
        original_name: files[0].name,
      }]);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);
    if (form.checkValidity() === true && responsibleId > 0 && companyId > 0) {
      loader.show();
      setFetching(true);
      API.createProject({
        title,
        description,
        image,
        genre_id: genre,
        ip_holder: ipHolder,
        responsible_id: responsibleId,
        company_id: companyId,
        state_id: state,
        project_documents: projectDocuments,
      })
      .then(()=> {
        setModalShow(false);
        setValidated(false);
        setShow(false);
        getProjects();
        loader.hide();
      })
      .catch((err: ApiException) => {
        loader.hide();
        setError(err.message);
        setShow(true);
      })
      .finally(() => {
        setFetching(false);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getProjects();
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  const selectCompany = (data: Company) => {
    setCompanyId(Number(data.id));
    setCompanyName(data.name);
  }

  const selectUser = (data: User) => {
    setResponsibleId(Number(data.id));
    setResponsibleEmail(data.email);
  }

  const removeCompany = () => {
    setCompanyId(0);
    setCompanyName('');
  }

  const removeUser = () => {
    setResponsibleId(0);
    setResponsibleEmail('');
  }

  const getIconDocument = (origina_name: string): IconName => {
    if (origina_name.match(/\.pdf/) != null) {
      return "file-pdf";
    }

    if (origina_name.match(/\.(doc|docx|odt)^/) != null) {
      return "file-word";
    }

    return "file";
  }

  const unselectDocument = (projectDocument: ProjectDocument) => {
    setProjectDocuments(projectDocuments.filter((it) => it.url != projectDocument.url));
  }

  const afterValidationClass = (isValid: boolean) => validated ? (isValid ? "is-valid" : "is-invalid") : "";

  return (
    <>
      {/* <button className="btn btn-sm btn-success" onClick={() => setModalShow(true)}>Add</button> */}
      <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add Project" />
      
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-hard-validation">
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Project name" onChange={e => setTitle(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid title name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" size="sm" type="text" placeholder="Project description" onChange={e => setDescription(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid description.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom04" className="mb-3">
                <Form.Label>IP Holder</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Intellectual property holder" onChange={e => setIpHolder(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid ip holder.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )}/>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid image.</Form.Control.Feedback>
              </Form.Group>
            </Col>

              <Row>
                <Form.Group as={Col} md="6" sm="12" controlId="validationCustom04" className="mb-3">
                  <Form.Label>Genre</Form.Label>
                    <select className="form-control form-select-sm" onChange={e => setGenre(Number(e.target.value))} required>
                      <option key={0} className="d-none"></option>
                        {genres.map((genre: Genre) => (
                          <option key={genre.id} value={genre.id}>{genre.name}</option>
                        ))}
                    </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please select a genre.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom04" className="mb-3 ">
                <Form.Label>Stage</Form.Label>
                  <select className="form-control form-select-sm" onChange={e => setState(Number(e.target.value))} required>
                    <option key={0} className="d-none"></option>
                      {states.map((state: ProjectState) => (
                        <option key={state.id} value={state.id}>{state.name}</option>
                      ))}
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please select a stage.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom05" className="mb-3" required>
                <Form.Label>Company</Form.Label>
                <Search
                    className={["form-control", afterValidationClass(companyId > 0)].join(' ')}
                    search={API.searchCompanies}
                    item={CompanyIconOption}
                    onItemClick={selectCompany}
                    onRemoveContent={removeCompany}
                    value={companyName}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please select a company.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" sm="12" controlId="validationCustom06" className="mb-3" required>
                <Form.Label>Responsible</Form.Label>
                <Search
                    className={["form-control", afterValidationClass(responsibleId > 0)].join(' ')}
                    search={API.searchUserByEmail}
                    item={UserIconOption}
                    onItemClick={selectUser}
                    onRemoveContent={removeUser}
                    value={responsibleEmail}
                  />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please select a responsible.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Documents</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => documentUpload( (e.target as HTMLInputElement).files )}/>
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex flex-wrap gap-2 mt-3">
                {projectDocuments.map((projectDocument:ProjectDocument) => 
                  <SimplePill
                    key={projectDocument.original_name}
                    iconName={getIconDocument(projectDocument.original_name)}
                    label={projectDocument.original_name}
                    checkable={true}
                    onCheck={() => unselectDocument(projectDocument)}
                  />
                )}
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel} disabled={fetching}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary" disabled={fetching}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ProjectCreate;