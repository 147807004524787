/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import RoleState from './RoleState';

class ContextType {
  constructor(
    public state: RoleState = {} as RoleState,
    public getRoles: (page: number) => Promise<void> = async (_) => {},
  ){}
} 

const RoleContext = createContext<ContextType>({} as ContextType);

export default RoleContext;