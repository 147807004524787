/* eslint-disable react/jsx-key */
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { Contact } from "../../../api/models/Contact.model";
import ConfirmAlert from "../../../components/alerts/ConfirmAlert";
import DeleteButton from "../../../components/buttons/DeleteButton";
import EmailButton from "../../../components/buttons/EmailButton";
import ContactUpdate from "../../Contact/ContactUpdate";
import UserContext from "../context/UserContext";

const UserContacts = (): JSX.Element => {
  const { state: { contacts }, getUserContacts } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    getUserContacts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const del = (id: number) => {
    setShowConfirmDelete(false);
    API.deleteContact(id)
    .then(() => {
      getUserContacts();
    })
    .catch(() => setShow(true));
  }

  const delConfirm = (id: number) => {
    setContactToDelete(id);
    setShowConfirmDelete(true);
  }

  const sendEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Not this time</Modal.Title>
        </Modal.Header>
        <Modal.Body>It seems that the role is assigned to a user, we cannot delete it.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(contactToDelete)} onCancel={() => setShowConfirmDelete(false)} />

      <div className="">
        <table className="table table-bordered table-striped table-sm text-center">
          <thead>
            <tr>
              <th scope="col">Phone</th>
              <th scope="col">Street Name</th>
              <th scope="col">Street Number</th>
              <th scope="col">Email</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts?.map((contact: Contact) => (
              <tr key={contact.id}>
                <td>{contact.phone}</td>
                <td>{contact.street}</td>
                <td>{contact.number}</td>
                <td>{contact.email}</td>
                <td>
                  <div className="d-flex mr-2 gap-2 justify-content-center">
                    <ContactUpdate contact={contact} />
                    <DeleteButton onClick={() => delConfirm(contact.id)} placement="top" hover="Delete contact" />
                    <EmailButton onClick={() => sendEmail(contact.email)} placement="top" hover="Send email" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UserContacts;
