import ProjectProvider from "./context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import ColumnFilter from "../../components/table/ColumnFilter";
import SimpleCard from "../../components/card/SimpleCard";
import TableFiltering from "../../components/table/TableFiltering";
import ProjectUpdate from "./components/ProjectUpdate";
import { useHistory } from "react-router-dom";
import ProjectContext from "./context/ProjectContext";
import ProjectCreate from "./components/ProjectCreate";
import { API } from "../../api/api";
import { Badge, Button, Modal } from "react-bootstrap";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import Guard from "../../components/guards/Guard";
import DeleteButton from "../../components/buttons/DeleteButton";
import ViewButton from "../../components/buttons/ViewButton";
import { ProjectsStatistics } from "../../api/models/Statistics.model";
import { Project, ProjectSortCriteriaValues } from "../../api/models/Project.model";
import OptionFilter from "../../components/table/OptionFilter";
import { ProjectState } from "../../api/models/ProjectState.model";
import moment from "moment";
import { SortCriteriaOptions } from "../../types/Generics";
import ColumnSorter from "../../components/table/ColumnSorter";
import Paginator from "../../components/Paginator";

const ProjectList = (): JSX.Element => {
  return (
    <ProjectProvider>
      <ProjectTable/>
    </ProjectProvider>
  )
}

const imageIconStyle = {
  width: 42,
  height: 42,
}

const ProjectTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [statistics, setStatistics] = useState<ProjectsStatistics>();
  const [projectStates, setProjectStates] = useState<ProjectState[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  
  const [title, setTitle] = useState<string|null>(null);
  const [genre, setGenre] = useState<string|null>(null); 
  const [state, setState] = useState<number|null>(null);
  const [company, setCompany] = useState<string|null>(null);
  const [responsible, setResponsible] = useState<string|null>(null); 
  
  const { state: {projects, lastPage}, getProjects } = useContext(ProjectContext);

  const [show, setShow] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [createdAtSorter, setCreatedAtSorter] = useState<SortCriteriaOptions>('DESC');
  const [sortersOrder, setSortersOrder] = useState<ProjectSortCriteriaValues[]>(['created_at']);

  const history = useHistory();

  useEffect(() => {
    getProjects({
      page,
      title,
      genre,
      state,
      company,
      responsible,
      created_at_sort: createdAtSorter,
      sort_criteria: sortersOrder,
    }).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, title, genre, state, company, responsible, createdAtSorter, sortersOrder]);

  useEffect(() => {
    (async () => {
      const [states, statistics] = await Promise.all([
        API.getProjectStates(),
        API.getProjectsStatistics(),
      ]);
      setProjectStates(states);
      setStatistics(statistics);
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, title]);

  const switchCreatedAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'created_at'));
    } else if (sortersOrder.indexOf('created_at') < 0) {
      sortersOrder.push('created_at');
      setSortersOrder(sortersOrder);
    }
    setCreatedAtSorter(sortCriteria);
  }

  const del = (id: number) => {
    setShowConfirmDelete(false);
    API.deleteProject(id)
    .then(() => {
      getProjects();
    })
    .catch(() => setShow(true));
  };

  const delConfirm = (id: number) => {
    setProjectToDelete(id);
    setShowConfirmDelete(true);
  }

  const view = (id: number) => {
    history.push(`/projects/${id}`);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Image',
      Footer: 'image',
      accessor: 'image',
      maxWidth: 70,
      minWidth: 70,
      Cell: (props : { value: string } ) => (
        <img
          src={props.value}
          className='cell-image'
          style= {imageIconStyle}
          alt='thumbnail'
        />
      ),
      canFilter: false,
    },
    { 
      Header: 'Title',
      Footer: 'title',
      accessor: 'title',
      canFilter: true,
      Filter: ColumnFilter(title, setTitle),
    },
    { 
      Header: 'Genre',
      Footer: 'genre',
      accessor: 'genre',
      canFilter: true,
      Filter: ColumnFilter(genre, setGenre),
    },
    {
      Header: 'State',
      Footer: 'state',
      accessor: 'state',
      canFilter: true,
      Filter: OptionFilter({
        options: projectStates.map((projectState) => ({id: projectState.id, value: projectState.name})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setState(option.id),
      }),
    },
    { 
      Header: 'Company',
      Footer: 'company',
      accessor: 'company',
      canFilter: true,
      Filter: ColumnFilter(company, setCompany),
    },
    { 
      Header: 'Responsible',
      Footer: 'responsible',
      accessor: 'responsible',
      canFilter: true,
      Filter: ColumnFilter(responsible, setResponsible),
    },
    { 
      Header: 'Created At',
      Footer: 'created_at',
      accessor: 'created_at',
      canFilter: false,
      canSort: true,
      Sorter: ColumnSorter({ setSortCriteria: switchCreatedAtSort })
    },
    { 
      Header: 'Actions',
      Footer: '',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = projects.map(project => ({
    ...project,
    id: project.id,
    image: Project.getImage(project),
    title: project.title,
    genre: project.genre.name,
    state: project.state.name,
    company: project.company.name,
    responsible: project.responsible.email,
    releases: "0",
    feedbacks: "0",
    created_at: moment(project.created_at).format('MM/DD/YYYY'),
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <ViewButton onClick={() => view(project.id)} placement="top" hover="View project" />
        <Guard entity="PROJECTS" level="EDIT">
          <ProjectUpdate id={project.id} />
        </Guard>
        {/* <Guard entity="PROJECTS" level="FULL">
          <DeleteButton onClick={() => delConfirm(project.id)} placement="top" hover="Delete project" />
        </Guard> */}
      </div>
    )
  }));

  return (
    <>
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Not this time</Modal.Title>
      </Modal.Header>
      <Modal.Body>It seems that the project already has versions, we cannot delete it.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>

    <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(projectToDelete)} onCancel={() => setShowConfirmDelete(false)} />

    <div className="card shadow-card mb-2">
      <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
        <SimpleCard value={statistics?.projectCount || 0} placeholder="PROJECTS"/>
        <SimpleCard value={statistics?.releaseCount || 0} placeholder="RELEASES"/>
        <SimpleCard value={statistics?.releasesActiveCount || 0} placeholder="PRODUCTION"/>
        <SimpleCard value={statistics?.feedbackCount || 0} placeholder="FEEDBACKS"/>
        <SimpleCard value={statistics?.developersCount || 0} placeholder="DEVELOPERS"/>
      </div>
    </div>

    <div className="card shadow-card mb-2">
      <div className="card-body">
        <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
          <div className="h5">Projects</div>

          <Guard entity="PROJECTS" level="CREATE">
            <ProjectCreate />
          </Guard>

        </div>
        <div className="">
          <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
        </div>
      </div>
      <div className="card-footer">
        <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
      </div>
    </div>
    </>
  );
}

export default ProjectList;