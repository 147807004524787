import { Feedback, FeedbackPagingFilter } from "../../../api/models/Feedback.model";
import { Project, ProjectPagingFilter } from "../../../api/models/Project.model";
import BaseState from "../../../context/base/BaseState";

class ProjectState extends BaseState {
  constructor(
    public feedbacks: Feedback[] = [],
    public defaultFeedbackFilters: FeedbackPagingFilter = {} as FeedbackPagingFilter,
    public projects: Project[] = [],
    public defaultProjectFilters: ProjectPagingFilter = {} as ProjectPagingFilter,
    public project: Project =  {} as Project,
    public page: number = 1,
    public lastPage: number = 1,
  ){
    super()
  }
}

export default ProjectState;