import { SyntheticEvent, useEffect, useState, createRef } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { API } from "../../api/api";
import { Device } from "../../api/models/Device.model";
import NewTabButton from "../buttons/NewTabButton";
import RotateButton from "../buttons/RotateButton";
import "./DeviceToolbar.css";

type Resolution = {
  width: number,
  height: number,
}

type DeviceToolbarProps = {
  setResolution: (resolution: Resolution) => void;
  defaultWidth: number;
  defaultHeight: number;
};

const DeviceToolbar = (props: DeviceToolbarProps): JSX.Element => {
  const [capture, setCapture] =useState('');
  const [devices, setDevices] = useState<Device[]>([]);

  const [device, setDevice] = useState<Device>({
    id: 0,
    name: 'Default',
    width: props.defaultWidth,
    height: props.defaultHeight,
    isPortrait: props.defaultWidth > props.defaultHeight,
  });

  useEffect(() => {
    if(props.defaultWidth !== device.width || props.defaultHeight !== device.height) {
      setDevice({
        id: 0,
        name: 'Default',
        width: props.defaultWidth,
        height: props.defaultHeight,
        isPortrait: props.defaultWidth > props.defaultHeight,
      })
    }
  }, [props.defaultHeight, props.defaultWidth])

  useEffect(() => {
    (async () => {
      const device = await API.getDevices()
      setDevices(device.data);
    })()
  }, []);

  function updateDimensions(currentValue: Device) {
    setDevice({
      ...device,
      name: currentValue.name,
      width: currentValue.width,
      height: currentValue.height,
    });
    props.setResolution({
      width: currentValue.width,
      height: currentValue.height,
    });
  }

  const updateHeight = (e: SyntheticEvent) => {
    setDevice({
      ...device,
      name: 'Custom',
      height: (e.target as HTMLFormElement).value,
    });
    props.setResolution({
      width: device.width,
      height: (e.target as HTMLFormElement).value,
    });
  }

  const updateWidth = (e: SyntheticEvent) => {
    e.preventDefault();
    setDevice({
      ...device,
      name: 'Custom',
      width: (e.target as HTMLFormElement).value,
    });
    props.setResolution({
      width: (e.target as HTMLFormElement).value,
      height: device.height,
    });
  }

  const rotateScreen = () => {
    setDevice({
      ...device,
      width: device.height,
      height: device.width,
    });
    props.setResolution({
      width: device.height,
      height: device.width,
    });
  }

  const fullScreen = () => {
    const iFrameRelease = document.getElementById("game");
    iFrameRelease?.requestFullscreen();
  }

  return (
    <div className="">
      <Row xs="auto" className="row-device justify-content-center">
        <Col>
          <Dropdown className="form-select form-select-sm">
            <Dropdown.Toggle variant="" size="sm" bsPrefix=" " className="d-block w-100 p-0">{device.name}</Dropdown.Toggle>
              <Dropdown.Menu>
                {devices.map((device: Device) => (
                  <Dropdown.Item key={device.id} onClick={() => updateDimensions(device)}>{device.name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="col-2">
          <input className="form-control form-control-sm" placeholder="Width" type="number" value={device.width} onChange={e => updateWidth(e)} />
        </Col>
        <Col className="col-2">
          <input className="form-control form-control-sm" placeholder="Height" type="number" value={device.height} onChange={e => updateHeight(e)} />
        </Col>
        <Col>
          <RotateButton onClick={() => rotateScreen() } placement={"top"} hover={"Rotate"} />
        </Col>
        <Col>
          <NewTabButton onClick={() => fullScreen() } placement={"top"} hover={"Fullscreen"} />
        </Col>
      </Row>
    </div>
  )
}

export default DeviceToolbar;