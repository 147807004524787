/* eslint-disable @typescript-eslint/no-unused-vars */
import { useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import { FeedbackPagingFilter, FeedbackPageable } from '../../../api/models/Feedback.model';
import { Project, ProjectPagingFilter, ProjectPageable } from '../../../api/models/Project.model';
import ProjectContext from './ProjectContext';
import ProjectReducer from './ProjectReducer';
import ProjectState from './ProjectState';

const ProjectProvider = (props: {children: any, projectId?: number}): JSX.Element => {

  const [state, dispatch] = useReducer(ProjectReducer, {} as ProjectState, (_) => new ProjectState());

  const getProject = (): Promise<void> => {
    return API.getProject(props.projectId)
      .then((project: Project) => {
        dispatch({
          type: "GET_PROJECT",
          payload: {
            project: project,
          }
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const getProjectFeedbacks = (filters?: FeedbackPagingFilter): Promise<void> => {
    if (!filters) {
      filters = state.defaultFeedbackFilters;
    }
    return API.getProjectFeedbacks(props.projectId, filters)
      .then((projectFeedbacks: FeedbackPageable) => {
        dispatch({ 
          type: "GET_PROJECT_FEEDBACKS",
          payload: {
            feedbacks: projectFeedbacks.data,
            lastPage: projectFeedbacks.meta.last_page,
            filters,
          }
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const getProjects = (filters?: ProjectPagingFilter): Promise<void> => {
    if (!filters) {
      filters = state.defaultProjectFilters;
      if (state.projects.length <= 1) {
        filters.page = Math.max(1, filters.page-1);
      }
    }
    return API.getProjects(filters)
      .then((project: ProjectPageable) => {
        dispatch({
          type: "GET_PROJECTS",
          payload: {
            projects: project.data,
            lastPage: project.meta.last_page,
            filters,
          }
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  return (
    <ProjectContext.Provider
      value = {{
        state,
        getProject,
        getProjectFeedbacks,
        getProjects
      }}
    >
      { props.children }
    </ProjectContext.Provider>
  )
}

export default ProjectProvider;