/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react"
import GenreContext from "./context/GenreContext";
import GenreCreate from "./components/GenreCreate";
import GenreUpdate from "./components/GenreUpdate";
import ColumnFilter from "../../components/table/ColumnFilter";
import TableFiltering from "../../components/table/TableFiltering";
import GenreProvider from "./context/GenreProvider";
import { API } from "../../api/api";
import { Button, Modal } from "react-bootstrap";
import ConfirmAlert from "../../components/alerts/ConfirmAlert";
import { Redirect } from "react-router-dom";
import DeleteButton from "../../components/buttons/DeleteButton";
import { GenreStatistics } from "../../api/models/Statistics.model";
import Paginator from "../../components/Paginator";


const GenreList = (): JSX.Element => {
  return (
    <>
      <GenreProvider>
        <GenreTable />
      </GenreProvider>
    </>
  )
}

const GenreTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [name, setName] = useState<string|null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [genreToDelete, setGenreToDelete] = useState<number>(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [statistics, setStatistics] = useState<GenreStatistics>();
  const { state: { genres, lastPage, errorCode }, getGenres } = useContext(GenreContext);

  useEffect(() => {
    getGenres(page, name ?? '').finally(() => setShowLoading(false))
  }, [page, name]);

  useEffect(() => {
    (async () => {
      const statistics = await API.getGenreStatistics();
      setStatistics(statistics);
    })();
  }, [])

  const del = (id: number) => {
    setShowConfirmDelete(false);
    API.deleteGenre(id)
      .then(() => {
        getGenres(1, name ?? '');
      })
      .catch(() => {
        setShow(true);
      });
  }

  const delConfirm = (id: number) => {
    setGenreToDelete(id);
    setShowConfirmDelete(true);
  }

  if (errorCode !== 200) {
    return <Redirect to={'/'} />
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Name',
      Footer: 'name',
      accessor: 'name',
      canFilter: true,
      Filter: ColumnFilter(name, setName),
    },
    {
      Header: 'Action',
      Footer: '',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = genres.map(genre => ({
    ...genre,
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <GenreUpdate id={genre.id} />
        <DeleteButton onClick={() => delConfirm(genre.id)} placement="top" hover="Delete genre" />
      </div>
    )
  }));

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Not this time</Modal.Title>
        </Modal.Header>
        <Modal.Body>It seems that the category is in a project, we cannot delete it.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmAlert show={showConfirmDelete} title={"Are you sure?"} message={"You won't be able to revert this!"} onConfirm={() => del(genreToDelete)} onCancel={() => setShowConfirmDelete(false)} />

      <div className="card shadow-card mb-2">
        {/* <div className="card-header d-flex align-items-center justify-content-center">
          <SimpleCard value={statistics?.genresCount || 0} placeholder="GENRES"/>
          {statistics?.projectCountByGenre?.map((item: ProjectCountByGenre, key: number) => (
            <SimpleCard key={key} value={item.count} placeholder={item.name.toUpperCase()}/>
          ))}
        </div> */}
        <div className="card-body">
          <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
            <div className="h5">Genres</div>
            <GenreCreate />
          </div>
          <div className="">
            <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
          </div>
        </div>
        <div className="card-footer">
          <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
        </div>
      </div>
    </>
  )
}

export default GenreList;