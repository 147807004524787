import { useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import { FeedbackPagingFilter } from '../../../api/models/Feedback.model';
import { TaskPagingFilter } from '../../../api/models/Task.model';
import FeedbackContext from './FeedbackContext';
import FeedbackReducer from './FeedbackReducer';
import FeedbackState from './FeedbackState';

const FeedbackProvider = (props: any): JSX.Element => {
  
  const [state, dispatch] = useReducer(FeedbackReducer, {} as FeedbackState, (_) => new FeedbackState());

  const getFeedbackTasks = (filters?: TaskPagingFilter): Promise<void> => {
    if (!filters) {
      filters = state.defaultTaskFilters;
    }
    return API.getFeedbackTasks(props.feedbackId, filters)
      .then((feedbackTasksResponse) => {
        dispatch({ 
          type: "GET_TASKS",
          payload: {
            tasks: feedbackTasksResponse.data,
            lastPage: feedbackTasksResponse.meta.last_page,
            filters,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  const getFeedbacks = (filters?: FeedbackPagingFilter): Promise<void> => {
    if (!filters) {
      filters = state.defaultFilters;
    }
    return API.getFeedbacks(filters)
      .then((feedbackResponse) => {
        dispatch({ 
          type: "GET_FEEDBACKS",
          payload: {
            feedbacks: feedbackResponse.data,
            lastPage: feedbackResponse.meta.last_page,
            filters,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  const getFeedback = (): Promise<void> => {
    return API.getFeedback(props.feedbackId)
      .then((feedbackResponse) => {
        dispatch({ 
          type: "GET_FEEDBACK",
          payload: feedbackResponse
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  const startEdit = () => {
    dispatch({
      type: "EDIT_FEEDBACK",
      payload: true,
    })
  }

  const closeEdit = () => {
    dispatch({
      type: "EDIT_FEEDBACK",
      payload: false,
    })
  }


  return (
    <FeedbackContext.Provider
      value = {{
        state,
        getFeedbackTasks,
        getFeedbacks,
        getFeedback,
        startEdit,
        closeEdit
      }}
    >
      {props.children}
    </FeedbackContext.Provider>
  )
}

export default FeedbackProvider;