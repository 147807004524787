import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as dotenv from 'dotenv';
import Provider from './context/Provider';

dotenv.config();

axios.defaults.baseURL = process.env.REACT_APP_API_URL_BASE;
axios.defaults.headers['x-auth-token'] = localStorage.getItem('token');

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
