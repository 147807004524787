/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import FeedbackCreate from "../Feedback/components/FeedbackCreate";
import ProjectFeedbacks from "./components/ProjectFeedbacks";
import { Tab, Tabs } from "react-bootstrap";
import ProjectProvider from "./context/ProjectProvider";
import ReleaseCreate from "../Release/components/ReleaseCreate";
import ReleaseProvider from "../Release/context/ReleaseProvider";
import DisplayRelease from "./components/DisplayRelease";
import ReleaseContext from "../Release/context/ReleaseContext";
import Context from "../../context/Context";
import ProjectUsers from "./components/ProjectUsers";
import ReleaseList from "../Release/components/ReleaseList";
import Guard from "../../components/guards/Guard";
import DeviceToolbar from "../../components/deviceToolbar/DeviceToolbar";
import { Project } from "../../api/models/Project.model";
import BaseButton from "../../components/buttons/BaseButton";
import ProjectDetails from "./components/ProjectDetails";
import { Release } from "../../api/models/Release.model";
import ProjectGuests from "./components/ProjectGuests";
import { MiniLoader } from "../../components/Loader";
import SharedGuard from "../../components/guards/SharedGuard";

const imageIconStyle = {
  width: 42,
  height: 42,
  borderRadius: '4px',
}

const ProjectView = ({ match: { params: { id } } }: any): JSX.Element => (
  <ReleaseProvider projectId={id}>
    <ProjectViewBody id={id} />
  </ReleaseProvider>
)

const ProjectViewBody = ({ id }: { id: number }) => {
  //TODO add Resolution option to project creation
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [releaseToUse, setReleaseToUse] = useState<Release>({} as Release);
  const [resolution, setResolution] = useState({ width: 1040, height: 800 });
  const [hasOneRelease, setHasOneRelease] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState("feedback_list");

  const { state: { currentRelease, project, releaseForClients, releaseForDevs, releaseToTry }, getProject, hasDummyRelease } = useContext(ReleaseContext);
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    const releaseToUse = (() => {
      if (releaseToTry !== undefined && JSON.stringify(releaseToTry) !== '{}') {
        return releaseToTry;
      }
      let candidateRelease = user.role?.name == 'CLIENT' ? releaseForClients : releaseForDevs
      if (candidateRelease === undefined || JSON.stringify(candidateRelease) === '{}') {
        candidateRelease = currentRelease;
      }
      return candidateRelease;
    })();
    const hasOneRelease = releaseToUse !== null && releaseToUse !== undefined && JSON.stringify(releaseToUse) !== '{}';
    if (hasOneRelease) {
      setResolution({
        width: releaseToUse.width,
        height: releaseToUse.height,
      })
      setReleaseToUse(releaseToUse);
    }
    setHasOneRelease(hasOneRelease);
  }, [releaseToTry, currentRelease, releaseForClients, releaseForDevs])

  useEffect(() => {
    getProject()
      .finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <SharedGuard projectId={project.id}>
        <div className="card shadow-card mb-2">
          <div className="card-header d-flex align-items-center">
            <div className="col-1 d-flex justify-content-center">
              <img src={Project.getImage(project)} alt="" style={imageIconStyle} className="" />
            </div>
            <div className="col-3">
              <p className="my-0 card-title vertical-align-center">{project?.title}</p>
              <p className="my-0 text-secondary">Updated {moment(project?.updated_at).fromNow()}</p>
            </div>
            <div className="col-3">
              <p className="my-0">{project?.created_by?.email}</p>
              <p className="my-0 text-secondary">on {moment(project?.created_at).format('MM/DD/YYYY')}</p>
            </div>
            <div className="col-2">
              <p className="my-0">Release created at</p>
              { hasOneRelease
                ? <p className="my-0 text-secondary">on {moment(releaseToUse?.created_at).format('MM/DD/YYYY')}</p>
                : <p className="my-0 text-secondary">No yet release</p>
              }
            </div>
            <div className="col-2">
              <p className="my-0">Version</p>
              { hasOneRelease
                ? <p className="my-0 text-secondary">{releaseToUse?.version}</p>
                : <p className="my-0 text-secondary">¯\_(ツ)_/¯</p>
              }
            </div>
            <div className="col-2 gap-2 d-flex">
              <ProjectDetails  id={id} />
              <Guard entity="PROJECT_TEAM" level="EDIT">
                <ProjectUsers projectId={id} />
                <ProjectGuests projectId={id} />
              </Guard>
            </div>
          </div>
        </div>
      </SharedGuard>

      { hasOneRelease &&
        <div className="card shadow-card mb-2">
          <div className="card-body d-flex justify-content-center">
            <DeviceToolbar setResolution={setResolution} defaultWidth={resolution.width} defaultHeight={resolution.height}/>
          </div>
        </div>
      }

      <div className="card shadow-card mb-2">
        <div className="card-body d-flex justify-content-center">
          { showLoading 
            ? <MiniLoader />
            : <DisplayRelease release={releaseToUse} resolution={resolution} image={Project.getImage(project)} />
          }
        </div>
      </div>

      <SharedGuard projectId={project.id}>
        <Guard entity="PROJECT_RELEASES" level="VIEW">
          <div className="card shadow-card mb-2">
            <div className="card-body d-flex align-items-center justify-content-between">
              <div className="">
                { hasOneRelease &&
                  <>
                    <h6 className="header-title mt-2 mb-2">Release changelogs</h6>
                    <p className="font-monospace text-muted">{releaseToUse?.changelog}</p>
                  </>
                }
              </div>
              <div className="d-flex gap-2">
                <Guard entity="PROJECT_RELEASES" level="CREATE">
                  <ReleaseCreate projectId={id} onProject={true} />
                </Guard>
                <ReleaseList />
              </div>
            </div>
        </div>
        </Guard>

        { (hasOneRelease || hasDummyRelease()) &&
          <div className="card shadow-card mb-2">
            <div className="card-body">
              <ProjectProvider projectId={project?.id}>
                <Guard entity="PROJECT_FEEDBACKS" level="CREATE">
                  <Tabs variant="pills" defaultActiveKey="feedback_list" className="mb-3 justify-content-start" onSelect={eventKey => eventKey !== null && setTabActiveKey(eventKey)} activeKey={tabActiveKey}>
                    {releaseToUse && releaseToUse.id
                      && <Tab eventKey="add_feedback" title="Add Feedback">
                        <FeedbackCreate releaseId={releaseToUse.id} afterSubmit={() => setTabActiveKey("feedback_list")}/>
                      </Tab>
                    }
                    <Tab eventKey="feedback_list" title="Feedbacks">
                      <ProjectFeedbacks projectId={project?.id} />
                    </Tab>
                  </Tabs>
                </Guard>

                <Guard entity="PROJECT_FEEDBACKS" level="VIEW" exactly={true}>
                  <Tabs variant="pills" defaultActiveKey="feedback_list" className="mb-3 justify-content-start">
                    <Tab eventKey="feedback_list" title="Feedbacks">
                      <ProjectFeedbacks projectId={project?.id} />
                    </Tab>
                  </Tabs>
                </Guard>
              </ProjectProvider>
            </div>
          </div>
        }
      </SharedGuard>
    </>
  )
}

export default ProjectView;
