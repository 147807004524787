import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import NotFoundScreen from './404/NotFound';
import Dashboard from './Dashboard/Dashboard';
import FeedbackView from './Feedback/FeedbackView';
import Feedbacks from './Feedback/FeedbackList';
import GenreList from './Genre/GenreList';
import Login from './Login/Login';
import ProjectList from './Project/ProjectList';
import ProjectView from './Project/ProjectView';
import TaskList from './Task/TaskList';
import UserList from './User/UserList';
import ProfileView from './Profile/ProfileView';
import RoleList from './Role/RoleList';
import CompanyList from './Company/CompanyList';
import TeamList from './Teams/TeamList';

const PagesNavigation = () => {

  const publicRoutes = [
    <Route path={'/login'} component={Login} key="Login"/>,
    <Route path={'/404'} component={NotFoundScreen} key="404"/>,
  ]

  const privateRoutes = [
    <ProtectedRoute path={'/'} exact component={Dashboard} key="Dashboard"/>,
    <ProtectedRoute path={'/projects'} exact component={ProjectList} key="ProjectIndex"/>,
    <ProtectedRoute path={'/projects/:id'} exact component={ProjectView} key="ProjectView"/>,
    <ProtectedRoute path={'/releases/:releaseId/feedbacks/:feedbackId'} exact component={FeedbackView} key="FeedbackView"/>,
    <ProtectedRoute path={'/tasks'} exact component={TaskList} key="TaskList"/>,
    <ProtectedRoute path={'/genres'} exact component={GenreList} key="GenreList"/>,
    <ProtectedRoute path={'/feedbacks'} exact component={Feedbacks} key="Feedbacks"/>,
    <ProtectedRoute path={'/users'} exact component={UserList} key="UserList"/>,
    <ProtectedRoute path={'/users/:id/profile'} exact component={ProfileView} key="ProfileView"/>,
    <ProtectedRoute path={'/roles'} exact component={RoleList} key="RoleList"/>,
    <ProtectedRoute path={'/companies'} exact component={CompanyList} key="CompanyList"/>,
    <ProtectedRoute path={'/teams'} exact component={TeamList} key="TeamList"/>,
  ]

    return (
        <BrowserRouter>
          <Switch>
            {publicRoutes}
            {privateRoutes}
            <Redirect to='/404' />
          </Switch>
      </BrowserRouter>
    );
}

export default PagesNavigation;