import moment from "moment";
import { useContext, useEffect } from "react";
import { Contact } from "../../../api/models/Contact.model";
import { User } from "../../../api/models/User.model";
import EmailButton from "../../../components/buttons/EmailButton";
import Context from "../../../context/Context";
import UserContext from "../../User/context/UserContext";

const ProfilePublic = ({ userId }: { userId: number }) => {
  const { state: { showLoader }, loader } = useContext(Context);
  const { state: { user, contacts }, getUser } = useContext(UserContext);

  useEffect(() => {
    loader.show()
    getUser().finally(() => loader.hide())
  }, []);

  const sendEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  }

  return (
    <>
      {!showLoader &&
        <div className="row">
          <div className="col-md-12 col-xl-3">

            <div className="card shadow-card mb-3">
              <div className="card-body">

                <div className="profile-widgets py-3">
                  <div className="text-center">
                    <div className="row align-items-center">
                      <div>
                        <img src={User.getImage(user)} alt="User Avatar" className="rounded-circle img-thumbnail" />
                      </div>
                      <div className="mt-3">
                        <div className="mt-1 mb-1 text-dark">{user.profile?.name}</div>
                        <div className="text-muted font-13 text-body mt-1 mb-1">{user.profile?.occupation}</div>
                      </div>
                    </div>

                    <div className="mt-4">

                      <ul className="list-inline social-source-list">
                        <li className="list-inline-item">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-dark" style={{ cursor: "pointer" }}>
                              <a href={user.profile?.github} target="_blank" rel="noopener noreferrer" className="text-white">
                                <i className="fab fa-github"></i>
                              </a>
                            </span>
                          </div>
                        </li>

                        <li className="list-inline-item">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-info" style={{ cursor: "pointer" }}>
                              <a href={user.profile?.twitter} target="_blank" rel="noopener noreferrer" className="text-white">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </span>
                          </div>
                        </li>

                        <li className="list-inline-item">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-primary" style={{ cursor: "pointer" }}>
                              <a href={user.profile?.linkedin} target="_blank" rel="noopener noreferrer" className="text-white">
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </span>
                          </div>
                        </li>

                        <li className="list-inline-item">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-danger" style={{ cursor: "pointer" }}>
                              <a href={user.profile?.instagram} target="_blank" rel="noopener noreferrer" className="text-white">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow-card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-2">My Top Skills</h5>
                <p className="text-muted mb-0 pb-0"><em>{"Excellence is not a skill, it's an attitude."}</em></p>
                <p className="text-muted"><em>{"Ralph Marston"}</em></p>
                <ul className="list-unstyled list-inline language-skill mb-0">
                  {user.profile?.skills?.split(',')?.map((skill, it) => (
                    <li key={it} className="list-inline-item badge bg-danger"><span>{skill}</span></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-xl-9">
            <div className="row">

              <div className="col-md-12 col-xl-4">
                <div className="card shadow-card mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <p className="mb-2">User since</p>
                        <h5 className="mb-0 text-muted">{moment(user.created_at).format('dddd D MMMM YYYY')}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-4">
                <div className="card shadow-card mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <p className="mb-2">Last Update</p>
                        <h5 className="mb-0 text-muted">{moment(user.updated_at).format('dddd D MMMM YYYY')}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-4">
                <div className="card shadow-card mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <p className="mb-2">Total Projects</p>
                        <h5 className="mb-0 text-muted">{user.projects?.length}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow-card mb-3">
              <div className="card-body">
                <h6 className="header-title mt-2 mb-2">About me</h6>
                <div className="text-muted font-13 text-body mt-1 mb-1">{user.profile?.about}</div>
              </div>
            </div>

            <div className="card shadow-card mb-3">
              <div className="card-body">
                <h6 className="header-title mt-2 mb-2">Contacts</h6>

                <div className="">
                  <table className="table table-bordered table-striped table-sm text-center">
                    <thead>
                      <tr>
                        <th scope="col">Phone</th>
                        <th scope="col">Street Name</th>
                        <th scope="col">Street Number</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts?.map((contact: Contact) => (
                        <tr key={contact.id}>
                          <td>{contact.phone}</td>
                          <td>{contact.street}</td>
                          <td>{contact.number}</td>
                          <td>{contact.email}</td>
                          <td>
                            <div className="d-flex mr-2 gap-2 justify-content-center">
                              <EmailButton onClick={() => sendEmail(contact.email)} placement="top" hover="Send email" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      }
    </>
  )
}

export default ProfilePublic;
