import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import { User, UserPageable } from "../../../api/models/User.model";
import BaseButton from "../../../components/buttons/BaseButton";
import PillIcon from "../../../components/pills/PillIcon";
import { UserIconOption } from "../../../components/select/IconOption";
import Search from "../../../components/select/Search";
import ReleaseContext from "../../Release/context/ReleaseContext";

const ProjectGuests = ({ projectId }: {projectId: number}): JSX.Element => {
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { getProject } = useContext(ReleaseContext);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const guestsAssigned: UserPageable = await API.getUsersSharedToProject(1, projectId);
        setUsers(guestsAssigned.data);
      } catch (err) {
        console.log('Oops, something went wrong ', err);
      }
    })()
  },[projectId, modalShow])

  const submit = (e: SyntheticEvent) => {
    e.preventDefault();

    API.updateUsersSharedToProject(projectId, users.map((user: User) => user.id))
    .then(() => {
      getProject();
      setModalShow(false);
      setShow(false);
    })
    .catch((err: ApiException) => {
      setError(err.message);
      setShow(true);
    });

  };

  const selectUser = (user: User) => {
    if (users.some((it: User) => it.id == user.id)) {
      return;
    }
    setUsers([...users, user]);
  }

  const unselectUser = (user: User) => {
    setUsers(users.filter((it: User) => it.id != user.id));
  }

  const handleCancel = () => {
    setShow(false);
    setModalShow(false);
  }

  return  (
    <>
      <BaseButton className="btn-sm btn-danger" onClick={() => setModalShow(true)} placement={"top"} hover="Share with Guest" iconName="share"/>
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Share game</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
          <Modal.Body>
            <Search search={(email) => API.searchGuestByEmail(email, projectId)} item={UserIconOption} onItemClick={selectUser} />
            <div className="d-flex flex-wrap gap-2 mt-3">
              {users.map((user:User) => 
                <PillIcon
                  key={user.id}
                  icon={User.getImage(user)}
                  hover={user.email}
                  placement="top"
                  label={user?.profile?.name || User.getUsername(user)}
                  checkable={true}
                  onCheck={() => unselectUser(user)}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</button>
            <button className="btn btn-sm btn-primary" onClick={submit}>Save</button>
        </Modal.Footer>
        </Modal>
    </>
  )
}

export default ProjectGuests;