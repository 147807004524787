import { CompanyAPI } from "./services/Company.service";
import { ContactAPI } from "./services/Contact.service";
import { DeviceAPI } from "./services/Device.service";
import { FeedbackAPI } from "./services/Feedback.service";
import { GenreAPI } from "./services/Genre.service";
import { ProjectAPI } from "./services/Project.service";
import { RoleAPI } from "./services/Role.service";
import { StatisticAPI } from "./services/Statistic.service";
import { TaskAPI } from "./services/Task.service";
import { UserAPI } from "./services/User.service";

export const API = {
  ...CompanyAPI,
  ...ContactAPI,
  ...FeedbackAPI,
  ...GenreAPI,
  ...ProjectAPI,
  ...RoleAPI,
  ...StatisticAPI,
  ...UserAPI,
  ...TaskAPI,
  ...DeviceAPI,
};