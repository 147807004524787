import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { SyntheticEvent, useContext, useState } from "react";
import UserContext from "../User/context/UserContext";
import { API } from "../../api/api";
import AddButton from "../../components/buttons/AddButton";
import { ApiException } from "../../api/errors/ApiException";

const ContactCreate = (props: {userId: number}): JSX.Element => {
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  
  const { getUserContacts: getContacts } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  
  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      API.createContact({
        phone,
        street,
        number,
        email,
        user_id: props.userId,
      })
      .then(() => {
        getContacts();
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      })
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getContacts();
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  return (
    <>
      {/* <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add Contact" /> */}
      <button className="dropdown-item" onClick={() => setModalShow(true)}>{"Add Contact"}</button>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header  className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
          <Modal.Title>Add Contact</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} md="3">
                <Form.Label>Phone number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Phone number" onChange={ e => setPhone(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>Street name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street name" onChange={ e => setStreet(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Street number</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Street number"  onChange={ e => setNumber(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid street number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email"  size="sm" placeholder="Email" onChange={ e => setEmail(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ContactCreate;