import { Link } from "react-router-dom";
import { User } from "../api/models/User.model";
import { API } from "../api/api";
import { Project } from "../api/models/Project.model";
import Avatar from "./svg/Avatar";
import PasswordUpdate from "../pages/User/components/PasswordUpdate";
import ProfileCreate from "../pages/Profile/components/ProfileCreate";
import { isEmpty } from "../utils/Objects";
import Search from "./select/Search";
import { ProjectIconOption } from "./select/IconOption";
import { useContext } from "react";
import Context from "../context/Context";
import Killabunnies from "./logo/Killabunnies";
import ProfileUpdate from "../pages/Profile/components/ProfileUpdate";
import ContactCreate from "../pages/Contact/ContactCreate";

const mutedTextStyle = {
  fontSize: '11px',
}

const Header = ({ user }: { user: User }): JSX.Element => {
  const { isGuest } = useContext(Context);

  const logout = async () => {
    await API.logout();
  }

  const getName = (name: string) => {
    return name !== undefined ? name : user.email;
  }

  const buildHref = (data: Project) => `/projects/${data.id}`

  return (
    <header className="navbar navbar-dark sticky-top flex-md-nowrap col-12 mb-2">
      <div className={["d-flex align-items-center justify-content-between w-100 gap-3", !isGuest() ? "col-ps-md-3 col-ps-lg-2" : "ps-5"].join(' ')}>
        <button className="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div>
          {!isGuest()
            ? <Search 
              className="form-control" 
              search={API.searchProjects}
              item={ProjectIconOption}
              href={buildHref}
            />
            : <Killabunnies />
          }
        </div>

        <div className="dropdown text-end d-flex gap-2 col-auto">
          <div>
            <p className="text-left align-self-center m-0 text-dark"> {getName(user?.profile?.name)}</p>
            <p className="d-block text-left align-self-center m-0 text-muted" style={mutedTextStyle}> {getName(user?.role?.name)} </p>
          </div>
          <a href="/#" className="d-block link-light text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            <Avatar src={User.getImage(user)} alt="avatar" />
          </a>
          <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="dropdownUser1">
            { !isGuest() && 
              <>
                {isEmpty(user.profile)
                  ? <li className="small"><ProfileCreate /></li>
                  : <>
                      <li className="small"><Link className="dropdown-item" to={`/users/${user.id}/profile`}>Profile</Link></li>
                      <li className="small"><ProfileUpdate profile={user.profile} /></li>
                      <li className="small"><ContactCreate userId={user.id} /></li>
                    </> 
                }
              </>
            }
            <li className="small"><PasswordUpdate /></li>
            <li><hr className="dropdown-divider" /></li>
            <li className="small"><Link className="dropdown-item" to="/login" onClick={logout}>Sign out</Link></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header;