import moment from "moment";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { API } from "../../../api/api";
import { TaskSortCriteriaOrder } from "../../../api/models/Task.model";
import { TaskCategory } from "../../../api/models/TaskCategory.model";
import { TaskPriority } from "../../../api/models/TaskPriority.model";
import { TaskState } from "../../../api/models/TaskState.model";
import Paginator from "../../../components/Paginator";
import ColumnFilter from "../../../components/table/ColumnFilter";
import ColumnSorter from "../../../components/table/ColumnSorter";
import OptionFilter from "../../../components/table/OptionFilter";
import TableFiltering from "../../../components/table/TableFiltering";
import { SortCriteriaOptions } from "../../../types/Generics";
import TaskUpdate from "../../Task/components/TaskUpdate";
import TaskView from "../../Task/components/TaskView";
import FeedbackContext from "../context/FeedbackContext";

const FeedbackTasks = ({ feedbackId }: { feedbackId: number }): JSX.Element => {
  const [page, setPage] = useState(1);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const { state: {feedback, tasks, lastPage}, getFeedbackTasks } = useContext(FeedbackContext);
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [taskPriorities, setTaskPriorities] = useState<TaskPriority[]>([]);
  const [taskCategories, setTaskCategories] = useState<TaskCategory[]>([]);

  const [title, setTitle] = useState<string|null>(null);
  const [state, setState] = useState<number|null>(null)
  const [priority, setPriority] = useState<number|null>(null)
  const [category, setCategory] = useState<number|null>(null)
  const [assignedTo, setAssignedTo] = useState<string|null>(null)

  const [createdAtSorter, setCreatedAtSorter] = useState<SortCriteriaOptions>('DESC');
  const [dueAtSorter, setDueAtSorter] = useState<SortCriteriaOptions>(null);
  const [sortersOrder, setSortersOrder] = useState<TaskSortCriteriaOrder[]>(['created_at']);

  useEffect(() => {
    getFeedbackTasks({
      page,
      title,
      state,
      priority,
      category,
      assigned_to: assignedTo,
      created_at_sort: createdAtSorter,
      due_at_sort: dueAtSorter,
      sort_criteria: sortersOrder,
    }).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, title, state, priority, category, assignedTo, createdAtSorter, dueAtSorter, sortersOrder]);

  useEffect(() => {
    (async () => {
      const [states, priorities, categoies] = await Promise.all([
        API.getTaskStates(),
        API.getTaskPriorities(),
        API.getTaskCategories(),
      ]);
      setTaskStates(states);
      setTaskPriorities(priorities);
      setTaskCategories(categoies);
    })()
  },[])

  const switchCreatedAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'created_at'));
    } else if (sortersOrder.indexOf('created_at') < 0) {
      sortersOrder.push('created_at');
      setSortersOrder(sortersOrder);
    }
    setCreatedAtSorter(sortCriteria);
  }

  const switchDueAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'due_at'))
    } else if (sortersOrder.indexOf('due_at') < 0) {
      sortersOrder.push('due_at');
      setSortersOrder(sortersOrder);
    }
    setDueAtSorter(sortCriteria);
  }

  const getDueDateColor = (date: string, state: string): string => {
    if (state === 'READY') return 'text-muted fw-bold';

    const days = moment(date).diff(moment(), 'days');
    if (days > 0 && days <= 7) {
      return 'text-warning fw-bold';
    } else if (days <= 0) {
      return 'text-danger fw-bold';
    }
    return 'text-success fw-bold';
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Title',
      Footer: 'title',
      accessor: 'title',
      canFilter: true,
      Filter: ColumnFilter(title, setTitle),
    },{
      Header: 'State',
      Footer: 'state',
      accessor: 'state',
      canFilter: true,
      Filter: OptionFilter({
        options: taskStates.map((taskState) => ({id: taskState.id, value: taskState.state})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setState(option.id),
      }),
    },
    {
      Header: 'Priority',
      Footer: 'priority',
      accessor: 'priority',
      canFilter: true,
      Filter: OptionFilter({
        options: taskPriorities.map((taskPriority) => ({id: taskPriority.id, value: taskPriority.name})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setPriority(option.id),
      }),
      Cell: (props : { value: string } ) => (
        <Badge bg={props.value === "HIGH" ? `danger` : (props.value === "MIDIUM" ? `warning`: `info`)}>{props.value}</Badge>
      )
    },
    {
      Header: 'Category',
      Footer: 'category',
      accessor: 'category',
      canFilter: true,
      Filter: OptionFilter({
        options: taskCategories.map((taskCategory) => ({id: taskCategory.id, value: taskCategory.name})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setCategory(option.id),
      }),
    },
    {
      Header: 'Assigned to',
      Footer: 'assigned_to',
      accessor: 'assigned_to',
      canFilter: true,
      Filter: ColumnFilter(assignedTo, setAssignedTo),
    },
    {
      Header: 'Created at',
      Footer: 'created_at',
      accessor: 'created_at',
      canFilter: false,
      canSort: true,
      Sorter: ColumnSorter({ defaultSortCriteria: createdAtSorter, setSortCriteria: switchCreatedAtSort })
    },
    {
      Header: 'Due at',
      Footer: 'due_at',
      accessor: 'due_at',
      canFiler: false,
      canSort: true,
      Sorter: ColumnSorter({ setSortCriteria: switchDueAtSort }),
      Cell: (props : { value: { due_at: string, state: string } } ) => (
        <span className={getDueDateColor(props.value.due_at, props.value.state)}>{props.value.due_at}</span>
      ),
    },
    { 
      Header: 'Actions',
      Footer: 'actions',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = tasks.map(task => ({
    id: task.id,
    title: task.title,
    state: task.state.state,
    priority: task.priority.name,
    category: task.category.name,
    assigned_to: task.assignation?.assigned_to?.email,
    created_at: moment(task.created_at).format('MM/DD/YYYY'),
    due_at: {
      due_at: moment(task.due_at).format('MM/DD/YYYY'),
      state: task.state.state,
    },
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        {feedback.closed
          ? <TaskView taskId={task.id} />
          : <TaskUpdate taskId={task.id} feedbackId={feedbackId} />
        }
      </div>
    )
  }));

  return (
    <div>
      <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
      <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
    </div>
  );
}

export default FeedbackTasks;