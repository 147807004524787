/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Modal } from "react-bootstrap"

type ErrorAlertProps = {
  show: boolean,
  title: string,
  message: string,
  onCancel: () => void,
}

const ErrorAlert = (props: ErrorAlertProps): JSX.Element => {

  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="primary" onClick={props.onCancel}>OK</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ErrorAlert;