import axios, { AxiosError, AxiosResponse } from "axios";
import { Parameters } from "../../types/Generics";
import { Profile, ProfileCreateDto } from "../models/Profile.model";
import { User } from "../models/User.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserAPI {

  export const login = async (body: any): Promise<any> => {
    const uri = `login`;

    return axios.post(uri, body)
      .then((res: AxiosResponse) => {
        localStorage.setItem('token', res.data.token);
        axios.defaults.headers['x-auth-token'] = res.data.token;
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const logout = async (): Promise<any> => {
    localStorage.removeItem('token');
    delete axios.defaults.headers['x-auth-token'];
  }

  export const register = async (body: any): Promise<any> => {
    const uri = `users`;
    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const updatePassword = async (body: any): Promise<any> => {
    const uri = `users/password`;
    return await axios.put(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const refreshToken = async (): Promise<any> => {
    const uri = `refresh-token`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        localStorage.setItem('token', res.data.token);
        axios.defaults.headers['x-auth-token'] = res.data.token;
        return res.data.me;
      })
      .catch((err: AxiosError) => {
        localStorage.removeItem('token');
        delete axios.defaults.headers['x-auth-token'];
        errorHandler(err);
      });
  }

  export const getUser = async (id: number): Promise<User | any> => {
    const uri = `users/${id}`;

    return await axios.get(uri)
      .then((res: AxiosResponse)=> {
        return res.data;
      })
      .catch((err: AxiosError) => {
        console.log("Ooops!: ", err);
      });
  }

  export const updateUser = async (id:number, body: any): Promise<any> => {
    const uri = `users/${id}`;
    return axios.put(uri, body)
      .catch((err:AxiosError) => {
        errorHandler(err);
      });
  }

  export const deleteUser = async (id: number): Promise<any> => {
    const uri = `users/${id}`;
    return axios.delete(uri)
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUserContacts = async (id: number): Promise<any> => {
    const uri = `users/${id}/contacts`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUserProfile = async (id: number): Promise<Profile> => {
    const uri = `users/${id}/profile`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUserTasks = async (id: number, page:number): Promise<any> => {
    const uri = `users/${id}/tasks?limit=15&offset=${(page-1)*15}`;
    
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUserProjects = async (id: number): Promise<any> => {
    const uri = `users/${id}/projects`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const getUsers =  async (page = 1, email: string | null): Promise<User[] | any> => {
    const uri = `users`;

    const params: Parameters= {
      page: page,
    };

    if (email !== null && email.length > 1) params['email'] = email;

    return await axios.get(uri, {params})
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
    });
  }

  export const searchUserByEmail = async (email: string, project_id?: number): Promise<User[]> => {
    const params = { project_id, email }
    return await axios.get("users/as", { params })
      .then((res: AxiosResponse)=> {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const searchGuestByEmail = async (email: string, project_id?: number): Promise<User[]> => {
    const params = { project_id, email }
    return await axios.get("users/as/guests", { params })
      .then((res: AxiosResponse)=> {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const createUserProfile = async (body: ProfileCreateDto, userId: number|null = null): Promise<any> => {
    const uri = !userId ? `profile` : `profile/${userId}`;
    return await axios.post(uri, body )
      .then((res: AxiosResponse) =>{
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const updateUserProfile = async (id: number, body: any): Promise<any> => {
    const uri = `profile/${id}`;

    return await axios.put(uri, body )
      .then((res: AxiosResponse) =>{
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }
}
