import { Dispatch, Key, ReactChild, ReactFragment, ReactPortal, SetStateAction, useState } from "react";
import { Dropdown } from "react-bootstrap";

type Option = {
  id: number|null
  value: ReactChild | ReactFragment | ReactPortal
  [key:string]: any
}

const allOptions: Option = {
  id: null,
  value: "All",
}

export type OptionFilterProps = {
  options: Option[]
  cell?: (props: Option) => JSX.Element;
  filterValue?: Option
  setFilter: Dispatch<SetStateAction<Option|null>>| ((option: Option) => void)
}

const OptionFilter = (props: OptionFilterProps): JSX.Element => {
  const [optionSelected, setOption] = useState<Option>(allOptions)

  const selectOption = (option: Option) => {
    props.setFilter(option)
    setOption(option)
  }

  return (
    <Dropdown className="dropdown-button-drop-hidden">
      <Dropdown.Toggle variant="" size="sm" bsPrefix=" " className="d-block w-100 shadow-none form-control border-0">
        {props.cell ? <props.cell {...optionSelected}>{optionSelected.value}</props.cell> : optionSelected.value}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item key={allOptions.id} onClick={() => selectOption(allOptions)}>{allOptions.value}</Dropdown.Item>
        {props.options.map((option) => (
          <Dropdown.Item key={option.id} onClick={() => selectOption(option)}>{option.value}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default OptionFilter;
