import { Contact } from "../../../api/models/Contact.model";
import { Profile } from "../../../api/models/Profile.model";
import { User } from "../../../api/models/User.model";
import UserState from "./UserState";

const UserReducer = (state: UserState, action: {payload: any, type: string}): UserState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_USER_PROFILE":
      return {
        ...state,
        profile: payload as Profile
      };

    case "GET_USER_CONTACTS":
      return {
        ...state,
        contacts: payload as Contact[]
      };

    case "GET_USERS":
      return {
        ...state,
        users: payload.users,
        lastPage: payload.lastPage
      };

    case "GET_USER":
      // eslint-disable-next-line no-case-declarations
      const user = payload as User;
      return {
        ...state,
        user,
        contacts: user.contacts,
        profile: user.profile,
        skills: user.profile ? user.profile.skills.split(',') : [],
      }

    case "GET_USER_TASKS":
      return {
        ...state,
        tasks: payload.tasks,
        lastPage: payload.lastPage
      }
    
    case "ERROR":
      // eslint-disable-next-line no-case-declarations
      const { code } = payload;
      return {
        ...state,
        errorCode: code,
    };

    default:
      return state;
  }
}

export default UserReducer;
