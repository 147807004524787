import { Feedback } from "../../../api/models/Feedback.model";
import { Project } from "../../../api/models/Project.model";
import ProjectState from "./ProjectState";

const ProjectReducer = (state: ProjectState, action: {payload: any, type: string}): ProjectState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_PROJECT_FEEDBACKS":
      return {
        ...state,
        feedbacks: payload.feedbacks as Feedback[],
        lastPage: payload.lastPage, // WARNING!!!!
        defaultFeedbackFilters: payload.filters,
      };
    
    case "GET_PROJECTS":
      return {
        ...state,
        projects: payload.projects as Project[],
        lastPage: payload.lastPage,
        defaultProjectFilters: payload.filters,
      }

    case "GET_PROJECT":
      return {
        ...state,
        project: payload as Project,
      }
    
    case "ERROR":
      // eslint-disable-next-line no-case-declarations
      const { code } = payload;
      return {
        ...state,
        errorCode: code,
      };

    default:
      return state;
  }
}

export default ProjectReducer;