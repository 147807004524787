import { ReactNode, useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import UserContext from './UserContext';
import UserReducer from './UserReducer';
import UserState from './UserState';

const UserProvider = (props: {children: ReactNode, userId: number | undefined}): JSX.Element => {
  const [state, dispatch] = useReducer(UserReducer, {} as UserState, () => new UserState());

  const getUser = (): Promise<void> => {
    return API.getUser(Number(props.userId))
      .then(userResponse => {
        dispatch({
          type: "GET_USER",
          payload: userResponse
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const getUserContacts = (): Promise<void> => {
    return API.getUserContacts(Number(props.userId))
      .then(userContactsResponse => {
        dispatch({
          type: "GET_USER_CONTACTS",
          payload: userContactsResponse
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const getUserProfile = (): Promise<void> => {
    return API.getUserProfile(Number(props.userId))
      .then(userProfileResponse => {
        dispatch({
          type: "GET_USER_PROFILE",
          payload: userProfileResponse
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const getUserTasks = (page: number): Promise<void> => {
    return API.getUserTasks(Number(props.userId), page)
      .then(userTasks => {
        dispatch({
          type: "GET_USER_TASKS",
          payload: {
            tasks: userTasks,
            lastPage: userTasks.length,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  const getUsers = (page: number, email: string|null): Promise<void> => {
    return API.getUsers(page, email)
      .then(usersResponse => {
        dispatch({ 
          type: "GET_USERS",
          payload: {
            users: usersResponse.data,
            lastPage: usersResponse.meta.last_page,
          }
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  return (
    <UserContext.Provider
      value = {{
        state,
        getUser,
        getUserProfile,
        getUserContacts,
        getUserTasks,
        getUsers,
      }}
    >
      { props.children }
    </UserContext.Provider>
  )
}

export default UserProvider;