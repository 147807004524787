import NextButton from "./buttons/NextButton";
import PrevButton from "./buttons/PrevButton";

type PaginatorProps = {
  page: number;
  lastPage: number | null;
  onPageChange: (page: number) => void;
};

const Paginator = (props: PaginatorProps) => {

  const next = () => props.onPageChange(props.page === props.lastPage ? props.lastPage : props.page + 1);
  const prev = () => props.onPageChange(props.page === 1 ? 1 : props.page - 1);

  return (
    <>
      { (props.lastPage && props.lastPage !== 1) ?
          <div className="d-flex align-items-center justify-content-end">
            <div className="gap-2 d-flex">
              <PrevButton onClick={prev} hover='Previous page' placement="top"/>
              <NextButton onClick={next} hover='Next page' placement="top"/>
            </div>
          </div>
          : <></>
      }
    </>
  )
}

export default Paginator;
