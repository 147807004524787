/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import UserState from './UserState';

class ContextType {
  constructor(
    public state: UserState = {} as UserState,
    public getUser: () => Promise<void> = async () => {},
    public getUserProfile: () => Promise<void> = async () => {},
    public getUserContacts: () => Promise<void> = async () => {},
    public getUsers: (page: number, email: string|null) => Promise<void> = async (_1, _2) => {},
    public getUserTasks: (page: number) => Promise<void> = async (_) => {},
  ){}
} 

const UserContext = createContext<ContextType>({} as ContextType);

export default UserContext;