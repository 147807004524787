import { Task, TaskPagingFilter } from "../../../api/models/Task.model";

class TaskState {
  constructor(
    public tasks: Task[] = [],
    public defaultFilters: TaskPagingFilter = {} as TaskPagingFilter,
    public lastPage: number = 1,
    public state = {hasError: false},
  ){}
}

export default TaskState;