import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type SaveButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const SaveButton = (props: SaveButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-success"
    iconName="save"
  />
);

export default SaveButton;