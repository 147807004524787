import { Project, ProjectPageable } from "../../api/models/Project.model";
import { API } from "../../api/api";
import { User } from "../../api/models/User.model";
import { useHistory } from "react-router-dom";
import InfinityScroll from "../../components/lazy/InfinityScroll";

const TeamList = (): JSX.Element => {

  const fetchProjects = (page: number): Promise<ProjectPageable> => 
  API.getProjects({
    page,
    created_at_sort: 'DESC',
    sort_criteria: ['created_at'],
  });

  return (
    <>
      <div className="card shadow-card mb-2">
        <div className="card-body row">
          <InfinityScroll fetcher={fetchProjects} component={CardProject} />
        </div>
      </div>
    </>
  )
}

const CardProject = ({ data: project }: { data: Project }) => {
  const history = useHistory();
  const view = (user: User) => {
    console.log(user.profile);
    if (user.profile === null) return;
    history.push(`/users/${user.id}/profile`);
  }

  return (
    <div className="col-md-2 mb-4">
      <div  className="card shadow-card h-100" style={{ maxWidth: '22rem' }}>
        <div className="card-block d-flex flex-column">
          <img className="card-img-top" src={Project.getImage(project)} alt={project.title} draggable="false" onDragStart={(e) => {e.preventDefault();}}/>
          <div className="card-body">
            <h6 className="card-title">{project.title}</h6>
          </div>
          <div className="card-footer bg-white">
            <div className="d-flex gap-2 flex-wrap">
              {project.users.map((user) => (
                <a key={user.id} style={{cursor: "pointer"}} onClick={() => view(user)} data-toggle="tooltip" data-placement="top" title={user.email}>
                  <img src={User.getImage(user)} alt="Avatar" width="32px" style={{borderRadius: "50%"}}/>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamList;