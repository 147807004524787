import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type ViewButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const ViewButton = (props: ViewButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-warning"
    iconName="eye"
  />
);

export default ViewButton;
