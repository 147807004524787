import TaskState from "./TaskState";

const TaskReducer = (state: TaskState, action: {payload: any, type: string}): TaskState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_TASKS":
      // eslint-disable-next-line no-case-declarations
      const { tasks, lastPage, filters } = payload;
      return {
        ...state,
        tasks,
        lastPage,
        defaultFilters: filters,
      };

    default:
      return state;
  }
}

export default TaskReducer;
