import { SyntheticEvent, useEffect, useState, useContext } from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import { Genre } from "../../../api/models/Genre.model";
import EditButton from "../../../components/buttons/EditButton";
import GenreContext from "../context/GenreContext";

type InitialState = {
  genreName: string,
}

const GenreUpdate = (props: {id: number}): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [genreName, setGenreName] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const { getGenres } = useContext(GenreContext);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() =>{
    API.getGenre(props.id)
    .then((genre: Genre) => {
      setGenreName(genre.name);
      setInitialState({
        genreName: genre.name,
      })
    })
    .catch((err: ApiException) => {
      console.log(err);
    });
  }, [props.id]);

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      API.updateGenre(props.id, {
        name: genreName
      })
      .then(() => {
        getGenres(1);
        setModalShow(false);
        setValidated(false);
        setShow(false);
        setInitialState({
          genreName,
        })
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getGenres(1);
    setModalShow(false);
    setValidated(false);
    setShow(false);
    setGenreName(initialState.genreName);
  }

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit genre"} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update gender</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Genre</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Genre" value={genreName} onChange={ e => setGenreName(e.target.value) } required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a genre name.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>

        </Form>

      </Modal>
    </>
  );
}

export default GenreUpdate;