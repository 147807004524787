import axios from "axios";
import { useContext, useEffect } from "react";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Profile } from "../../../api/models/Profile.model";
import EditButton from "../../../components/buttons/EditButton";
import Context from "../../../context/Context";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";

type InitialState = {
  firstName : string,
  lastName : string,
  avatar : string,
  about : string,
  occupation : string,
  skills: string,
  github: string,
  twitter: string,
  linkedin: string,
  instagram: string,
}

const ProfileUpdate = (props: {profile: Profile}): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [about, setAbout] = useState('');
  const [occupation, setOccupation] = useState('');
  const [avatar, setAvatar ] = useState('');
  const [skills, setSkills ] = useState('');
  const [github, setGithub] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');

  const { getUser } = useContext(Context);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setFirstName(props.profile.first_name);
    setLastName(props.profile.last_name);
    setAbout(props.profile.about);
    setOccupation(props.profile.occupation);
    setAvatar(props.profile.avatar);
    setSkills(props.profile.skills);
    setGithub(props.profile.github);
    setTwitter(props.profile.twitter);
    setLinkedin(props.profile.linkedin);
    setInstagram(props.profile.instagram);
    setInitialState({
      firstName: props.profile.first_name,
      lastName: props.profile.last_name,
      avatar: props.profile.about,
      about: props.profile.occupation,
      occupation: props.profile.avatar,
      skills: props.profile.skills,
      github: props.profile.github,
      twitter: props.profile.twitter,
      linkedin: props.profile.linkedin,
      instagram: props.profile.instagram,
    });
  }, [props.profile])


  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setAvatar(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      API.updateUserProfile(props.profile.id, {
        first_name: firstName,
        last_name: lastName,
        avatar,
        about,
        occupation,
        skills,
        github,
        twitter,
        linkedin,
        instagram,
      })
      .then(() => {
        getUser();
        setModalShow(false);
        setValidated(false);
        setShow(false);
        setInitialState({
          firstName,
          lastName,
          avatar,
          about,
          occupation,
          skills,
          github,
          twitter,
          linkedin,
          instagram,
        });
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getUser();
    setModalShow(false);
    setValidated(false);
    setShow(false);
    setFirstName(initialState.firstName);
    setLastName(initialState.lastName);
    setAbout(initialState.avatar);
    setOccupation(initialState.about);
    setAvatar(initialState.occupation);
    setAvatar(initialState.skills);
    setGithub(initialState.github);
    setTwitter(initialState.twitter);
    setLinkedin(initialState.linkedin);
    setInstagram(initialState.instagram);
  }

  return (
      <>
      {/* <EditButton onClick={() => setModalShow(true)} placement={"left"} hover={"Edit profile"} /> */}
      <button className="dropdown-item" onClick={() => setModalShow(true)}>{"Update Profile"}</button>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update profile</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text"  size="sm" placeholder="First Name" onChange={e => setFirstName(e.target.value)} value={firstName} required />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid first name.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom02" className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text"  size="sm" placeholder="Last Name" onChange={e => setLastName(e.target.value)} value={lastName} required />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid last name.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom03" className="mb-3">
            <Form.Label>Avatar</Form.Label>
            <Form.Control type="file" size="sm"  onChange={e => imageUpload( (e.target as HTMLInputElement).files )}/>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid image.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom04" className="mb-3">
            <Form.Label>About</Form.Label>
            <Form.Control as="textarea"  size="sm" placeholder="Description"  onChange={e => setAbout(e.target.value)} value={about} required />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid company description.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom05" className="mb-3">
            <Form.Label>Occupation</Form.Label>
            <Form.Control type="text"  size="sm" placeholder="Occupation" onChange={e => setOccupation(e.target.value)} value={occupation} required />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid occupation.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom06" className="mb-3">
            <Form.Label>Skills</Form.Label>
            <Form.Control as="textarea"  size="sm" placeholder="C++, Unreal, PixiJS"  onChange={e => setSkills(e.target.value)} value={skills} required />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Please provide a valid skill list.</Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Form.Group controlId="validationCustom05" as={Col} md="3">
              <Form.Label>GitHub</Form.Label>
              <Form.Control type="link" size="sm" placeholder="https://github.com/" onChange={e => setGithub(e.target.value)} value={github} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid github link.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05" as={Col} md="3">
              <Form.Label>Twitter</Form.Label>
              <Form.Control type="text" size="sm" placeholder="https://twitter.com/" onChange={e => setTwitter(e.target.value)} value={twitter} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid twitter link.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05" as={Col} md="3">
              <Form.Label>Linkedin</Form.Label>
              <Form.Control type="text" size="sm" placeholder="https://www.linkedin.com/" onChange={e => setLinkedin(e.target.value)} value={linkedin} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid linkedin link.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05" as={Col} md="3">
              <Form.Label>Instagram</Form.Label>
              <Form.Control type="text" size="sm" placeholder="https://www.instagram.com/" onChange={e => setInstagram(e.target.value)} value={instagram} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Please provide a valid instagram link.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ProfileUpdate