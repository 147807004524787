/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "react-router-dom";
import Logo from "../logo/Logo";
import SidebarFeedbacks from "./SidebarFeedbacks";
import SidebarProjects from "./SidebarProjects";
import SidebarUsers from "./SidebarUsers";
import './Sidebar.css';

const textStyle = {
  fontSize: '10px',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Sidebar = (props: any): JSX.Element => {
  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse">
      <div className="d-flex flex-column flex-shrink-0 p-3" >
        <Link to={'/'} className="align-items-center" aria-current="page">
          <Logo/>
        </Link>
        <hr className="nav-divider text-white mt-3" />
        <ul className="nav nav-pills flex-column mb-auto">
          <li>
            <h6 className="text-muted mt-4" style={textStyle}>PROJECTS</h6>
          </li>
          <SidebarProjects/>
          <li>
            <h6 className="text-muted mt-4" style={textStyle}>FEEDBACKS</h6>
          </li>
          <SidebarFeedbacks/>
          <li>
            <h6 className="text-muted mt-4" style={textStyle}>USERS</h6>
          </li>
          <SidebarUsers/>
        </ul>
        <hr className="nav-divider text-white mt-3" />
      </div>
    </nav>
  )
}

export default Sidebar;