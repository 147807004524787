import axios, { AxiosError, AxiosResponse } from "axios";
import { Company } from "../models/Company.model";
import { errorHandler } from "../utils/ErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CompanyAPI {
  /**
   * 
   * @param id company ID
   * @returns 
   */
  export const getCompany =  async (id: number): Promise<Company> => {
    const uri = `companies/${id}`;
    return await axios.get(uri)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  export const createCompany = async (body: any): Promise<any> => {
    const uri = `companies`;

    return await axios.post(uri, body)
      .then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((err: AxiosError) => {
        errorHandler(err);
			});
  }

  /**
   * 
   * @param id 
   * @param params 
   * @returns 
   */
  export const updateCompany = async (id: number, body: any): Promise<any> => {
    const uri = `companies/${id}`;

    return await axios.put(uri, body)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
          errorHandler(err);
      });
    }

  /**
   * 
   * @param id 
   * @returns 
   */
  export const deleteCompany = async (id: number): Promise<any> => {
    const uri =  `companies/${id}`;
    return await axios.delete(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
          errorHandler(err);
      });
  }

  /**
   * @returns an object that contains array with companies and a number with total pages
   */
  export const getCompanies = async (page = 1): Promise<any> => {
    const uri = `companies?page=${page}`;

    return await axios.get(uri)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
      });
  }

  export const searchCompanies = async (name: string): Promise<Company[]> => {
    const uri = `companies/as?name=${name}`;

    return await axios.get(uri)
    .then((res: AxiosResponse)=> {
      return res.data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    })
  }
}
