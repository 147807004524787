/* eslint-disable @typescript-eslint/no-unused-vars */
import { useReducer } from 'react';
import { API } from '../api/api';
import { ApiException } from '../api/errors/ApiException';
import { User } from '../api/models/User.model';
import Context from './Context';
import Reducer from './Reducer';
import State from './State';

const Provider = (props: { children: any }): JSX.Element => {
  const [state, dispatch] = useReducer(Reducer, {} as State, (_) => new State());

  const getUser = (): Promise<void> => {
    return API.refreshToken()
      .then((user: User) => {
        dispatch({
          type: "GET_USER",
          payload: user
        });
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  };

  const isGuest = (): boolean => {
    return state.user?.role?.name === "GUEST"
  }

  const showLoader = () => {
    dispatch({
      type: "SHOW_LOADER",
      payload: true,
    });
  };

  const hideLoader = () => {
    dispatch({
      type: "SHOW_LOADER",
      payload: false,
    });
  };

  return (
    <Context.Provider
      value = {{
        state,
        getUser,
        isGuest,
        loader: {
          show: showLoader,
          hide: hideLoader,
        },
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default Provider;
