import { User } from "../api/models/User.model";
import State from "./State";

const Reducer = (state: State, action: { payload: any, type: string }): State => {
  const { payload, type } = action;

  switch (type) {
    case "GET_USER":
      // eslint-disable-next-line no-case-declarations
      const user = payload as User;
      return {
        ...state,
        user,
      }
    case "SHOW_LOADER":
      return {
        ...state,
        showLoader: payload as boolean,
      }
    case "ERROR":
      // eslint-disable-next-line no-case-declarations
      const { code } = payload;
      return {
        ...state,
        errorCode: code,
      };
    
    default:
      return state;
    }
}

export default Reducer;