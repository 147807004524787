import { Project } from "../../../api/models/Project.model";
import { Release } from "../../../api/models/Release.model";

class ReleaseState {
  constructor(
    public currentRelease: Release | undefined = {} as Release,
    public releaseForClients: Release | undefined = {} as Release,
    public releaseForDevs: Release | undefined = {} as Release,
    public releaseToTry: Release | undefined = {} as Release,
    public releases: Release[] = [],
    public project: Project  = {} as Project,
    public page: number = 1,
    public lastPage: number = 1,
  ){}
}

export default ReleaseState;