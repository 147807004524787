import { useReducer } from 'react';
import { API } from '../../../api/api';
import { TaskPagingFilter } from '../../../api/models/Task.model';
import TaskContext from './TaskContext';
import TaskReducer from './TasksReducer';
import TaskState from './TaskState';

const TaskProvider = (props: any): JSX.Element => {

  const [state, dispatch] = useReducer(TaskReducer, {} as TaskState, (_) => new TaskState());

  const getTasks = (filters?: TaskPagingFilter): Promise<void> => {
    if (!filters) {
      filters = state.defaultFilters;
    }
    return API.getTasks(filters)
      .then((tasks) => {
        dispatch({ 
          type: "GET_TASKS",
          payload: {
            tasks: tasks.data,
            lastPage: tasks.meta.last_page,
            filters,
          }
        });
      })
      .catch((err) => {
        console.log('Oops, something went wrong ', err);
      })
  }

  return (
    <TaskContext.Provider
      value = {{
        state,
        getTasks,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  )
}

export default TaskProvider;