import Telescope from "../svg/Telescope";

const NoResults = (): JSX.Element => (
  <div className="d-flex justify-content-center align-items-center gap-2 m-2">
    <Telescope />
    <span className="text-black-50" style={{fontSize: "14px"}}>No results found</span>
  </div>
);

export default NoResults;
