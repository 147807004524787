/* eslint-disable react/jsx-key */
import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import UserProvider from "../User/context/UserProvider";
import ProfilePrivate from "./components/ProfilePrivate";
import ProfilePublic from "./components/ProfilePublic";
import './Profile.css';

const ProfileView = ({ match: { params: { id } } }: any): JSX.Element => {
  const [isLogger, setIsLogger] = useState<boolean>(false);
  const { state: { user: userLogged } } = useContext(Context);

  useEffect(() => {
    setIsLogger(userLogged.id == id);
  }, [id])

  return (
    <UserProvider userId={id}>
      { isLogger
        ? <ProfilePrivate userId={id} />
        : <ProfilePublic userId={id} />
      }
    </UserProvider>
  )
}



export default ProfileView;