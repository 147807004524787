import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type AddButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  iconName?: IconName;
  disabled?: boolean;
}

const AddButton = ({ iconName="plus", ...props }: AddButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-success"
    iconName={iconName}
  />
);

export default AddButton;