import { Project } from "./Project.model";
import { Company } from "./Company.model";
import { Contact } from "./Contact.model";
import { Profile } from "./Profile.model";
import { Role } from "./Role.model";
import { Feedback } from "./Feedback.model";
import { Pageable } from "./Pageable";
import { WithId } from "../../types/Generics";
import avatarDefault from '../../assets/avatar.svg';
import { isEmpty } from "../../utils/Objects";

export class User implements WithId {
  constructor(
    public id: number = 0,
    public email: string = '',
    public profile: Profile = new Profile(),
    public role: Role = new Role(),
    public company: Company = new Company(),
    public contacts: Array<Contact> = [],
    public projects: Array<Project> = [],
    public shared_projects: Array<Project> = [],
    public project_owner: Array<Project> = [],
    public feedback: Array<Feedback> = [],
    public created_at: string,
    public updated_at: string,
  ) { }

  static getImage = (user: User): string => {
    return !isEmpty(user.profile?.avatar) 
      ? user.profile.avatar
      : avatarDefault;
  }

  static getUsername = (user: User): string => {
    return user.email.split('@')[0];
  }
}

export type UserPageable = Pageable<User>;
