/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const cardStyle = {
  boxShadow: '0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)',
}
const SimpleCard = ({ value, placeholder }: any): JSX.Element => {

  return (
    <div className="col col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-6 p-3 text-center bg-white m-2 border-start border-3 rounded radius-10 border-danger" style={cardStyle}>
      <h4>{ value }</h4>
      <h6 className="text-muted m-0 fw-normal">{ placeholder }</h6>
    </div>
  )
}

export default SimpleCard;
