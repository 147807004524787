import { Feedback, FeedbackPagingFilter } from "../../../api/models/Feedback.model";
import { Task, TaskPagingFilter } from "../../../api/models/Task.model";

class FeedbackState {
  constructor(
    public tasks: Task[] = [],
    public defaultFilters: FeedbackPagingFilter = { page: 1 } as FeedbackPagingFilter,
    public defaultTaskFilters: TaskPagingFilter = { page: 1 } as TaskPagingFilter,
    public page: number = 1,
    public lastPage: number = 1,
    public feedback: Feedback = {} as Feedback,
    public feedbacks: Feedback[] = [],
    public editFeedback: boolean = false,
    public state = {hasError: false},
  ){}
}

export default FeedbackState;