import moment from "moment";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { API } from "../../api/api";
import { TasksStatistics } from "../../api/models/Statistics.model";
import { TaskSortCriteriaOrder } from "../../api/models/Task.model";
import { TaskCategory } from "../../api/models/TaskCategory.model";
import { TaskPriority } from "../../api/models/TaskPriority.model";
import { TaskState } from "../../api/models/TaskState.model";
import SimpleCard from "../../components/card/SimpleCard";
import Paginator from "../../components/Paginator";
import ColumnFilter from "../../components/table/ColumnFilter";
import ColumnSorter from "../../components/table/ColumnSorter";
import OptionFilter from "../../components/table/OptionFilter";
import TableFiltering from "../../components/table/TableFiltering";
import { SortCriteriaOptions } from "../../types/Generics";
import TaskUpdate from "./components/TaskUpdate";
import TaskContext from "./context/TaskContext";
import TaskProvider from "./context/TaskProvider";

const TaskList = (): JSX.Element => {
  return (
    <TaskProvider  TaskId={undefined}>
      <TaskTable/>
    </TaskProvider>
  )
}

const TaskTable = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [statistics, setStatistics] = useState<TasksStatistics>();
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [taskPriorities, setTaskPriorities] = useState<TaskPriority[]>([]);
  const [taskCategories, setTaskCategories] = useState<TaskCategory[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(true);

  const {state: {tasks, lastPage}, getTasks} = useContext(TaskContext);

  const [project, setProject] = useState<string|null>(null);
  const [title, setTitle] = useState<string|null>(null);
  const [state, setState] = useState<number|null>(null)
  const [priority, setPriority] = useState<number|null>(null)
  const [category, setCategory] = useState<number|null>(null)
  const [assignedTo, setAssignedTo] = useState<string|null>(null)

  const [createdAtSorter, setCreatedAtSorter] = useState<SortCriteriaOptions>('DESC');
  const [dueAtSorter, setDueAtSorter] = useState<SortCriteriaOptions>(null);
  const [sortersOrder, setSortersOrder] = useState<TaskSortCriteriaOrder[]>(['created_at']);

  useEffect(() => {
    getTasks({
      page,
      project,
      title,
      state,
      priority,
      category,
      assigned_to: assignedTo,
      created_at_sort: createdAtSorter,
      due_at_sort: dueAtSorter,
      sort_criteria: sortersOrder,
    }).finally(() => setShowLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, project, title, state, priority, category, assignedTo, createdAtSorter, dueAtSorter, sortersOrder]);

  useEffect(() => {
    (async () => {
      const [states, priorities, categoies, statistics] = await Promise.all([
        API.getTaskStates(),
        API.getTaskPriorities(),
        API.getTaskCategories(),
        API.getTasksStatistics(),
      ]);
      setStatistics(statistics);
      setTaskStates(states);
      setTaskPriorities(priorities);
      setTaskCategories(categoies);
    })()
  },[])

  const switchCreatedAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'created_at'));
    } else if (sortersOrder.indexOf('created_at') < 0) {
      sortersOrder.push('created_at');
      setSortersOrder(sortersOrder);
    }
    setCreatedAtSorter(sortCriteria);
  }

  const switchDueAtSort = (sortCriteria: SortCriteriaOptions) => {
    if(sortCriteria == null) {
      setSortersOrder(sortersOrder.filter(v => v !== 'due_at'))
    } else if (sortersOrder.indexOf('due_at') < 0) {
      sortersOrder.push('due_at');
      setSortersOrder(sortersOrder);
    }
    setDueAtSorter(sortCriteria);
  }

  const getDueDateColor = (date: string, state: string): string => {
    if (state === 'READY') return 'text-muted fw-bold';

    const days = moment(date).diff(moment(), 'days');
    if (days > 0 && days <= 7) {
      return 'text-warning fw-bold';
    } else if (days <= 0) {
      return 'text-danger fw-bold';
    }
    return 'text-success fw-bold';
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    {
      Header: 'Project',
      Footer: 'project',
      accessor: 'project',
      canFilter: true,
      Filter: ColumnFilter(project, setProject),
    },
    {
      Header: 'Title',
      Footer: 'title',
      accessor: 'title',
      canFilter: true,
      Filter: ColumnFilter(title, setTitle),
    },
    {
      Header: 'State',
      Footer: 'state',
      accessor: 'state',
      canFilter: true,
      Filter: OptionFilter({
        options: taskStates.map((taskState) => ({id: taskState.id, value: taskState.state})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setState(option.id),
      }),
    },
    {
      Header: 'Priority',
      Footer: 'priority',
      accessor: 'priority',
      canFilter: true,
      Filter: OptionFilter({
        options: taskPriorities.map((taskPriority) => ({id: taskPriority.id, value: taskPriority.name})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setPriority(option.id),
      }),
      Cell: (props : { value: string } ) => (
        <Badge bg={props.value === "HIGH" ? `danger` : (props.value === "MIDIUM" ? `warning`: `info`)}>{props.value}</Badge>
      ),
    },
    {
      Header: 'Category',
      Footer: 'category',
      accessor: 'category',
      canFilter: true,
      Filter: OptionFilter({
        options: taskCategories.map((taskCategory) => ({id: taskCategory.id, value: taskCategory.name})),
        cell: (option) => (<Badge bg="secondary">{option.value}</Badge>),
        setFilter: (option: any) => setCategory(option.id),
      }),
    },
    {
      Header: 'Assigned to',
      Footer: 'assigned_to',
      accessor: 'assigned_to',
      canFilter: true,
      Filter: ColumnFilter(assignedTo, setAssignedTo),
    },
    {
      Header: 'Created at',
      Footer: 'created_at',
      accessor: 'created_at',
      canFilter: false,
      canSort: true,
      Sorter: ColumnSorter({ defaultSortCriteria: createdAtSorter, setSortCriteria: switchCreatedAtSort })
    },
    {
      Header: 'Due at',
      Footer: 'due_at',
      accessor: 'due_at',
      canFiler: false,
      canSort: true,
      Sorter: ColumnSorter({ setSortCriteria: switchDueAtSort }),
      Cell: (props : { value: { due_at: string, state: string } } ) => (
        <span className={getDueDateColor(props.value.due_at, props.value.state)}>{props.value.due_at}</span>
      ),
    },
    { 
      Header: 'Actions',
      Footer: 'actions',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = tasks.map(task => ({
    id: task.id,
    project: task.feedback?.release?.project.title,
    title: task.title,
    state: task.state.state,
    priority: task.priority.name,
    category: task.category.name,
    assigned_to: task.assignation?.assigned_to?.email,
    created_at: moment(task.created_at).format('MM/DD/YYYY'),
    due_at: {
      due_at: moment(task.due_at).format('MM/DD/YYYY'),
      state: task.state.state,
    },
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <TaskUpdate taskId={task.id} feedbackId={task.feedback?.id} releaseId={task.feedback?.release?.id} projectId={task.feedback?.release?.project.id}/>
      </div>
    )
  }));

  return (
    <>
      <div className="card shadow-card mb-2">
        <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
          <SimpleCard value={statistics?.tasksCount || 0} placeholder="TASKS"/>
          <SimpleCard value={statistics?.todoCount || 0} placeholder="TODOS"/>
          <SimpleCard value={statistics?.blockedCount || 0} placeholder="BLOCKED"/>
          <SimpleCard value={statistics?.wipCount || 0} placeholder="WIP"/>
          <SimpleCard value={statistics?.doneCount || 0} placeholder="DONE"/>
        </div>
      </div>

      <div className="card shadow-card mb-2">
        <div className="card-body">
          <div className="pt-3 pb-2 mb-3 border-bottom d-flex justify-content-between">
            <div className="h5">Tasks</div>
          </div>
          <div className="">
            <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
          </div>
        </div>
        <div className="card-footer">
          <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
        </div>
      </div>
    </>
  )
}

export default TaskList;

