/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import { Project } from '../../../api/models/Project.model';
import { Release } from '../../../api/models/Release.model';
import ReleaseContext from './ReleaseContext';
import ReleaseReducer from './ReleaseReducer';
import ReleaseState from './ReleaseState';

const ReleaseProvider = (props: {children: ReactNode, projectId: number | undefined}): JSX.Element => {

  const [state, dispatch] = useReducer(ReleaseReducer, {} as ReleaseState, (_) => new ReleaseState());

  const getReleases = (page  = 1): Promise<void> => {
    return API.getProjectReleases(page, props.projectId)
      .then((projectReleases) => {
        dispatch({
          type: "GET_RELEASES",
          payload: {
            releases: projectReleases.data,
            lastRelease: projectReleases.meta.last_page,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      })
  };

  const getProject = (): Promise<void> => {
    return API.getProject(props.projectId)
      .then((project: Project) => {
        dispatch({
          type: "GET_PROJECT",
          payload: project
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      })
  };

  const hasDummyRelease = (): boolean => {
    const releases = state.project?.releases;
    if (releases == undefined) {
      return false;
    }
    return releases.length > 0 && releases[0].is_dummy
  }

  const setReleaseToTry = (release: Release) => {
    dispatch({
      type: "RELEASE_TO_TRY",
      payload: release,
    });
  }

  return (
    <ReleaseContext.Provider
      value = {{
        state,
        getReleases,
        getProject,
        setReleaseToTry,
        hasDummyRelease,
      }}
    >
      { props.children }
    </ReleaseContext.Provider>
  )
}

export default ReleaseProvider;