import './SVG.css';

const Telescope = (): JSX.Element => (
  <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="svg-inline--fa fa-telescope fa-w-20 fa-2x">
    <g>
      <path d="M263.3594,347.79914,136.3008,391.44163c-8.7539,3.00781-18.05078-.69335-21.26953-8.46678l-8.74219-21.10738L42.17971,388.42211A15.99837,15.99837,0,0,1,21.27737,379.762L1.21877,331.34018a16.00653,16.00653,0,0,1,8.66406-20.90425L73.9844,283.88129,65.24221,262.772c-3.21875-7.77342.73828-16.96481,9.05469-21.02925L380.4219,92.1336l78.125,188.62074-66.60157,22.87691a71.98089,71.98089,0,0,0-143.96093.36914A71.08619,71.08619,0,0,0,263.3594,347.79914Z" className='path1'></path>
      <path d="M638.77737,216.83064,553.06252,9.88181a15.99836,15.99836,0,0,0-20.90234-8.66014L414.84377,49.81923a15.99639,15.99639,0,0,0-8.65625,20.90426l85.71094,206.94883a16.00274,16.00274,0,0,0,20.90625,8.66014l117.3125-48.59757A15.99819,15.99819,0,0,0,638.77737,216.83064ZM376.13283,348.50812a71.27481,71.27481,0,0,0,15.85157-44.50773,72,72,0,0,0-144,0,71.27859,71.27859,0,0,0,15.87109,44.53507l-47.46484,142.404A16.00061,16.00061,0,0,0,231.57033,512h16.85938a16.00416,16.00416,0,0,0,15.17969-10.94139l42.16406-126.49585a71.05048,71.05048,0,0,0,28.44922-.002l42.168,126.4978A16.00046,16.00046,0,0,0,391.57033,512h16.85938a16.00062,16.00062,0,0,0,15.17969-21.06051ZM319.9844,328.00035a24,24,0,1,1,24-24A24.03627,24.03627,0,0,1,319.9844,328.00035Z" className='path2'></path>
    </g>
  </svg>
);

export default Telescope;