import axios from "axios";
import { useEffect } from "react";
import { useContext } from "react";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { Company } from "../../../api/models/Company.model";
import EditButton from "../../../components/buttons/EditButton";
import CompanyContext from "../context/CompanyContext";

const imageStyle = {
  width: 120,
  height: 120,
}

type InitialState = {
  name: string,
  description: string,
  logo: string,
}

const CompanyUpdate = (props:{ id:number }): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');
  
  const { getCompanies } = useContext(CompanyContext);
  
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(true);

  useEffect(()=>{
    (() => {
      API.getCompany(props.id)
      .then((company: Company) => {
        setName(company.name);
        setDescription(company.description);
        setLogo(company.logo);

        setInitialState({
          name: company.name,
          description: company.description,
          logo: company.logo,
        });
      })
    })()
  }, [props.id]);

  const imageUpload = async (files: FileList | null) => {
    if (files === null) return;

    try {
      const formData = new FormData();
      formData.append("image", files[0]);
      const { data: asset } = await axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/assets`, formData);
      setLogo(asset.url);
    } catch (err) {
      console.log('Oops, something went wrong ', err);
      alert('Oops, something went wrong');
    }
  }
  
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      API.updateCompany(props.id, {
        name,
        description,
        logo,
      }).then(() => {
        getCompanies(1);
        setModalShow(false);
        setValidated(false);
      })
      .catch((err) => {
        setError(err.message);
        setShow(true);
      })
    }
  };

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getCompanies(1);
    setModalShow(false);
    setValidated(false);

    setName(initialState.name);
    setDescription(initialState.description);
    setLogo(initialState.logo);
  }

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit company"} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <img src={logo} alt="" className="img-thumbnail" style={imageStyle}/> 
        </Modal.Header>
        { error && show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert> }
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Company name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Company name" value={name} onChange={e => setName(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Company logo</Form.Label>
                <Form.Control type="file" size="sm"  onChange={e => imageUpload((e.target as HTMLInputElement).files)}/>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company logo.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"  size="sm" placeholder="Description"  onChange={e => setDescription(e.target.value)} value={description} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid company description.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default CompanyUpdate;