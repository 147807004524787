import './Logo.css';

const Logo = (props: any): JSX.Element => {
  return (
    <div className='d-flex'>
      <svg className="logo animated tada" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="120" height="120px" version="1.1" viewBox="0 0 600 600">
        <g>
          <path fill="#EE0000" d="M290 471l0 17c0,9 -7,16 -16,16 -9,0 -16,-7 -16,-16l0 -22c-74,-19 -129,-86 -129,-166 0,-94 77,-171 171,-171 94,0 171,77 171,171 0,75 -48,138 -115,161l0 55c0,12 -9,22 -21,22 -12,0 -22,-10 -22,-22l0 -46c-4,1 -8,1 -13,1 -3,0 -7,0 -10,0zm45 79c12,0 21,9 21,21 0,12 -9,21 -21,21 -12,0 -22,-9 -22,-21 0,-12 10,-21 22,-21z"/>
          <path fill="#FEFEFE" d="M307 84c13,-1 23,28 21,63 0,24 -5,44 -12,56l16 -4c17,-5 35,5 40,23l29 110c5,17 -5,35 -23,40l-31 8 4 13 -49 13 -3 -13 -31 8c-17,5 -35,-5 -40,-23l-29 -110c-5,-17 5,-35 23,-40l13 -3c-12,-6 -26,-19 -39,-34 -22,-27 -32,-54 -21,-60 11,-6 37,10 59,37 16,19 25,38 25,50l34 -9c-8,-9 -13,-32 -12,-58 1,-36 13,-66 26,-67z"/>
          <path fill="#EE0000" d="M298 349c1,-2 4,-3 6,-2l10 6 6 -10c1,-2 4,-3 6,-2 3,1 3,4 2,6l-6 11 10 5c3,2 3,5 2,7 -1,2 -4,3 -6,1l-10 -5 -6 10c-2,2 -4,3 -7,1 -2,-1 -3,-4 -1,-6l5 -10 -10 -6c-2,-1 -3,-4 -1,-6zm-61 -68c9,-4 24,7 33,25 9,18 9,36 0,41 -9,4 -24,-7 -33,-25 -9,-18 -9,-36 0,-41zm108 -29c-10,1 -17,18 -16,38 1,20 10,36 20,36 10,-1 18,-18 17,-38 -2,-20 -11,-36 -21,-36z"/>
        </g>
      </svg>
    </div>
  )
}

export default Logo;