import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SyntheticEvent } from "react";
import { CloseButton } from "react-bootstrap";
import { doNothing } from "../../utils/Functions";

type SimplePillProps = {
  iconName: IconName;
  label?: string;
  className?: string;
  checkable?: boolean;
  onCheck?: (e: SyntheticEvent) => void;
}

const closeButtonStyles = {
  width: "8px",
  height: "8px",
  padding: "2px",
  backgroundSize: "6px",
};

const SimplePill = ({
  iconName,
  label = "",
  className = "",
  checkable = false,
  onCheck = doNothing,
}: SimplePillProps):JSX.Element => {
  return (
    <div className={["d-flex gap-1 align-items-center pill pe-1", className].join(" ")}>
      <FontAwesomeIcon icon={["fas", iconName]} />
      <div>{label}</div>
      { checkable &&
        <CloseButton style={closeButtonStyles} onClick={(e) => onCheck(e)}/>
      }
    </div>
  )
}

export default SimplePill;
