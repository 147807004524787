import { Genre } from "../../../api/models/Genre.model";
import BaseState from "../../../context/base/BaseState";

class GenreState extends BaseState {
  constructor(
    public genres: Genre[] = [],
    public page: number = 1,
    public lastPage: number = 1,
  ){
    super();
  }
}

export default GenreState;