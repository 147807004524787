import axios, { AxiosError } from "axios";
import { SyntheticEvent, useState, useContext, useEffect, useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import Context from "../../../context/Context";
import { Parameters } from "../../../types/Generics";
import ReleaseContext from "../context/ReleaseContext";
import "./ReleaseCreate.css";

const maxFileSize = parseInt(process.env.MAX_FILE_SIZE || "500000000");
const maxFileSizeStr = process.env.MAX_FILE_SIZE_STR || "50MB"

const ReleaseCreate = (props: { projectId: number, onProject: boolean }): JSX.Element => {
  const [version, setVersion] = useState('');
  const [changelog, setChangelog] = useState('');
  const [width, setWidth] = useState(1040);
  const [height, setHeight] = useState(800);
  const [activeForDev, setActiveForDev] = useState(true);
  const [activeForClient, setActiveForClient] = useState(false);

  const [fileUrl, setFileURL] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const refFileRelease = useRef() as React.RefObject<HTMLInputElement>;

  const { loader } = useContext(Context);
  const { state: { currentRelease }, getReleases, getProject } = useContext(ReleaseContext);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (currentRelease !== undefined){
      setWidth(currentRelease.width);
      setHeight(currentRelease.height);
    }
  }, [])

  const fileUpload = async (files: FileList | null) => {
    if (files === null) return;
    if (files[0].size > maxFileSize) {
      setError(`The files is too large. It can be no larger than ${maxFileSizeStr}.`);
      setShow(true);
      if (refFileRelease.current) refFileRelease.current.value = "";
      return;
    }

    const formData = new FormData();
    formData.append('build', files[0]);
    setUploading(true);
    axios.post(`${process.env.REACT_APP_BUILD_URL_BASE}/api/builds`, formData)
      .then(({ data: build }) => {
        setFileURL(build.url);
        setFileName(build.filename);
      })
      .catch((error: AxiosError) => {
        if (refFileRelease.current) refFileRelease.current.value = "";
        setError(error.message);
        setShow(true);
      })
      .finally(() => {
        setUploading(false);
      });
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);
    if (form.checkValidity() === true) {
      loader.show();
      API.createProjectRelease({
        url: fileUrl,
        filename: fileName,
        version,
        changelog,
        project_id: props.projectId,
        width,
        height,
      })
      .then(async (release) => {
        if (activeForDev || activeForClient) {
          const projectUpdate: Parameters = {};
          if (activeForDev) {
            projectUpdate['dev_release_id'] = release.id;
          }

          if (activeForClient) {
            projectUpdate['client_release_id'] = release.id;
          }

          await API.updateProject(props.projectId, projectUpdate);
          props.onProject && getReleases(1);
          props.onProject && getProject();
          setModalShow(false);
          setValidated(false);
          setShow(false);
        }
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      })
      .finally(() => loader.hide())
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getReleases(1);
    setModalShow(false);
    setValidated(false);
    setShow(false);
    loader.hide();
  }

  return (
    <>
      {
        props.onProject
          ? <button className="btn btn-sm btn-success" type="button" onClick={() => setModalShow(true)}>Add Release</button>
          : <button className="btn btn-sm btn-success" type="button" onClick={() => setModalShow(true)}>Add</button>
      }
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create Release</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>

              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label>Version</Form.Label>
                <Form.Control type="text" size="sm" placeholder="Version" onChange={e => setVersion(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid title version.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustom02" className="mb-3">
                <Form.Label>Changelog</Form.Label>
                <Form.Control as="textarea" type="text" size="sm" placeholder="Version changelog" onChange={e => setChangelog(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid description.</Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Form.Group as={Col} md="6" sm="12" controlId="validationCustom04" className="mb-3" required>
                  <Form.Label>Width</Form.Label>
                  <Form.Control type="number" size="sm" min={100} placeholder="Width" value={width} onChange={e => setWidth(Number(e.target.value))} required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid width.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" sm="12" controlId="validationCustom05" className="mb-3" required>
                  <Form.Label>Height</Form.Label>
                  <Form.Control type="number" size="sm" min={100} placeholder="Height" value={height} onChange={e => setHeight(Number(e.target.value))} required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid height.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group controlId="validationCustom05" className="mb-3">
                <Form.Label>File</Form.Label>
                <Form.Control type="file" size="sm" required disabled={uploading} ref={refFileRelease}
                  className={ uploading ? "uploading-file-in-progress" : "" }
                  onChange={e => fileUpload( (e.target as HTMLInputElement).files )}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a file.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group  controlId="validationCustom06" className="mb-3">
                <Form.Label>Visibility</Form.Label>
                <Form.Switch
                variant="outline-primary"
                checked={activeForDev}
                onChange={(e) => setActiveForDev(e.currentTarget.checked)}
                label="Active for devs"
                required
              />
              <Form.Switch
                variant="outline-primary"
                checked={activeForClient}
                onChange={(e) => setActiveForClient(e.currentTarget.checked)}
                label="Active for clients"
              />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel} disabled={uploading}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary" disabled={uploading}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ReleaseCreate;