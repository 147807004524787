import { createContext } from 'react';
import GenreState from './GenreState';

class ContextType {
  constructor(
    public state: GenreState = {} as GenreState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public getGenres: (page: number, name?: string) => Promise<void> = async (_) => {},
  ){}
} 

const GenreContext = createContext<ContextType>({} as ContextType);

export default GenreContext;