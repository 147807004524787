
import { useContext } from "react";
import { useEffect } from "react";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../api/api";
import { ApiException } from "../../api/errors/ApiException";
import { Contact } from "../../api/models/Contact.model";
import EditButton from "../../components/buttons/EditButton";
import UserContext from "../User/context/UserContext";

type InitialState = {
  phone: string,
  street: string,
  number: string,
  email: string,
}

const ContactUpdate = (props: {contact: Contact}): JSX.Element => {
  const [initialState, setInitialState] = useState({} as InitialState);
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');

  const { getUserContacts: getContacts } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setPhone(props.contact.phone);
    setStreet(props.contact.street);
    setNumber(props.contact.number);
    setEmail(props.contact.email);
    setInitialState({
      phone: props.contact.phone,
      street: props.contact.street,
      number: props.contact.number,
      email: props.contact.email,
    })
  }, [props.contact]);

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getContacts();
    setModalShow(false);
    setValidated(false);
    setShow(false);
    setPhone(initialState.phone);
    setStreet(initialState.street);
    setNumber(initialState.number);
    setEmail(initialState.email);
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      API.updateContact(props.contact.id, {
        phone,
        street,
        number,
        email,
      })
      .then(() => {
        getContacts();
        setModalShow(false);
        setShow(false);
        setValidated(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      })
    }
  };

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit contact"} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update Contact</Modal.Title> 
        </Modal.Header>
          {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control type="text"  size="sm" placeholder="Phone number"  value={phone} onChange={ e => setPhone(e.target.value) } required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Form.Label>Street name</Form.Label>
                  <Form.Control type="text"  size="sm" placeholder="Street name" value={street} onChange={ e => setStreet(e.target.value) } required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid street name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="validationCustom03">
                  <Form.Label>Street number</Form.Label>
                  <Form.Control type="text"  size="sm" placeholder="Street number" value={number} onChange={ e => setNumber(e.target.value) } required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid street number.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4">
                <Form.Label>Email</Form.Label>
                  <Form.Control type="email"  size="sm" placeholder="Email" value={email} onChange={ e => setEmail(e.target.value) } required />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
              <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
            </Modal.Footer>
          </Form>
      </Modal>
    </>
  )
}

export default ContactUpdate;