/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { API } from "../../../api/api";
import { ApiException } from "../../../api/errors/ApiException";
import { Release } from "../../../api/models/Release.model";
import BaseButton from "../../../components/buttons/BaseButton";
import DownloadButton from "../../../components/buttons/DownloadButton";
import Guard from "../../../components/guards/Guard";
import Paginator from "../../../components/Paginator";
import TableFiltering from "../../../components/table/TableFiltering";
import ReleaseContext from "../context/ReleaseContext";

const ReleaseList = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const { state: { project, releases, lastPage }, getProject, getReleases, setReleaseToTry } = useContext(ReleaseContext);
  const [modalShow, setModalShow] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    getReleases(page).finally(() => setShowLoading(false))
  }, [page])

  const tryGameNow = (release: Release) => {
    setReleaseToTry(release);
    setModalShow(false);
  }

  const activateReleaseForDevs = (release: Release) => {
    // Agregar confirmación
    API.updateProject(project.id, {
      dev_release_id: release.id,
    })
    .then(() => {
      getReleases(page);
      getProject();
      setShow(false);
    })
    .catch((err: ApiException) => {
      setShow(true);
      setError(err.message);
    });
  }

  const activateReleaseForClients = (release: Release) => {
    // Agregar confirmación
    API.updateProject(project.id, {
      client_release_id: release.id,
    })
    .then(() => {
      getReleases(page);
      getProject();
      setShow(false);
    })
    .catch((err: ApiException) => {
      setError(err.message);
      setShow(true);
    });
  }

  const handleCancel = () => {
    setShow(false);
    setModalShow(false);
  }

  const headers = [
    {
      Header: 'ID',
      Footer: 'id',
      accessor: 'id',
      canFilter: false,
    },
    { 
      Header: 'Version',
      Footer: 'version',
      accessor: 'version',
      canFilter: false,
      // Filter: ColumnFilter(title, setTitle),
    },
    { 
      Header: 'Created at',
      Footer: 'created_at',
      accessor: 'created_at',
      canFilter: false,
      // Filter: ColumnFilter(title, setTitle),
    },
    { 
      Header: 'Actions',
      Footer: 'actions',
      accessor: 'actions',
      canFilter: false,
    }
  ];

  const dataRender = releases.map(release => ({
    ...release,
    created_at: moment(release.created_at).format('MM/DD/YYYY'),
    actions: (
      <div className="d-flex mr-2 gap-2 justify-content-center">
        <Guard entity="PROJECT_RELEASES" level="CREATE">
          <BaseButton 
            className="btn-sm btn-dark"
            hover="Try it"
            placement="top"
            onClick={() => tryGameNow(release)} iconName="gamepad"
          />
          <BaseButton
            className="btn-sm btn-success"
            hover="Activate for devs"
            disabled={project.devRelease?.id == release.id}
            placement="top"
            onClick={() => activateReleaseForDevs(release)} iconName="check-circle"
          />
          <BaseButton
            className="btn-sm btn-danger"
            hover="Activate for clients"
            disabled={project.clientRelease?.id == release.id}
            placement="top"
            onClick={() => activateReleaseForClients(release)}
            iconName="check-circle"
          />
        </Guard>
        <Guard entity="PROJECT_RELEASES" level="VIEW">
          <DownloadButton
            hover="Download version"
            placement="top"
            path={`${process.env.REACT_APP_BUILD_URL_BASE}/api/builds/download/${release.filename}`}
            disabled={release.filename === undefined || release.filename === ''}
          />
        </Guard>
        {/* <Link to={`/releases/${release.id}/update`} className="btn btn-sm btn-outline-secondary">Edit</Link> */}
        {/* <button className="btn btn-sm btn-outline-secondary" onClick={() => del(release.id)}>Delete</button> */}
      </div>
    )
  }));

  return (
    <>
      <button className="btn btn-sm btn-outline-secondary" type="button" onClick={() => setModalShow(true)}>Releases</button>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header>
        Releases
      </Modal.Header>
      {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
      <Modal.Body>
      <div className="">
        <TableFiltering columns={headers} data={dataRender} isWaitingData={showLoading} />
      </div>
      <Paginator page={page} lastPage={lastPage} onPageChange={setPage}/>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-danger" onClick={handleCancel}>Close</button>
      </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReleaseList;