import { Placement } from "react-bootstrap/esm/types";
import BaseButton from "./BaseButton";

type ViewButtonProps = {
  onClick?: () => void;
  placement?: Placement;
  hover?: string;
  disabled?: boolean;
}

const GoButton = (props: ViewButtonProps): JSX.Element => (
  <BaseButton
    {...props}
    className="btn-sm btn-primary"
    iconName="arrow-right"
  />
);

export default GoButton;
