/* eslint-disable no-case-declarations */
import GenreState from "./GenreState";

const GenreReducer = (state: GenreState, action: {payload: any, type: string}): GenreState => {
  const {payload, type} = action;

  switch (type) {
    case "GET_GENRES":
      const { genres, lastPage } = payload;
      return {
        ...state,
        genres,
        lastPage
      };
    case "ERROR":
      // eslint-disable-next-line no-case-declarations
      const { code } = payload;
      return {
        ...state,
        errorCode: code,
      };
    default:
      return state;
  }
}

export default GenreReducer;
