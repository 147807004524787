/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useReducer } from 'react';
import { API } from '../../../api/api';
import { ApiException } from '../../../api/errors/ApiException';
import GenreContext from './GenreContext';
import GenreReducer from './GenreReducer';
import GenreState from './GenreState';

const GenreProvider = (props: {children: any}): JSX.Element => {

  const [state, dispatch] = useReducer(GenreReducer, {} as GenreState, (_) => new GenreState());

  const getGenres = (page: number, name?: string): Promise<void> => {
    return API.getGenres(page, name)
      .then((genresResponse) => {
        dispatch({ 
          type: "GET_GENRES",
          payload: {
            genres: genresResponse.data,
            lastPage: genresResponse.meta.last_page,
          }
        })
      })
      .catch((err: ApiException) => {
        dispatch({
          type: "ERROR",
          payload: {
            code: err.code,
            message: err.message
          }
        })
      });
  }

  return (
    <GenreContext.Provider
      value = {{
        state,
        getGenres,
      }}
    >
      {props.children}
    </GenreContext.Provider>
  )
}

export default GenreProvider;