import { useState } from "react"
import Game from "../../../components/Game"
import { Release } from "../../../api/models/Release.model"
import playImage from "../../../assets/play.png";
import '../Project.css';

const iframeStyle = {
  alignSelf: 'center',
}

interface DisplayReleaseProps {
  release?: Release;
  image?: string;
  resolution: {
    height: number;
    width: number;
  };
}

const DisplayRelease = (props: DisplayReleaseProps): JSX.Element => {
  const [play, setPlay] = useState(false);
  const { release, image } = props;

  const stylesForPlay = {
    width: props.resolution.width,
    height: props.resolution.height,
    alignSelf: 'center',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundSize: 'cover'
  };

  if (release === null || release === undefined || JSON.stringify(release) === '{}' ) {
    return <img className="card-img-bottom img-fluid rounded card-project-img" style={stylesForPlay} src={image} alt="" />
  }

  return play
    ? <Game url={release.url} title={release.version} style={iframeStyle} resolution={props.resolution} />
    : <div className="d-flex justify-content-center align-items-center card-project-img" style={stylesForPlay} onClick={() => setPlay(true)}>
      <img src={playImage} alt="play" className="image-styles-custom" />
    </div>
}

export default DisplayRelease