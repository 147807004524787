import axios from "axios";
import { SyntheticEvent, useEffect, useState, useContext } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ApiException } from "../../../api/errors/ApiException";
import { Permission } from "../../../api/models/Permission.model";
import { Table } from "../../../api/models/Table.model";
import AddButton from "../../../components/buttons/AddButton";
import RoleContext from "../context/RoleContext";

type TableLevel = {[key: string]: string};

const RoleCreate = (): JSX.Element => {
  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [tableLevel, setTableLevel] = useState({} as TableLevel);
  const [permissionsAssigned, setPermissionsAssigned] = useState<string[]>([]);

  const { getRoles } = useContext(RoleContext);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [ { data: permissions }, { data: tables} ] = await Promise.all([
          axios.get("permissions"),
          axios.get("tables"),
        ]);
        setPermissions(permissions);
        setTables(tables);
      } catch (err) {
        console.log('Oops, something went wrong ', err);
      }
    })();
  }, []);

  const selectTableLevel = (table: string, level: string) => {
    tableLevel[table] = level;
    setPermissionsAssigned(
      tables.filter((table: Table) => tableLevel[table.name] !== undefined && tableLevel[table.name] !== 'None')
        .map((table: Table) => `${table.name}_${tableLevel[table.name]}`)
    );
    setTableLevel(tableLevel);
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    // TODO: Agregar loader.

    setValidated(true);

    if (form.checkValidity() === true) {
      const permissionIds = permissions.filter((permission: Permission) => permissionsAssigned.includes(permission.name))
      .map((permission: Permission) => permission.id);
        axios.post('roles', {
          name,
          permissions: permissionIds
        })
        .then(() => {
          getRoles(1);
          setModalShow(false);
          setValidated(false);
          setShow(false);
        })
        .catch((err: ApiException) => {
          setError(err.message);
          setShow(true);
        });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getRoles(1);
    setModalShow(false);
    setValidated(false);
    setShow(false);
  }

  return (
    <>
      {/* <button className="btn btn-sm btn-success" onClick={() => setModalShow(true)}>Add</button> */}
      <AddButton onClick={() => setModalShow(true)} placement="left" hover="Add Role" />

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create role</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Role name" onChange={e => setName(e.target.value)} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02">
                <div className="">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Entity</th>
                        <th scope="col">Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tables.map((table:Table) => (
                        <tr key={table.id}>
                          <td>{table.name}</td>
                          <td>
                            <select className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={e => selectTableLevel(table.name, e.target.value)}>
                              <option selected>None</option>
                              <option value="VIEW">VIEW</option>
                              <option value="CREATE">CREATE</option>
                              <option value="EDIT">EDIT</option>
                              <option value="FULL">FULL</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default RoleCreate;