import axios from "axios";
import { SyntheticEvent, useEffect, useState, useContext } from 'react'
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { ApiException } from "../../../api/errors/ApiException";
import { Permission } from "../../../api/models/Permission.model";
import { Table } from "../../../api/models/Table.model";
import EditButton from "../../../components/buttons/EditButton";
import RoleContext from "../context/RoleContext";

type TableLevel = {[key: string]: string};

const RoleUpdate = (props: {id: number}): JSX.Element => {
  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [tableLevel, setTableLevel] = useState({} as TableLevel);
  const [modalShow, setModalShow] = useState(false);
  const [permissionsAssigned, setPermissionsAssigned] = useState<string[]>([]);

  const { getRoles } = useContext(RoleContext);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      setCurrentValues();
    })();
  }, [props.id]);

  const setCurrentValues = async () => {
    try {
      const [{ data: role }, { data: permissions }, { data: tables}] = await Promise.all([
        axios.get(`roles/${props.id}`),
        axios.get("permissions"),
        axios.get("tables"),
      ]);
      setName(role.name);
      setTables(tables);
      setPermissions(permissions);
      const tableLevel = {} as TableLevel;
      role.permissions.forEach((permission: Permission) => {
        const reg = /_(VIEW|CREATE|EDIT|FULL)/.exec(permission.name);
        if (reg !== null) {
          const level = reg[1];
          const table = permission.name.slice(0, reg.index);
          tableLevel[table] = level;
        }
      });
      setPermissionsAssigned(role.permissions.map((permission: Permission) => permission.name));
      setTableLevel(tableLevel);
    } catch (err) {
      setShow(true);
      setError('Oops, something went wrong ' + JSON.stringify(err));
    }
  }

  const selectTableLevel = (table: string, level: string) => {
    tableLevel[table] = level;
    setPermissionsAssigned(
      tables.filter((table: Table) => tableLevel[table.name] !== undefined && tableLevel[table.name] !== 'None')
        .map((table: Table) => `${table.name}_${tableLevel[table.name]}`)
    );
    setTableLevel(tableLevel);
  }

  const handleSubmit = (e: SyntheticEvent) => {
    const form = e.target as HTMLFormElement;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      const permissionIds = permissions.filter((permission: Permission) => permissionsAssigned.includes(permission.name))
      .map((permission: Permission) => permission.id)

      axios.put(`roles/${props.id}`, {
        name,
        permissions: permissionIds
      }).then(() => {
        getRoles(1);
        setModalShow(false);
        setValidated(false);
        setShow(false);
      })
      .catch((err: ApiException) => {
        setError(err.message);
        setShow(true);
      });
    }
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    getRoles(1);
    setModalShow(false);
    setValidated(false);
    setShow(false);
    if ( name == '' ) {
      setCurrentValues();
    }
  }

  return (
    <>
      <EditButton onClick={() => setModalShow(true)} placement={"top"} hover={"Edit role"} />
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update role</Modal.Title>
        </Modal.Header>
        {show && <Alert variant="danger" onClose={() => setShow(false)} dismissible>{error}</Alert>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Col>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text"  size="sm" placeholder="Role name" onChange={e => setName(e.target.value)} value={name} required />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom02">
                <div className="">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Entity</th>
                        <th scope="col">Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tables.map((table:Table) => (
                        <tr key={table.id}>
                          <td>{table.name}</td>
                          <td>
                            <select
                              className="form-select form-select-sm" aria-label=".form-select-sm example" 
                              value={tableLevel[table.name] === undefined ? '-' : tableLevel[table.name]}
                              onChange={e => selectTableLevel(table.name, e.target.value)}
                            >
                              <option value="-">None</option>
                              <option value="VIEW">VIEW</option>
                              <option value="CREATE">CREATE</option>
                              <option value="EDIT">EDIT</option>
                              <option value="FULL">FULL</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</Button>
            <Button type="submit" className="btn btn-sm btn-primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default RoleUpdate;